import { compact } from 'lodash'
import { addSelectOptions } from 'services/select'

class LineItemParties {
  constructor(lineItem) {
    this.lineItem = lineItem
    this.el = lineItem.el
    this.doc = lineItem.doc
    this.$partySelect = this.el.find('select.party-select')

    this.bindEvents()
  }

  bindEvents() {
    if (this.doc.isReadonly()) return
    if (this.doc.isSelectPartyMode()) this.$partySelect.change(this.handleChangeParty)
  }

  setPartySelect({ modificationId = null, serialNumber = null, datevalid = null } = {}) {
    const isParty = this.doc.companySettings.party_accounting
    const isSerial = this.lineItem.isSerial()
    let parties = this.lineItem.product.parties

    const disabled = this.lineItem.isProductKit() || (isSerial && isParty)
    this.$partySelect.prop('disabled', disabled)

    if (!parties || disabled) return

    if (modificationId) parties = parties.filter(party => party.modification_id === modificationId)
    if (datevalid) parties = parties.filter(party => party.datevalid === datevalid)
    if (isSerial) parties = parties.filter(party => party.serial_number == serialNumber)

    const options = parties.map(item => {
      let partyOrPrice = item.price_balance

      if (isParty) partyOrPrice = item.party_code || I18n.t('document_items.without_party')

      const details = compact([
        isParty ? `${I18n.t('prices.price')}: ${item.price_balance || '-'}` : null,
        `${I18n.t('main.remain')}: ${item.balance_quantity || 0}`
      ]).join(', ')

      return {
        label: `<strong>${partyOrPrice}</strong><span class='party-details'>(${details})</span>`,
        value: partyOrPrice
      }
    })

    const allowBlank = this.doc.data.auto_parties ? window.I18n.t('main.auto').toLowerCase() : true

    addSelectOptions(this.$partySelect, options, {
      label: 'label', value: 'value', clear: true, allowBlank
    })

    if (this.doc.data.auto_parties) {
      this.$partySelect.val('').trigger('change')
    } else if (options.length === 1) {
      this.$partySelect.val(options[0].value).trigger('change')
    } else if (options.length > 1 && !this.lineItem.isSerial() && !this.doc.applyProductsFromCatalogPending) {
      // setTimeout is neccessary, because select2 dropdown has fixed position,
      // and line items column width will be changed during product insertion,
      // than select2 dropdown will have wrong position.
      setTimeout(() => this.$partySelect.select2('open'), 300)
    }
  }

  // On party change we should update fields for documents product_off, overvalue, correction_balance, disassembly
  // Depending on company settings party_accounting we perform different logic.
  // Party could be found by party code or by balance price (if party_accounting disabled).
  handleChangeParty = (event) => {
    const partyCode = $(event.target).val()
    let party

    if (this.doc.companySettings.party_accounting) {
      party = this.lineItem.product.parties.find(p => p.party_code === (partyCode || ''))
    } else {
      party = this.lineItem.product.parties.find(p => Number(p.price_balance || 0) === Number(partyCode || 0))
    }

    if (!party) return

    // Только для документов [product_off overvalue correction_balance disassembly]
    // При партионном учете:
    // - для Переоценки приходная цена и количество выбирается от партии
    // - для Списания и Корректировки остатка цена выбирается от партии
    // - для случаев, когда поле партии пустое (в старых приходах) количество берем как actual_quantity
    switch (this.doc.typedoc) {
      case 'product_off':
        this.onChangeProductOffParty(party)
        break
      case 'correction_balance':
        this.onChangeBalanceCorrectionParty(party)
        break
      case 'overvalue':
        this.onChangeOvervalueParty(party)
        break
      case 'disassembly':
        this.onChangeProductOffParty(party)
    }

    this.lineItem.calculate({event})
  }

  onChangeProductOffParty(party) {
    const price = party.price_balance
    this.el.find('.price.price-document').val(price)
    this.el.find('.price.price-accounting').val(price)
    this.el.find('.admin-price-accounting').text(price)
  }

  onChangeBalanceCorrectionParty(party) {
    if (this.doc.companySettings.party_accounting) {
      const price = party.price_balance
      this.el.find('.price.price-document').val(price)
      this.el.find('.price.price-accounting').val(price)
      this.el.find('.admin-price-accounting').text(price)
    }

    this.el.find('.correction-balance-party-quantity').text(party.balance_quantity)
  }

  onChangeOvervalueParty(party) {
    if (this.doc.companySettings.party_accounting) {
      this.el.find('.price-supply').val(party.price_balance)
    }
    this.el.find('.quantity').val(party.balance_quantity)
  }
}

export default LineItemParties

import BaseWidget from './base_widget'

class CommentsWidget extends BaseWidget {

  render() {
  }

  bindEvents() {
    this.el.find('.edit-btn').click((e) => this.handleToggleEdit(e, true))
    this.el.find('.cancel-edit-btn').click((e) => this.handleToggleEdit(e, false))
  }

  handleToggleEdit(e, editMode) {
    e.preventDefault()

    const $comment = $(e.target).closest('.comment-item')
    $comment.toggleClass('edit-mode', editMode)
  }

}

export default CommentsWidget

class Shop {

  constructor(el) {
    this.el = el
    this.$cartButton = $('.gcart')
    this.bindEvents()
  }

  bindEvents() {
    this.el.find('.add-to-cart-btn').click(this.onAddProductToCard)
    this.el.find('.show-cart-modal-btn').click(this.onOpenCartModal)
    // this.$productModal.find('form').submit(this.onCreateOrUpdateProduct)
  }

  onOpenCartModal = (e) => {
    // e.preventDefault()
  }

  onAddProductToCard = (e) => {
    e.preventDefault()
    const product_id = $(e.target).closest('.add-to-cart-btn').data().productId
    $.ajax({
      method: 'POST',
      data: { product_id },
      url: `${gon.locale_path}/platform/cart_items`,
      dataType: 'json',
      success: response => {
        console.log(response)
        this.$cartButton.find('.amount').text(response.amount)
        $(e.target).closest('.add-to-cart').addClass('product-in-cart')
        // if (response.errors) {
        //   this.$productModal.find('.submit-btn').prop('disabled', false)
        //   this.$productModal.find('.alert').text(response.errors).show()
        // } else {
        //   this.activeLineItem.resetLineItem(response)
        //   this.$productModal.find('.submit-btn').prop('disabled', false)
        //   this.closeProductModal()
        // }
      },
      error(error) {
        // this.$productModal.find('.submit-btn').prop('disabled', false)
        console.error(error.status, error.statusText)
      }
    })
  }

  // openProductModal(lineItem) {
  //   this.activeLineItem = lineItem
  //   const query = lineItem.query
  //   this.$productModal.find('.form-group input, .form-group textarea').val(null)
  //   this.$productModal.modal('show')
  //   this.$productModal.find('#product_title').focus()
  // }
  //
  // closeProductModal() {
  //   this.$productModal.modal('hide')
  //   this.activeLineItem = null
  // }
  //
  // onCreateOrUpdateProduct = (e) => {
  //   e.preventDefault()
  //   this.$productModal.find('.submit-btn').prop('disabled', true)
  //   this.$productModal.find('.alert').hide()
  //   $.ajax({
  //     method: 'POST',
  //     data: this.fetchProductModalParams(),
  //     url: '/products',
  //     dataType: 'json',
  //     success: response => {
  //       if (response.errors) {
  //         this.$productModal.find('.submit-btn').prop('disabled', false)
  //         this.$productModal.find('.alert').text(response.errors).show()
  //       } else {
  //         // activeLineItem set in openProductModal
  //         this.activeLineItem.resetLineItem(response)
  //         this.$productModal.find('.submit-btn').prop('disabled', false)
  //         this.closeProductModal()
  //       }
  //     },
  //     error(error) {
  //       this.$productModal.find('.submit-btn').prop('disabled', false)
  //       console.error(error.status, error.statusText)
  //     }
  //   })
  // }
  //
  // fetchProductModalParams() {
  //   return `${this.$productModal.find('form').serialize()}&typeprice=${this.data.typeprice}`
  // }
}

export default Shop

import BaseWidget from './base_widget'

// Widget to handle interactions with address form
// If kind-field data attribute provided then widget listen to the kind field outside it's html.
class AddressFormWidget extends BaseWidget {
  initialize() {
    this.$kind = this.fetchKindField()
    this.$form = this.el.find('.address-form-fields')
  }

  render() {
    // Kind could inside widget or outside, like in document delivery
    if (this.el.data('kind-field')) {
      this.el.find('.address-kind-box').remove()
    } else {
      this.el.find('.address-kind-box').show()
    }

    this.reload()
  }

  bindEvents() {
    this.$kind.change(this.reload.bind(this))
  }

  reload() {
    let visible

    switch (this.$kind.val()) {
      case 'direct':
        visible = '.global-city-box, .street-name-box, .house-box, .flat-box, .postal-code-box'
        break
      case 'courier':
        visible = '.address-line-box'
        break
      case 'pickup':
        break
      case 'novaposhta':
        visible = '.global-city-box, .global-post-office-box'
        break
      case 'novaposhta_address':
        visible = '.global-city-box, .global-street-box, .house-box, .flat-box'
        break
    }

    const $hidden = this.$form.find(`.kind-dependent:not(${visible})`)
    $hidden.find('input').val(null)
    $hidden.find('select').val(null).trigger('change')
    $hidden.hide()

    this.el.find(visible).show()
  }

  fetchKindField() {
    return this.el.data('kind-field') ? $(this.el.data('kind-field')) : this.el.find('.address-kind')
  }
}

export default AddressFormWidget

import humps from 'humps'
import qs from 'qs'

export function isEmpty(obj, skipProp = 'id') {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const isPropEmpty = obj[key] === null || obj[key].length === 0

      if (!isPropEmpty && key !== skipProp) return false
    }
  }

  return true
}

export function camelizeKeys(data) {
  return humps.camelizeKeys(data, (key, convert) => {
    return /[A-Z0-9]/.test(key) ? key : convert(key)
  })
}

export function decamelizeKeys(data) {
  return humps.decamelizeKeys(data, (key, convert, options) => {
    return /^[A-Z0-9_]+$/.test(key) ? key : convert(key, options)
  })
}

export function fetchParams() {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  return Object.keys(params).reduce((acc, key) => {
    let value = params[key]
    switch (value) {
      case 'true':
        value = true
        break
      case 'false':
        value = false
        break
    }
    acc[key] = value
    return acc
  }, {})
}

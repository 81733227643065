import BaseWidget from './base_widget'
import Chart from 'chart.js/auto'

class ChartWidget extends BaseWidget {

  render() {
    this.el.empty()
    const $canvas = $('<canvas>')
    this.el.append($canvas)

    new Chart(
      $canvas,
      this.el.data('config')
    )
  }

}

export default ChartWidget

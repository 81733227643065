import Pickr from '@simonwep/pickr'
import BaseWidget from './base_widget'

const OPTIONS = {
  theme: 'classic', // or 'monolith', or 'nano'
  useAsButton: true,
  swatches: [
    'rgb(244, 67, 54)',
    'rgb(233, 30, 99)',
    'rgb(156, 39, 176)',
    'rgb(103, 58, 183)',
    'rgb(63, 81, 181)',
    'rgb(33, 150, 243)',
    'rgb(3, 169, 244)',
    'rgb(0, 188, 212)',
    'rgb(0, 150, 136)',
    'rgb(76, 175, 80)',
    'rgb(139, 195, 74)',
    'rgb(205, 220, 57)',
    'rgb(255, 235, 59)',
    'rgb(255, 193, 7)'
  ],
  components: {
    preview: true,
    opacity: true,
    hue: true,
    interaction: {
      hex: true,
      rgba: true,
      hsla: true,
      hsva: true,
      cmyk: true,
      input: true,
      clear: false,
      save: true
    }
  }
}

class ColorPicker extends BaseWidget {
  render() {
    this.picker = Pickr.create({el: this.el[0], ...OPTIONS})
  }

  bindEvents() {
    this.picker.on('init', this.setColor.bind(this))
    this.picker.on('change', this.handleChange.bind(this))
    this.picker.on('save', this.handleSave.bind(this))
  }

  setColor(picker) {
    this.el.css('color', 'transparent')
    const color = this.el.val()
    if (!color) return
    this.picker.setColor(color)
  }

  handleChange(color) {
    const rgba = color.toRGBA().toString(0)
    this.el.val(rgba)
    this.el.css('color', 'transparent')
    this.el.css('background-color', rgba)
  }

  handleSave() {
    this.picker.hide()
  }
}

export default ColorPicker

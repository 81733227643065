import axios from 'axios'
import { connectWidgets } from 'main/widgets/helpers'
import { errorMessage } from 'services/errors'

class Product {
  constructor(el, productId, { onCreate = null, onUpdate = null, onReplace = null, onError = null, newProductAttributes = {}} = {}) {
    this.el = el
    this.productId = productId
    this.onCreate = onCreate
    this.onUpdate = onUpdate
    this.onReplace = onReplace
    this.onError = onError
    this.newProductAttributes = newProductAttributes
    this.bindEvents()
  }

  bindEvents() {
    const form = this.form()

    form.submit(this.handleSubmit)
    this.el.find('.replace-btn').click(this.handleReplace)
  }

  open = async (productId, { onCreate = null, onUpdate = null, onReplace = null, onError = null, newProductAttributes = {}} = {}) => {
    this.productId = productId
    this.onCreate = onCreate
    this.onUpdate = onUpdate
    this.onReplace = onReplace
    this.onError = onError
    this.newProductAttributes = newProductAttributes

    this.el.find('.alert').hide()
    await this.reload()
    this.el.modal('show')
    this.el.find('#product_title').focus()
  }

  close = () => {
    this.el.modal('hide')
    this.productId = null
    this.onCreate = null
    this.onUpdate = null
    this.onReplace = null
    this.onError = null
    this.newProductAttributes = {}
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (this.productId) {
      this.save(this.onUpdate)
    } else {
      this.save(this.onCreate)
    }
  }

  // Perform save product and replace document item data with product data.
  handleReplace = (e) => {
    e.preventDefault()

    this.save(this.onReplace)
  }

  save = async (callback) => {
    this.el.find('.submit-btn').prop('disabled', true)
    this.el.find('.alert').hide()
    try {
      const method = this.form().attr('method')
      const url = `${this.form().attr('action')}.json`
      const data = new FormData(this.form()[0])
      const response = await axios[method](url, data)
      if (response.data.error) return this.handleRequestError(response.data.error)
      callback && callback(response.data)
      this.el.find('.submit-btn').prop('disabled', false)
      this.close()
    } catch (e) {
      const message = e.response && e.response.data.error
      this.handleRequestError(e, message || errorMessage(e))
      console.error(e)
    }
  }

  handleRequestError(message) {
    this.el.find('.submit-btn').prop('disabled', false)
    const $alert = this.el.find('.alert')
    $alert.text(message).show()
    // Scroll to the begining of modal
    this.el.find('.modal-content')[0].scrollIntoView()
  }

  // Get via method because html recreatred in reload
  form() {
    return this.el.find('form')
  }

  // For now reload works only in modal case. You may improve it if necessary
  // FIXME: Product modal have product types radio buttons, in manufacture (etc) some button should be disabled.
  // This form overrides class obtained via radio_button_class.
  async reload() {
    try {
      const params = { product_id: this.productId, new_product_attributes: this.newProductAttributes }
      const response = await axios.get(`${gon.locale_path}/platform/products/modal.json`, { params })
      const html = response.data.html
      if (!html) return
      this.el.find('.modal-content').html($(html).find('.modal-content'))
      this.bindEvents()
      connectWidgets(this.el)
    } catch (e) {
      this.onError && this.onError(e)
      console.error(e)
    }
  }
}

export default Product

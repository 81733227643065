import BaseWidget from './base_widget'
import { round } from 'lodash'
import axios from 'axios'
import qs from 'qs'
import Bootbox from 'bootbox'
import { isMobile } from 'services/mobile'
import { onSubmitCreateOption } from 'services/select'
import { isAxiosNetworkError, isAxiosServerError, showAlert } from 'services/errors'
import { DATE_PICKER_FORMAT, DATETIME_PICKER_FORMAT } from 'main/constants'
import { day } from 'services/dates'
import { formToObject } from 'services/form'

// Widget to handle interactions with delivery form
class DeliveryWidget extends BaseWidget {
  initialize() {
    this.doc = window.doc
    this.delivery = this.el.data('delivery')
    this.$shipment = this.el.find('.delivery-shipment')
    this.$payment = this.el.find('.delivery-payment')
    this.$sender = this.el.find('.delivery-sender')
    this.$customSeats = this.$shipment.find('.custom-seats')
    this.$seatsList = this.$customSeats.find('.seats-list')
    this.$commonSeats = this.$shipment.find('.common-seats')
    this.$presetModal = $('#preset-modal')
    this.presets = [...this.data.presets]
    this.seatTemplate = this.prepareSeatTemplate()
    this.readonly = this.el.data('readonly')
    this.persisted = this.el.data('persisted')
    this.initFields()
    this.setKind()
    this.setDefaults()
    this.setNotes()
  }

  initFields() {
    this.fields = {
      kind: this.el.find('.delivery-kind'),
      shipmentNotes: this.$shipment.find('.shipment-notes'),
      evaluationAmount: this.$shipment.find('.delivery-evaluation-amount'),
      autoEvaluationAmount: this.$shipment.find('.delivery-auto-evaluation-amount'),
      customizeSeats: this.$shipment.find('.customize-seats'),
      seatsQuantity: this.$shipment.find('.seats-quantity'),
      weightInput: this.$shipment.find('.common-weight-input'),
      weightSelect: this.$shipment.find('.common-weight-select'),
      volume: this.$shipment.find('.common-volume'),
      volumeWeight: this.$commonSeats.find('.common-volume-weight'),
      presetSelect: this.$shipment.find('.shipment-preset-select'),
      backwardDelivery: this.el.find('.backward-delivery'),
      cost: this.el.find('.delivery-cost'),
      payerType: this.el.find('.delivery-payer-type'),
      paymentMethod: this.el.find('.delivery-payment-method'),
      backwardPayer: this.el.find('.delivery-backward-payer'),
      paymentControl: this.el.find('.delivery-payment-control'),
      senderCollaborator: this.el.find('.delivery-sender-collaborator'),
      senderAddress: this.el.find('.delivery-sender-address'),
      plannedDate: this.el.find('.delivery-planned-date'),
      status: this.el.find('.delivery-status-select')
    }
  }

  bindEvents() {
    this.el.find('.print-delivery-btn').click(this.onPrint.bind(this))
    $(`a[href="#${this.el.closest('.tab-pane').attr('id')}"]`).on('shown.bs.tab', this.makeActionsSticky.bind(this))

    if (this.readonly) return

    this.fields.kind.change(this.reload.bind(this))
    this.fields.senderCollaborator.change(this.onChangeSenderCollaborator.bind(this))
    this.$shipment.find('.cargo-type').change(this.toggleShipmentContent.bind(this))
    this.fields.autoEvaluationAmount.change(this.setEvaluationAmount.bind(this))
    this.fields.shipmentNotes.change(this.setNotes.bind(this))
    this.fields.presetSelect.change(this.onChangeShipmentPreset.bind(this))
    this.fields.volume.change(this.calculateVolumeWeight.bind(this))
    this.fields.customizeSeats.change(this.onCustomizeSeats.bind(this))
    this.fields.backwardDelivery.change(this.reloadPayment.bind(this))
    this.fields.paymentControl.change(this.reloadPayment.bind(this))
    this.$shipment.find('.add-seat-btn').click(this.onAddSeat.bind(this))
    this.$shipment.find('.update-preset-btn').click(this.onUpdateShipmentPreset.bind(this))
    this.$presetModal.find('form').submit(this.onCreateShipmentPreset.bind(this))
    this.el.find('.registry-add-waybill-btn').click(this.onOpenRegistriesAddWaybillModal.bind(this))
  }

  bindSeatEvents($seat) {
    $seat.find('.remove-seat-btn').click(this.onRemoveSeat.bind(this))
    $seat.find('.seat-weight, .seat-width, .seat-length, .seat-height').change(this.onCalculateSeat.bind(this))
  }

  // Rendering

  render() {
    const presetId = this.fields.presetSelect.val()

    if (presetId && !this.delivery.id) {
      this.applyShipmentPreset(presetId)
    } else {
      this.setInitialCargoType()
      this.$seatsList.find('.seat-item').each((_, item) => {
        this.calculateSeat($(item))
        this.bindSeatEvents($(item))
      })
      this.reloadShipment()
    }

    this.reloadPayment()
    this.makeActionsSticky()
  }

  reload() {
    this.setKind()
    this.setDefaults()
    this.reloadShipment()
    this.reloadPayment()
  }

  reloadShipment() {
    if (!this.isNovaposhta()) return this.$shipment.hide()

    this.$shipment.show()
    this.toggleShipmentContent()
    this.setEvaluationAmount()
    this.calculateVolumeWeight()
  }

  reloadPayment() {
    if (!this.isNovaposhta()) {
      this.$payment.hide()
      this.$sender.hide()
      return
    }

    this.$payment.show()
    this.$sender.show()
    const backwardDelivery = this.fields.backwardDelivery.is(':checked')
    const paymentControl = this.fields.paymentControl.is(':checked')
    this.$payment.find('.backward-delivery-fields').toggle(backwardDelivery)
    this.$payment.find('.payment-control-fields').toggle(paymentControl)
  }

  setInitialCargoType() {
    if (this.delivery.id) return

    this.switchCargoType('parcel')
  }

  toggleShipmentContent() {
    const cargoType = this.fetchCargoType()
    const customizeSeats = this.fields.customizeSeats.is(':checked')
    const $customizeSeatsBox = this.$shipment.find('.customize-seats-box')

    this.fields.shipmentNotes.val(this.shipmentNotes).prop('disabled', this.readonly)
    this.$shipment.find('.common-weight-box').show().find('input').prop('disabled', this.readonly)
    this.$shipment.find('.common-weight-select-box').hide().find('select').prop('disabled', true)

    switch (true) {
      case ['parcel', 'cargo'].includes(cargoType):
        $customizeSeatsBox.show()
        this.$customSeats.toggle(customizeSeats)
        this.$commonSeats.toggle(!customizeSeats)
        break
      case cargoType === 'paper':
        $customizeSeatsBox.hide()
        this.$customSeats.hide()
        this.$commonSeats.show()

        if (this.isNovaposhta()) {
          this.fields.shipmentNotes.val('Документи').prop('disabled', true)
          this.$shipment.find('.common-weight-box').hide().find('input').prop('disabled', true)
          this.$shipment.find('.common-weight-select-box').show().find('select').prop('disabled', this.readonly)
        }
        break
      case cargoType === 'pallet':
        $customizeSeatsBox.show()
        this.$customSeats.toggle(customizeSeats)
        this.$commonSeats.toggle(!customizeSeats)

        if (this.isNovaposhta()) this.fields.shipmentNotes.val('Палети').prop('disabled', true)
        break
      case cargoType === 'wheel':
        $customizeSeatsBox.hide()
        this.$customSeats.hide()
        this.$commonSeats.hide()
        if (this.isNovaposhta()) this.fields.shipmentNotes.val('Шини та диски').prop('disabled', true)
        break
      default:
        $customizeSeatsBox.hide()
        this.$customSeats.hide()
        this.$commonSeats.hide()
    }
  }

  setNotes() {
    this.shipmentNotes = this.fields.shipmentNotes.val()
  }

  switchCargoType(cargoType) {
    this.$shipment.find('.cargo-type-btn').removeClass('active')
    this.$shipment.find(`.cargo-type[value="${cargoType}"]`)
      .prop('checked', true)
      .closest('.cargo-type-btn')
      .addClass('active')
      .tab('show')
  }

  // Calculations

  calculateVolumeWeight() {
    const volume = Number(this.fields.volume.val() || 0)

    // Formula provided by Novaposhta
    const volumeWeight = round(volume * 250.0, 2)
    this.fields.volumeWeight.val(volumeWeight)
  }

  setEvaluationAmount() {
    const currency = this.doc.currency || {}

    if (this.isNovaposhta()) {
      this.el.find('.novaposhta-hint').show()
      this.el.find('.evaluation-amount-currency-sign').text(window.I18n.t('currencies.short_titles.UAH'))
    } else {
      this.el.find('.novaposhta-hint').hide()
      this.el.find('.evaluation-amount-currency-sign').text(currency.short_title)
    }

    if (this.readonly) return

    // TODO: Try to add support for multicurrency and remove this condition totally
    if (this.isNovaposhta() && currency.code !== 'UAH') {
      this.fields.autoEvaluationAmount.prop('disabled', true).prop('checked', false)

      // Clear amount when it's equal to amount updated from document.
      // Why? Because different currencies should have different amount, and if it's equal then
      // amount probably was set when currency was the same. Yeah, it's difficult to understand...
      if (Number(this.fields.evaluationAmount.val() || 0) === this.doc.amounts.amountProducts) {
        this.fields.evaluationAmount.val(null)
      }
    } else {
      this.fields.autoEvaluationAmount.prop('disabled', false)
    }

    this.fields.evaluationAmount.prop('readonly', this.fields.autoEvaluationAmount.is(':checked'))
    if (this.fields.autoEvaluationAmount.is(':checked')) this.doc.resetDeliveryEvaluationAmount()
  }

  // Seats

  onCustomizeSeats(e) {
    const customize = $(e.target).is(':checked')

    this.$customSeats.toggle(customize)
    this.$commonSeats.toggle(!customize)
  }

  onRemoveSeat(e) {
    e.preventDefault()

    if (this.$seatsList.find('.seat-item').length === 1) {
      this.$seatsList.find('.seat-item input, .seat-item input').val(null).attr('value', null)
    } else {
      $(e.target).closest('.seat-item').remove()
    }
  }

  onAddSeat(e) {
    e.preventDefault()
    this.addSeat()
  }

  onCalculateSeat(e) {
    this.calculateSeat($(e.target).closest('.seat-item'))
  }

  addSeat(data) {
    this.$seatsList.append(this.seatTemplate)
    const $seat = this.$seatsList.find('.seat-item:last-child')

    if (data) {
      $seat.find('.seat-weight').val(data.weight)
      $seat.find('.seat-length').val(data.length)
      $seat.find('.seat-width').val(data.width)
      $seat.find('.seat-height').val(data.height)
      $seat.find('.seat-service-no-box').prop('checked', data.service_no_box)
    }

    this.calculateSeat($seat)
    this.bindSeatEvents($seat)
  }

  calculateSeat($seat) {
    const width = Number($seat.find('.seat-width').val() || 0)
    const length = Number($seat.find('.seat-length').val() || 0)
    const height = Number($seat.find('.seat-height').val() || 0)

    // Formula provided by Novaposhta
    const volumeWeight = round((width * length * height) / 4000.0, 2)
    $seat.find('.seat-volume-weight').val(volumeWeight)
  }

  prepareSeatTemplate() {
    const $seat = this.$seatsList.find('.seat-item:first-child').clone()
    $seat.find('input, select').attr('value', null).val(null)

    return $seat[0].outerHTML
  }

  // Shipment presets

  onChangeShipmentPreset(e) {
    this.applyShipmentPreset($(e.target).val())
  }

  applyShipmentPreset(presetId) {
    if (this.readonly) return

    const preset = this.presets.find(p => p.id === presetId)
    if (!preset) return

    const data = preset.data

    this.switchCargoType(data.cargo_type)
    this.fields.shipmentNotes.val(data.shipment_notes)
    this.fields.evaluationAmount.val(data.evaluation_amount)
    this.fields.autoEvaluationAmount.prop('checked', data.auto_evaluation_amount)
    this.fields.customizeSeats.prop('checked', data.customize_seats)
    this.fields.seatsQuantity.val(data.seats_quantity)
    this.fields.weightSelect.val(data.weight)
    this.fields.weightInput.val(data.weight)
    this.fields.volume.val(data.volume)

    this.$seatsList.empty()
    data.seats.forEach(seat => this.addSeat(seat))

    this.setNotes()
    this.reloadShipment()
  }

  async onUpdateShipmentPreset(e) {
    e.preventDefault()

    try {
      const preset = {
        id: this.fields.presetSelect.val(),
        kind: 'shipment',
        data: JSON.stringify(this.shipmentParams())
      }

      if (!preset.id) {
        return showAlert(window.I18n.t('presets.not_selected'), { title: window.I18n.t('presets.not_selected_title') })
      }

      const url = `${gon.locale_path}/platform/presets/${preset.id}.json`
      const response = await axios.put(url, { preset })
      const data = response.data
      if (data.error) return showAlert(data.error, { error: true })

      this.presets = this.presets.map(preset => preset.id === data.id ? data : preset)

      showAlert(window.I18n.t('presets.success_save'), { title: window.I18n.t('notices.saved') })
    } catch (e) {
      console.error(e)

      let message = window.I18n.t('errors.unexpected_error')
      if (isAxiosNetworkError(e)) message = window.I18n.t('errors.network_error')
      if (isAxiosServerError(e)) message = window.I18n.t('errors.unexpected_error')
      showAlert(message, { error: true })
    }
  }

  // New preset will be created with current selected settings.
  // Preset should be submitted with additional params, so
  // here we add custom logic, and because select has custom_create === true, it won't send double submit.
  onCreateShipmentPreset(e) {
    e.preventDefault()

    const params = {
      preset: {
        title: this.$presetModal.find('.preset-title').val(),
        kind: 'shipment',
        data: JSON.stringify(this.shipmentParams())
      }
    }

    // onSubmitCreateOption is async
    onSubmitCreateOption(e, this.fields.presetSelect, this.$presetModal, {
      label: 'title',
      value: 'id',
      params, //: qs.stringify(params, { arrayFormat: 'brackets' }),
      callback: (response) => this.presets.push(response)
    })
  }

  shipmentParams() {
    const cargoType = this.fetchCargoType()

    return {
      cargo_type: cargoType,
      shipment_notes: this.fields.shipmentNotes.val(),
      evaluation_amount: this.numberParam(this.fields.evaluationAmount),
      auto_evaluation_amount: this.fields.autoEvaluationAmount.prop('checked'),
      customize_seats: this.fields.customizeSeats.prop('checked'),
      seats_quantity: this.numberParam(this.fields.seatsQuantity),
      weight: cargoType === 'paper' ? this.numberParam(this.fields.weightSelect) : this.numberParam(this.fields.weightInput),
      volume: this.numberParam(this.fields.volume),
      seats: Array.from(this.$customSeats.find('.seat-item')).map(seat => {
        const $seat = $(seat)
        return {
          weight: this.numberParam($seat.find('.seat-weight')),
          length: this.numberParam($seat.find('.seat-length')),
          width: this.numberParam($seat.find('.seat-width')),
          height: this.numberParam($seat.find('.seat-height')),
          service_no_box: $seat.find('.seat-service-no-box').prop('checked')
        }
      })
    }
  }

  // General

  async onPrint(e) {
    e.preventDefault()

    const tab = window.open('about:blank')

    const path = $(e.currentTarget).attr('href')
    try {
      const response = await axios.get($(e.currentTarget).attr('href'))
      const { url, reload, error } = response.data

      if (error) {
        tab.close()
        return showAlert(error)
      }

      tab.location = url
      tab.focus()
      if (reload) window.location.reload()
    } catch (e) {
      console.error(e)
      tab.close()
      showAlert(e.message)
    }
  }

  onChangeSenderCollaborator(e) {
    if (!this.provider) return

    const id = $(e.target).val()
    const collaborator = this.doc.collaborators.find(c => c.id === id)

    if (collaborator && collaborator.phone) this.changeSenderPhone(collaborator.phone)
  }

  setKind() {
    this.kind = this.fields.kind.val()

    if (this.kind.includes('novaposhta')) {
      this.provider = this.el.data('providers').find(p => p.service === 'novaposhta')
    } else {
      this.provider = null
    }
  }

  setDefaults() {
    this.fields.cost.prop('disabled', !!(this.provider || this.readonly))

    if (this.provider) {
      this.fields.status.prop('disabled', true).closest('.form-group').hide()
    } else {
      this.fields.status.prop('disabled', false).closest('.form-group').show()
    }

    this.togglePlannedDateFormat()

    // Do not set defaults for persisted delivery record
    if (this.persisted || this.data.readonly || !this.provider) return

    const data = this.provider.fields
    const collaborator = this.doc.collaborators.find(c => c.id === data.collaborator_id)
    const senderPhoneWidget = this.senderPhoneWidget

    // Sender fields
    if (collaborator) {
      this.fields.senderCollaborator.val(collaborator.id).trigger('change')
      this.changeSenderPhone(collaborator.phone)
    }
    this.fields.senderAddress.val(data.filial_address_id).trigger('change')

    // Payment fields
    this.fields.payerType.val(data.payer_type).trigger('change')
    this.fields.paymentMethod.val(data.payment_method).trigger('change')
    this.fields.backwardPayer.val(data.backward_payer).trigger('change')
    this.fields.backwardDelivery.prop('checked', data.backward_delivery_enabled)
    this.fields.paymentControl.prop('checked', data.payment_control_enabled)
  }

  togglePlannedDateFormat() {
    const plannedDate = day(this.fields.plannedDate.val())
    const dateType = this.isNovaposhta() ? 'date' : 'datetime-local'
    const format = this.isNovaposhta() ? DATE_PICKER_FORMAT : DATETIME_PICKER_FORMAT

    if (dateType === this.fields.plannedDate.attr('type')) return

    this.fields.plannedDate.val(null)
    this.fields.plannedDate.attr('type',  dateType).val(plannedDate.format(format))
  }

  // Registry

  async onOpenRegistriesAddWaybillModal(e) {
    e.preventDefault()

    this.$registriesModal = this.reloadRegistriesModal()
    this.$registriesModal.find('.loader-box').show()
    this.$registriesModal.find('.alert').hide()
    this.$registriesModal.find('.submit-registry-add-waybill').prop('disabled', true)

    try {
      const response = await axios.get(`${gon.locale_path}/platform/deliveries/available_registries.json`, {
        params: { provider_id: this.provider.id }
      })
      const data = response.data
      this.$registriesModal.find('.loader-box').hide()

      if (data.error) {
        this.$registriesModal.find('.alert').html(data.error).show()
      } else {
        this.showRegistriesAddWaybillForm(data)
      }
    } catch(e) {
      console.error(e)
      this.$registriesModal.find('.alert').html(e.message).show()
    }
  }

  showRegistriesAddWaybillForm(data) {
    const registries = data.map((registry, index) => {
      return `
        <div class='radio' style='margin-bottom: 15px'>
          <label>
            <input type='radio' name='registry[registry_id]' value='${registry.provider_registry_id}' ${index === 0 ? 'checked' : ''}>
            <div class='badge badge-info' style='display: inline-block; width: 100px; margin-right: 5px'>
              ${registry.registry_number}
            </div>
            <div class='badge badge-default' style='margin-right: 5px'>${registry.registry_created_at}</div>
            ${registry.registry_description ? `<div class='badge badge-warning'>${registry.registry_description}</div>` : ''}
          </label>
        </div>
      `
    })

    const html = `
      ${registries.join('')}
      <div class='radio' style='margin-bottom: 15px'>
        <label>
          <input type='radio' name='registry[registry_id]' value='' ${registries.length ? '' : 'checked'}>
          <div class='badge badge-success'>${window.I18n.t('deliveries.create_new_registry')}</div>
        </label>
      </div>
      <div style='margin-left: 20px; margin-top: -5px; display: flex'>
        <div class='form-group' style='min-width: 170px; margin-right: 10px'>
          <input type='date' name='registry[registry_date]' class='form-control' style='width: 170px'>
        </div>
        <div class='form-group' style='width: 100%'>
          <input type='text' name='registry[registry_description]' class='form-control' placeholder='${window.I18n.t('main.description')}'>
        </div>
      </div>
    `

    this.$registriesModal.find('form').html(html)
    this.$registriesModal.find('.submit-registry-add-waybill').prop('disabled', false)
  }

  async submitRegistryAddWaybill() {
    this.$registriesModal.find('.submit-registry-add-waybill').prop('disabled', true)
    this.$registriesModal.find('.loader-box').hide()
    this.$registriesModal.find('.alert').hide()
    const params = {
      ...formToObject(this.$registriesModal.find('form'), 'registry').registry,
      document_id: this.delivery.document_id || this.delivery.source_document_id
    }

    try {
      const response = await axios.put(`${gon.locale_path}/platform/deliveries/${this.delivery.id}/registry_add_waybill`, params)
      this.$registriesModal.find('.loader-box').hide()
      if (response.data.error) {
        this.$registriesModal.find('.submit-registry-add-waybill').prop('disabled', false)
        return this.$registriesModal.find('.alert').html(response.data.error).show()
      }
      window.location.reload()
    } catch(e) {
      console.error(e)
      this.$registriesModal.find('.submit-registry-add-waybill').prop('disabled', false)
      this.$registriesModal.find('.alert').html(e.message).show()
    }
  }

  reloadRegistriesModal() {
    return Bootbox.dialog({
      title: window.I18n.t('deliveries.registry_add_waybill'),
      message: `
        <div class='alert alert-danger'></div>
        <div class='loader-box'>
          <div class='loader blue'></div>
          <p class='text-center'><em>${window.I18n.t('main.loading')}...</em></p>
        </div>
        <form class='registry-add-waybill-form'>
        </form>
      `,
      onEscape: false,
      backdrop: 'static',
      animate: true,
      className: 'delivery-registries-add-waybill-modal',
      buttons: {
        confirm: {
          label: window.I18n.t('buttons.confirm'),
          className: 'btn-success submit-registry-add-waybill',
          callback: (e) => {
            e.preventDefault()
            this.submitRegistryAddWaybill()
            return false
          }
        },
        cancel: {
          label: window.I18n.t('buttons.cancel'),
          className: 'btn-default',
        }
      }
    })
  }

  // Helpers

  numberParam($field) {
    const val = $field.val()

    if (!val) return null

    return Number(val)
  }

  fetchCargoType() {
    return this.$shipment.find('.cargo-type:checked').val()
  }

  isNovaposhta() {
    if (!this.kind) return false

    return this.kind.includes('novaposhta')
  }

  changeSenderPhone(phone) {
    const widget = this.el.find('.delivery-sender-phone')[0].widgetInstance
    // Phone widget may not be initialized
    if (widget) {
      widget.changeNumber(phone)
    } else {
      this.el.find('.delivery-sender-phone-field').val(phone)
    }
  }

  makeActionsSticky() {
    // In order to show sticky buttons set side panel height.
    // Without height sticky position doesn't work.
    if (isMobile()) return

    this.el.find('.side-panel').css('height', this.el.outerHeight())
  }
}

export default DeliveryWidget

import MediumEditor from 'medium-editor'
import BaseWidget from './base_widget'

const OPTIONS = {
  toolbar: {
    allowMultiParagraphSelection: true,
    buttons: ['bold', 'italic', 'underline', 'orderedlist', 'unorderedlist', 'anchor', 'h2', 'h3', 'h4', 'h5',
      'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'quote', 'html', 'image',
    ],
    diffLeft: 0,
    diffTop: -10,
    firstButtonClass: 'medium-editor-button-first',
    lastButtonClass: 'medium-editor-button-last',
    relativeContainer: null,
    standardizeSelectionStart: false,
    static: false,
    /* options which only apply when static is true */
    align: 'center',
    sticky: false,
    updateOnEmptySelection: false,
  },
  paste: {
    forcePlainText: false,
    cleanPastedHTML: true,
    cleanReplacements: [[/<h\d/g, '<h3'], [/<\/h\d>/g, '</h3>']],
    cleanAttrs: ['class', 'style', 'dir', 'align', 'width', 'id'],
    cleanTags: ['meta'],
    unwrapTags: []
  },
  imageDragging: false,
  placeholder: {
    text: '', // Because doesn't work correct, you can't click on text and start edit, should double click
    hideOnClick: false
  }
}
const ALLOWED_TYPES = ['image']
const CLASS_DRAG_OVER = 'medium-editor-dragover'

class Editor extends BaseWidget {
  render() {
    this.el.addClass('editor')
    this.editor = new MediumEditor(this.el, this.options())
  }

  options() {
    return OPTIONS
  }

  unmount() {
    this.editor.destroy()
  }

  bindEvents() {
    this.editor.subscribe('editableDrop', this.handleDrop.bind(this))
  }

  handleDrop(event) {
    // Prevent file from opening in the current window
    event.preventDefault();
    event.stopPropagation();
    // Select the dropping target, and set the selection to the end of the target
    // https://github.com/yabwe/medium-editor/issues/980
    this.editor.selectElement(event.target);
    var selection = this.editor.exportSelection();
    selection.start = selection.end;
    this.editor.importSelection(selection);
    // IE9 does not support the File API, so prevent file from opening in the window
    // but also don't try to actually get the file
    if (event.dataTransfer.files) {
      Array.prototype.slice.call(event.dataTransfer.files).forEach(function (file) {
        if (this.isAllowedFile(file)) {
          if (file.type.match('image')) {
            this.insertImageFile(file)
          }
        }
      }, this)
    }
    // Make sure we remove our class from everything
    this.clearClassNames(event.target)
  }

  isAllowedFile(file) {
    return ALLOWED_TYPES.some(function (fileType) {
      return !!file.type.match(fileType)
    })
  }

  insertImageFile(file) {
    if (typeof FileReader !== 'function') return
    var fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    // attach the onload event handler, makes it easier to listen in with jasmine
    fileReader.addEventListener('load', function (e) {
      var addImageElement = document.createElement('img')
      this.uploadImage(e.target.result, (response) => {
        addImageElement.src = response.original
        MediumEditor.util.insertHTMLCommand(document, addImageElement.outerHTML)
      })
    }.bind(this))
  }

  uploadImage(file, callback) {
    $.ajax({
      method: 'POST',
      data: { image: { file }, model: this.el.data('image-model') },
      url: `${gon.locale_path}/platform/images`,
      dataType: 'json',
      success: callback,
      error(error) {
        console.error(error.status, error.statusText)
      }
    })
  }

  clearClassNames(element) {
    var editable = MediumEditor.util.getContainerEditorElement(element),
      existing = Array.prototype.slice.call(editable.parentElement.querySelectorAll('.' + CLASS_DRAG_OVER));

    existing.forEach(function (el) {
      el.classList.remove(CLASS_DRAG_OVER);
    })
  }
}

export default Editor

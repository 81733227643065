import axios from 'axios'

export function onGenerateProductCode(e) {
  e.preventDefault()

  const $button = $(e.currentTarget)

  axios.get($button.data('url'))
    .then(({data}) => {
      $button.closest('.generate-code-box').find('input').val(data.code)
    })
}

// Keep in sync with DocumentItem#allow_materials?
export function isLineItemAllowMaterials(productType, typedoc) {
  if (!productType || !typedoc) return

  return (productType === 'manufacture' && ['manufacture'].includes(typedoc)) ||
    (productType === 'kit' &&
      [
        'product_off', 'retail', 'sale', 'sale_return', 'retail_return', 'client_order', 'showcase'
      ].includes(typedoc)
    )
}

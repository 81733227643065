// Widget to handle interactions with discount form

import BaseWidget from './base_widget'

// TODO: Add validations

class DiscountWidget extends BaseWidget {
  EMPTY_LEVEL = { amount: '', rate: '' }

  render() {
    this.discount = this.el.data('discount')
    this.discountLevels = this.discount.levels || []
    this.reload()
  }

  reload() {
    this.renderLevels()
  }

  renderLevels() {
    if (!['cumulative', 'progressive'].includes(this.discount.kind)) return

    this.$discountLevels = this.el.find('.discount-levels tbody')
    this.discountLevelIndex = this.discountLevels.length

    this.$discountLevels.empty()

    this.discountLevels.forEach((level, index) => {
      const row = $(this.levelTemplate(level, index))
      this.$discountLevels.append(row)
    })
  }

  bindEvents() {
    this.el.find('.add-discount-level').click(this.handleAddDiscountLevel.bind(this))
    this.el.find('.remove-discount-level').click(this.handleRemoveDiscountLevel.bind(this))
    this.el.find('.use-fixed-price').change(this.handleUseFixedPrice.bind(this))
  }

  handleAddDiscountLevel(e) {
    e.preventDefault()
    const row = $(this.levelTemplate(this.EMPTY_LEVEL, this.discountLevelIndex))
    this.discountLevelIndex += 1
    this.$discountLevels.append(row)
  }

  handleRemoveDiscountLevel(e) {
    e.preventDefault()
    $(e.target).closest('tr').empty()
  }

  handleUseFixedPrice(e) {
    const checked = $(e.target).prop('checked')
    this.el.find('.typeprice-select').prop('disabled', checked)
    this.el.find('.fixed-price').prop('disabled', !checked)
  }

  levelTemplate(level, index) {
    const prefix = `discount[levels][${index}]`

    return `
      <tr class='discount-level-item'>
        <td>
          <input type='number' name='${prefix}amount' value='${level.amount}' step='any' min=0 class='form-control' />
        </td>
        <td>
          <div class='flex'>
            <input type='number' name='${prefix}rate' value='${level.rate}' step='any' min=0 max=100 class='form-control' />
            <button role='button' class='remove-discount-level btn btn-default btn-icon'><i class='fas fa-trash'></i></button>
          <div>
        </td>
      </tr>
    `
  }
}

export default DiscountWidget

// Base class for independent UI widgets.
//
// Widget of child class could be attached to element manually, or automatically to any element
// that has data-widget="WidgetClassName" attribute set.
//
// Child classes can/should redefine:
//
// * initialize: performed once on widget creation, should be used to setup internal variables and
//   such stuff
// * render: by default, performed once on widget construction, should update DOM with proper
//   widget contents
// * events: map of sub-elements selectors to class methods, see method description
// * isValidConfig: see method description
//
// Other than that, there are currently not many conventions regarding widgets.

class BaseWidget {

  constructor(el) {
    if (el && el.widgetInstance) return
    el.widgetInstance = this
    this.el = $(el)
    this.data = this.el.data()
    if (!this.isValidConfig()) return
    this.initialize()
    this.render()
    this.bindEvents()
  }

  // For elements that dynamically created in render, turbolinks will recreate it again on
  // history back navigation. Write cleanUp method to restore initial html
  // Note. For now turbolinks cache is disabled by meta name='turbolinks-cache-control' content='no-cache'
  // in critical pages, such as document, modification and content pages.
  unmount() {
    return
  }

  initialize() {
    // do some initialization
  }

  render() {
    // handle rendering
  }

  // Events map, like in Backbone Views.
  // Format: {
  //   'input,change .quick_search': 'classMethod'
  // }
  events() {
    return {}
  }

  // Should check if all props are ok and return false and log error if not.
  isValidConfig() {
    return true
  }

  bindEvents() {
    return false
  }

  // bindEvents() {
  //   $.each(this.events(), function(event, handler) {
  //      const split = event.split(/\s+/)
  //      const events = split[0]
  //      const selector = split.slice(1, split.length).join(' ')
  //      if (typeof handler !== 'function') handler = this[handler]
  //      if (selector) {
  //        $(this.el).on(events.replace(',', ' '), selector, handler)
  //      } else {
  //        $(this.el).on(events.replace(',', ' '), handler)
  //      }
  //   })
  // }

  sendPostRequest(path, data) {
    $.post(path, data)
      .done(resp => showFlashNotice(resp.notice, 'success', $.guid++))
      .fail(resp => showFlashNotice(resp.responseJSON.alert, 'danger', $.guid++))
  }
}

export default BaseWidget

import BaseWidget from './base_widget'
import { debounce } from 'lodash'

class StickyTable extends BaseWidget {
  initialize() {
    this.$header = this.el.find('.table-header, thead')
    this.$headerRows = this.$header.find('.table-row, tr')
    this.$tableBox = this.el.find('.table-box')
    this.headerHeight = Math.round(this.$header.height())
    this.currentHeaderWidth = null
    this.currentHeaderHeight = null
    this.currentDocumentWidth = null
    this.sticky = false
    this.blockTablyAlignScroll = false
    this.blockStickyAlignScroll = false
    this.lazyUnblockStickyAlignScroll = debounce(this.unblockStickyAlignScroll, 300)
    this.lazyUnblockTableAlignScroll = debounce(this.unblockTableAlignScroll, 300)
  }

  bindEvents() {
    if (!this.$header.length) return

    $(document).on('scroll', this.addSticky.bind(this))
    this.$tableBox.on('scroll', this.addSticky.bind(this))
    this.$stickyTable.scroll(this.onScrollSticky.bind(this))
    const resizeObserver = new ResizeObserver(this.onResize.bind(this))
    resizeObserver.observe(this.el[0])

    // $(window).on('resize', this.addSticky.bind(this))
    //
    // // Handle cases when user just quickly resize window
    // this.$header.on('resize', this.setWidths.bind(this))
    // $(window).on('resize', this.setWidths.bind(this))
  }

  unmount() {
    $(document).off('scroll', this.addSticky.bind(this))
    $(window).off('resize', this.addSticky.bind(this))

    this.$header.off('resize', this.setWidths.bind(this))
    $(window).off('resize', this.setWidths.bind(this))

    this.$tableBox.off('scroll', this.addSticky.bind(this))
    if (this.$stickyTable) this.$stickyTable.remove()
  }

  render() {
    if (!this.$header.length) return

    this.$tableBox.css({'overflow-x': 'scroll', 'width': '100%'})
    // To be sure that there is no internal scroll class
    this.el.find('.scroll-x').removeClass('scroll-x')

    this.el.addClass('table-container-sticky')

    this.$stickyTable = this.el.find('.css-table, table').clone()
    this.$stickyTable.addClass('table-sticky')
    this.$stickyHeader = this.$header.clone()
    this.$stickyHeader.addClass('sticky-header')
    this.$stickyTable.html(this.$stickyHeader)
    this.$stickyHeaderRows = this.$stickyHeader.find('.table-row, tr')
    this.$header.closest('.css-table, table').parent().prepend(this.$stickyTable)

    this.setWidths(true)

    // For case when page loaded slow
    setTimeout(() => {
      this.setWidths(true)
      this.$stickyTable.addClass('loaded')
    }, 100)
    setTimeout(() => this.setWidths(true), 300)
  }

  setWidths(force = false) {
    const headerWidth = Math.round(this.$header.width())
    const headerOffset = 5
    const headerHeight = Math.round(this.$header.height()) + headerOffset
    const documentWidth = Math.round($(document).width())

    if (!force && this.currentHeaderWidth === headerWidth && this.currentHeaderHeight === headerHeight &&
      this.currentDocumentWidth === documentWidth) return

    const offsetLeft = this.el.offset().left + 1
    const offsetRight = $(document).innerWidth() - (offsetLeft + this.el.outerWidth()) + 2

    this.$stickyTable.css({'left': offsetLeft, 'right': offsetRight, 'width': `calc(100% - ${offsetLeft + offsetRight}px)`, 'height': `${headerHeight - headerOffset}px`})
    this.$stickyHeader.css({'height': `${headerHeight}px`, 'width': `${this.$header[0].offsetWidth + 1}px`})

    // Header could be with more than one row
    for (let r = 0; r < this.$headerRows.length; r++) {
      const $headerRow = this.$headerRows.eq(r)
      const $stickyHeaderRow = this.$stickyHeaderRows.eq(r)

      const $headerKids = $headerRow.find('.table-cell, th, td')
      const $stickyHeaderKids = $stickyHeaderRow.find('.table-cell, th, td')

      for (let i = 0; i < $headerKids.length; i++) {
        $stickyHeaderKids[i].style.width = `${Math.round($headerKids[i].offsetWidth)}px`
        $stickyHeaderKids[i].style.height = `${Math.round($headerKids[i].offsetHeight)}px`
      }
    }

    this.scrollEnabled = this.$stickyTable[0].offsetWidth < this.$stickyHeader[0].offsetWidth - 5
    this.$stickyTable.toggleClass('scroll-enabled', this.scrollEnabled)
    this.currentHeaderWidth = headerWidth
    this.currentHeaderHeight = headerHeight
    this.currentDocumentWidth = documentWidth
  }

  addSticky() {
    const scrollY = window.scrollY
    const scrollX = Math.round(this.$tableBox[0].scrollLeft)

    if (this.shouldSkipAddSticky(this.currentScrollY, scrollY, this.currentScrollX, scrollX)) return

    this.setWidths()

    const offsetTop = this.el.find('.table-body, tbody').offset().top - this.headerHeight
    const offsetBottom = this.el[0].offsetHeight + offsetTop - 200

    if (scrollY > offsetTop && scrollY < offsetBottom) {
      if (!this.sticky) {
        this.el.addClass('sticky-active')
        this.sticky = true
      }
    } else {
      this.el.removeClass('sticky-active')
      this.sticky = false
    }

    if (this.currentScrollX === scrollX) return

    this.onScrollTable()
    this.currentScrollX = scrollX
  }

  shouldSkipAddSticky(currentScrollY, scrollY, currentScrollX, scrollX) {
    const hesitation = 13

    return (currentScrollX === scrollX) &&
      // We can't just compare currentScrollY and scrollY for equality, because it causes fast swing (hesitation)
      // So we use some offset.
      ((currentScrollY === scrollY) || (currentScrollY < (scrollY + hesitation) && currentScrollY > (scrollY - hesitation)))
  }

  onResize(entries) {
    entries.forEach(entry => {
      if (!entry.target.isResizing) { // Add this condition to prevent infinite loop
        entry.target.isResizing = true; // Set a flag to track if the size has been updated

        this.addSticky()

        entry.target.isResizing = false; // Reset the flag after resizing logic is done
      }
    })
  }

  onScrollTable() {
    if (this.blockTableAlignScroll) return
    this.blockStickyAlignScroll = true
    this.$stickyTable.scrollLeft(this.$tableBox.scrollLeft())
    this.lazyUnblockStickyAlignScroll()
  }

  onScrollSticky() {
    if (this.blockStickyAlignScroll) return
    this.blockTableAlignScroll = true
    this.$tableBox.scrollLeft(this.$stickyTable.scrollLeft())
    this.lazyUnblockTableAlignScroll()
  }

  unblockStickyAlignScroll() {
    this.blockStickyAlignScroll = false
  }

  unblockTableAlignScroll() {
    this.blockTableAlignScroll = false
  }
}

export default StickyTable

import BaseWidget from './base_widget'
import Bootbox from 'bootbox'
import axios from 'axios'
import fluidPlayer from 'fluid-player'

class TutorialsWidget extends BaseWidget {
  initialize() {
    this.userTutorials = this.el.data('user-tutorials')
    this.allTutorials = this.el.data('all-tutorials')
  }

  render() {
  }

  bindEvents() {
    this.el.find('.spoiler').click(this.showPrediction.bind(this))
    this.el.find('.tutorial-item').click(this.openTutorialVideo.bind(this))
    // this.el.find('.complete-tutorial-btn').click(this.completeTutorial.bind(this))
    // this.el.find('.open-tutorial-guide-btn').click(this.openTutorialGuide.bind(this))
    // this.el.find('.panel .collapse-btn').click(this.onToggleTutorial.bind(this))
  }

  showPrediction(e) {
    $(e.currentTarget).removeClass('spoiler')
  }

  videoHtml(playerId, mp4, poster) {
    return `
      <video class='video-player' id='${playerId}' controls autoplay poster='${poster}'>
        <source src='${mp4}' type='video/mp4' />
        <!-- <source src='/path/to/video.webm' type='video/webm' /> -->

        <!-- Captions are optional -->
        <!-- <track kind='captions' label='English captions' src='/path/to/captions.vtt' srclang='en' default /> -->
      </video>
    `
  }

  youtubeEmbedHtml(playerId, video) {
    const origing = 'https://skynum.ua'
    const src = `${video}?origin=${origin}&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`

    return `
      <div id='${playerId}'>
        <iframe
          class='video-player'
          src="${src}"
          allowfullscreen
          allowtransparency
          allow="autoplay"
        ></iframe>
      </div>
    `
  }

  openTutorialVideo(e) {
    e.preventDefault()
    const $tutorial = $(e.currentTarget)
    let video = $tutorial.data('tutorial-video')

    if (!video) return

    const title = $tutorial.data('tutorial-title')
    const tutorialKey = $tutorial.data('tutorial-key')
    const guideUrl = $tutorial.data('tutorial-guide')
    const youtubeUrl = $tutorial.data('tutorial-youtube')
    const playerId = `player-${tutorialKey}`
    const poster = $tutorial.data('tutorial-poster')
    const isYoutube = false // ['skypos_settings', 'skypos_sales', 'order_statuses'].includes(tutorialKey)

    let body

    if (isYoutube) {
      body = this.youtubeEmbedHtml(playerId, video)
    } else {
      body = this.videoHtml(playerId, video, poster)
    }

    const buttons = {}

    if (guideUrl) {
      buttons.guide = {
        label: `<i class='fas fa-list'></i>`,
        className: 'btn-default',
        callback: () => {
          const a = document.createElement('a')
          a.href = guideUrl
          a.target = '_blank'
          a.click()
          a.remove()
          return false
        }
      }
    }

    if (youtubeUrl) {
      buttons.youtube = {
        label: `<i class='fas fa-play'></i>`,
        className: 'btn-default',
        callback: () => {
          const a = document.createElement('a')
          a.href = youtubeUrl
          a.target = '_blank'
          a.click()
          a.remove()
          return false
        }
      }
    }

    const $modal = Bootbox.dialog({
      message: `<div class='tutorial-video-container'>${body}</div>`,
      onEscape: true,
      backdrop: true,
      animate: true,
      className: 'modal tutorial-video-modal',
      size: 'large',
      onHide: () => this.player && this.player.destroy(),
      buttons
    })

    if (isYoutube) return

    this.player = fluidPlayer($modal.find(`#${playerId}`)[0],
      {
        layoutControls: {
          fillToContainer: true,
          allowDownload: true,
          allowTheatre: false,
          autoPlay: true,
          posterImageSize: 'cover',
          miniPlayer: {
            enabled: false
          }
        }
      }
    )
  }

  // async openTutorialGuide(e) {
  //   e.preventDefault()
  //
  //   const $link = $(e.currentTarget)
  //   const guide = await this.fetchGuide($link)
  //
  //   if (!guide) return
  //
  //   const $modal = Bootbox.dialog({
  //     title: $link.text(),
  //     message: guide.body,
  //     onEscape: true,
  //     backdrop: true,
  //     animate: true,
  //     className: 'tutorial-guide-modal modal right modal-lg',
  //     buttons: {
  //       close: {
  //         label: window.I18n.t('buttons.cancel'),
  //         className: 'btn-default'
  //       },
  //       newTab: {
  //         label: `<span style='margin-right: 7px'>${window.I18n.t('buttons.open_in_new_tab')}</span><i class='fas fa-external-link-alt'></i>`,
  //         className: 'btn-warning',
  //         callback: () => {
  //           const a = document.createElement('a')
  //           a.href = guide.url
  //           a.target = '_blank'
  //           a.click()
  //           a.remove()
  //         }
  //       }
  //     }
  //   })
  // }
  //
  // async fetchGuide($link) {
  //   try {
  //     const guide_path = $link.data('guide-path')
  //
  //     if ($link.hasClass('loading')) return
  //
  //     $link.addClass('loading')
  //     // GET to make it work with axios retry
  //     const response = await axios.get(`${gon.locale_path}/platform/academy/tutorials/guide`, { params: { guide_path }})
  //     $link.removeClass('loading')
  //     return response.data
  //   } catch (e) {
  //     console.error(e)
  //     $link.removeClass('loading')
  //     return null
  //   }
  // }
  //
  // onToggleTutorial(e) {
  //   e.preventDefault()
  //
  //   const $panel = $(e.target).closest('.panel')
  //
  //   if ($panel.hasClass('loading')) return
  //
  //   if ($panel.hasClass('opened')) {
  //     if ($panel.hasClass('panel-success')) return this.collapsePanel($panel)
  //     this.collapseTutorial($panel)
  //   } else {
  //     if ($panel.hasClass('panel-success')) return this.expandPanel($panel)
  //     this.expandTutorial($panel)
  //   }
  // }
  //
  // async expandTutorial($panel) {
  //   try {
  //     const tutorialKey = $panel.data('tutorial-key')
  //     $panel.addClass('loading')
  //     // GET to make it work with axios retry
  //     await axios.get(`${gon.locale_path}/platform/academy/tutorials/${tutorialKey}/open`)
  //     $panel.addClass('panel-pending')
  //     this.expandPanel($panel)
  //   } catch (e) {
  //     console.error(e)
  //   } finally {
  //     $panel.removeClass('loading')
  //   }
  // }
  //
  // async collapseTutorial($panel) {
  //   try {
  //     const tutorialKey = $panel.data('tutorial-key')
  //     $panel.addClass('loading')
  //     // GET to make it work with axios retry
  //     await axios.get(`${gon.locale_path}/platform/academy/tutorials/${tutorialKey}/close`)
  //     $panel.removeClass('panel-pending')
  //     this.collapsePanel($panel)
  //   } catch(e) {
  //     console.error(e)
  //   } finally {
  //     $panel.removeClass('loading')
  //   }
  // }
  //
  // async completeTutorial(e) {
  //   e.preventDefault()
  //
  //   const $button = $(e.currentTarget)
  //   const $panel = $button.closest('.panel')
  //   const tutorialKey = $panel.data('tutorial-key')
  //   const $nextPanel = $panel.nextAll('.panel:not(.panel-success)').first()
  //
  //   if ($panel.hasClass('loading')) return
  //
  //   try {
  //     $panel.addClass('loading')
  //     // GET to make it work with axios retry
  //     await axios.get(`${gon.locale_path}/platform/academy/tutorials/${tutorialKey}/complete`)
  //     $button.remove()
  //     $panel.removeClass('panel-pending panel-default').addClass('panel-success')
  //     this.collapsePanel($panel)
  //     this.expandTutorial($nextPanel)
  //     this.completedCount += 1
  //     this.updateProgress()
  //   } catch (e) {
  //     console.error(e)
  //   } finally {
  //     $panel.removeClass('loading')
  //   }
  // }
  //
  // collapsePanel($panel) {
  //   $panel.removeClass('opened').addClass('closed')
  //   $panel.find('.panel-body').collapse('hide')
  // }
  //
  // expandPanel($panel) {
  //   $panel.removeClass('closed').addClass('opened')
  //   $panel.find('.panel-body').collapse('show')
  // }
}

export default TutorialsWidget

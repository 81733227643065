import Rails from '@rails/ujs'
import Toastify from 'toastify-js'
import { submitForm } from 'services/form'
import { onGenerateProductCode } from 'services/products'
import { addSelectOptions } from 'services/select'

export function bindGlobalEvents() {
  $(document).on('ajax:before', '[data-remote]', () => {
    Turbolinks.clearCache()
  })

  // Save last rails_ujs request in order to retry on failure.
  // For example, on network or server 500 errors.
  $(document).on('ajax:beforeSend', function(e) {
    const [xhr, options] = e.detail
    window.lastAjaxRequestOptions = options
  })

  // Display error message with ability to retry last request.
  $(document).on('ajax:error', function(e) {
    $('.toastify-ajax-error').remove()
    Toastify({
      text: window.I18n.t('errors.ajax_error'),
      className: 'toastify-ajax-error alert-danger',
      duration: 10000,
      onClick: () => {
        $('.toastify-ajax-error').remove()
        Rails.ajax(window.lastAjaxRequestOptions)
      }
    }).showToast()
  })

  // Prevent sumbit form on enter
  $(document).on('keyup keypress', 'form:not(".submit-on-enter")', function(e) {
    const keyCode = e.keyCode || e.which
    const nodeName = e.target.nodeName.toLowerCase()
    if (keyCode === 13 && (!['textarea'].includes(nodeName) && !(e.target.className || '').includes('editor'))) {
      e.preventDefault()
      return false
    }
  })

  $(document).on('change', 'form.submit-on-change input, form.submit-on-change select', function(e) {
    const $form = $(e.target).closest('form')
    submitForm($form)
  })

  $(document).on('change', 'input.submit-on-change, select.submit-on-change', function(e) {
    const $form = $(e.target).closest('form')
    if ($form.hasClass('submit-on-change')) return
    submitForm($form)
  })

  $(document).on('keydown', 'input.submit-on-enter', function(e) {
    const $input = $(e.currentTarget)

    if ($input.prop('readonly')) return

    const keyCode = e.keyCode || e.which

    if (keyCode === 13) {
      const $form = $input.closest('form')
      submitForm($form)
    }
  })

  // For landing's seo full description
  $(document).on('click', 'a.show-full-description', function(e) {
    e.preventDefault()
    const section = $(e.target).closest('section')
    section.find('.full-description').removeClass('half-mode')
    section.find('.half-mode-opacity').hide()
    $(e.target).hide()
  })

  $(document).on('click', '.generate-barcode-btn, .generate-next-code-btn', onGenerateProductCode)

  $(document).tooltip({selector: '[data-toggle="tooltip"]'})
  $(document).popover({selector: '[data-toggle="popover"]'})

  $(document).on('change', '[data-toggle-element]', function(e) {
    const element = $(e.target).data('toggle-element')
    const toggleByValue = $(e.target).data('toggle-value')
    let toggle

    if (toggleByValue) {
      toggle = $(e.target).val().toString() === toggleByValue.toString()
    } else {
      toggle = $(e.target).prop('checked')
    }

    toggle ? $(element).show() : $(element).hide()
  })

  $(document).on('focus', 'input[type=number], input[type=text]', (e) => $(e.target).select())

  // Fix scrolling bug issue, when opening second modal from first modal
  $(document).on('hidden.bs.modal', '.modal', function () {
    $('.modal:visible').length && $(document.body).addClass('modal-open')
  })

  $(document).on('click', '.panel .panel-heading.clickable', function (e) {
    const $head = $(e.target).closest('.panel-heading')

    if (!$head.hasClass('panel-collapsed')) {
      $head.parents('.panel').find('.panel-body').slideUp()
      $head.addClass('panel-collapsed');
      $head.find('.collapse-icon').removeClass('fa-minus').addClass('fa-plus')
    } else {
      $head.parents('.panel').find('.panel-body').slideDown()
      $head.removeClass('panel-collapsed');
      $head.find('.collapse-icon').removeClass('fa-plus').addClass('fa-minus')
    }
  })

  $(document).on('change', '[data-filter-select]', function(e) {
    const $select = $($(e.target).data('filter-select'))
    const options = $(e.target).data('select-options').map(item => {
      return { label: item[0], value: item[1] }
    })

    addSelectOptions($select, options, { clear: true, allowBlank: true, triggerChange: true, label: 'label', value: 'value' })
  })

  $(document).on('click', '.pretty-quantity .show-real-quantity-btn', function (e) {
    e.preventDefault()

    const $quantity = $(e.target).closest('.pretty-quantity')
    $quantity.find('.quantity-rounded, .show-real-quantity-btn').hide()
    $quantity.find('.quantity-real').show()
  })
}

export const CREATE_OPTION = 'CREATE_OPTION'
import axios from 'axios'
import { errorMessage } from 'services/errors'

export function addSelectOptions(select, items, { selected, clear, label, value, allowBlank, triggerChange = true } = {}) {
  if (clear) clearSelectOptions(select, { triggerChange: false })

  const isMulti = select.prop('multiple')
  let options = items || []

  options = options.map(item => {
    const text = item[label] || item.title || item.name || item.label
    const id = item[value] || item.id
    return new Option(text, id, false, false)
  })

  if (allowBlank) {
    const blankLabel = typeof allowBlank === 'string' ? allowBlank : ''
    const blankOption = new Option(blankLabel, '', false, false)
    options = [blankOption, ...options]
  }

  select.append(options)

  if (selected) {
    const newValue = isMulti ? selected.map(item => item[value] || selected.id) : selected[value] || selected.id
    select.val(newValue)

    // Value shouldn't be CREATE_OPTION, but if no any options it set automatically
  } else if (select.val() === CREATE_OPTION) {
    select.val(null)
  }

  if (triggerChange) select.trigger('change')
}

export function clearSelectOptions(select, { triggerChange = true } = {}) {
  select.find('option').each((_, option) => {
    const value = $(option).val()
    if (!value || (value !== CREATE_OPTION)) $(option).remove()
  })
  select.val(null)
  if (triggerChange) select.trigger('change')
}

export async function onSubmitCreateOption(e, select, $modal, { label, value, params = null, similarSelects = [], callback = null }) {
  e.preventDefault()
  const $form = $modal.find('form')
  const $alert = $modal.find('.modal-body > .alert-danger')
  $modal.find(':submit').prop('disabled', true)
  $alert.hide()

  const data = params || new FormData($form[0])
  const method = $form.prop('method')
  const url = $form.prop('action')

  try {
    const response = await axios[method](url, data)

    if (response.data.error) {
      $modal.find(':submit').prop('disabled', false)
      $alert.text(response.data.error).show()
      // Scroll to the begining of modal
      $modal.find('.modal-content')[0].scrollIntoView()
    } else {
      addSelectOptions(select, [response.data], { selected: response.data, clear: false, label, value, triggerChange: false })
      // Many select could be passed, each of them should be updated
      similarSelects.forEach(similarSelect => {
        addSelectOptions(similarSelect, [response.data], { clear: false, label, value, triggerChange: false })
      })
      $modal.find(':submit').prop('disabled', false)
      $modal.modal('hide')
      if (callback) callback(response.data)

      // This should be the last call,
      // otherwise all event listener for change select will be triggered before callback.
      select.trigger('change')
    }
  } catch (e) {
    const message = errorMessage(e)

    $modal.find(':submit').prop('disabled', false)
    $alert.text(message).show()

    // Scroll to the begining of modal
    $modal.find('.modal-content')[0].scrollIntoView()
    console.error(message)
  }
}

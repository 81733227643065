import Bootbox from 'bootbox'

// Show error modal, if 'error' argument passed also throws this error
export function showAlert(message, { error = null, title = null, className = '' } = {}) {
  const text = message || window.I18n.t('errors.unexpected_error')
  let displayTitle = title

  if (!displayTitle) {
    displayTitle = error ? window.I18n.t('errors.error') : window.I18n.t('errors.attention')
  }

  Bootbox.dialog({
    title: displayTitle,
    message: `<div class='${className}' style='font-weight: bold; display: block !important;'>${text}</div>`,
    onEscape: true,
    backdrop: true,
    animate: false,
    buttons: {
      cancel: {
        label: window.I18n.t('buttons.ok'),
        className: 'btn-default'
      }
    }
  })

  if (error) throw error
}

export function isAxiosNetworkError(e) {
  if (!e.toJSON) return false

  return e.toJSON().message === 'Network Error'
}

export function isAxiosServerError(e) {
  const code = e && e.response && e.response.status
  if (!code) return false

  return code > 400 && ![401, 403, 404].includes(code)
}

export function errorMessage(error) {
  if (isAxiosNetworkError(error)) return window.I18n.t('errors.network_error')
  if (isAxiosServerError(error)) return window.I18n.t('errors.unexpected_error')

  const message = error.response && error.response.data.error
  return message || [error.name, error.message].filter(Boolean).join('. ')
}

import BaseWidget from './base_widget'
import ListItemTemplate from 'services/list_item_template'
import { round, sum, last, compact } from 'lodash'
import axios from 'axios'
import { addSelectOptions } from 'services/select'
import Catalog from 'main/document/catalog'
import { formattedQuantity, add, multiply } from 'services/numbers'

const ITEM_CLASS = 'line-item'
const TEMPLATE_CLASS = 'new-template-line-item'
const VALUE_CLASS = 'material-id'

class ManufactureWidget extends BaseWidget {
  initialize() {
    // this.data structure should be simalar to doc.js,
    // becuase we use if in Catalog
    this.doc = this.el.data('document') || {}
    this.readonly = this.el.data('readonly')
    this.data = {
      typeprice: 'price_supply',
      document_id: this.doc.id,
      stock_from_id: this.doc.stock_from_id
    }
    this.tables = {
      product: {
        name: 'product',
        body: this.el.find('#products-table .table-body'),
        template: null
      },
      expense: {
        name: 'expense',
        body: this.el.find('#expenses-table .table-body'),
        template: null
      }
    }
    this.$productsCatalog = new Catalog($('#products-catalog-modal'), this)
    this.setTypeprice()
  }

  bindEvents() {
    if (!this.readonly) this.el.find('.typeprice-select').change(this.setTypeprice.bind(this))
    this.bindLineItemEvents(this.el)
  }

  openProductsCatalog(e) {
    e.preventDefault()

    this.$productsCatalog.show()
  }

  render() {
    if (this.readonly) return
    Object.keys(this.tables).forEach(key => this.renderTable(this.tables[key]))
  }

  renderTable(table) {
    this.browserBackButtonFixes(table)
    this.prepareItemTemplate(table)
  }

  setTypeprice() {
    this.data.typeprice = this.el.find('.typeprice-select').val() || 'price_supply'
  }

  // Just make sure everyting works correct.
  // We should destroy item that is not saved, and also prepare template html class
  browserBackButtonFixes(table) {
    table.body.find('.line-item.empty').last()
      .addClass(TEMPLATE_CLASS)

    table.body.find('.line-item').not(`.${TEMPLATE_CLASS}`).each((_, item) => {
      const itemId = $(item).find('.line-item-id').val()
      if (!itemId) $(item).remove()
    })
  }

  prepareItemTemplate(table) {
    if (table.template || !table.body.length) return

    table.template = new ListItemTemplate(table.body, {
      itemClass: ITEM_CLASS,
      templateClass: TEMPLATE_CLASS,
      valueClass: VALUE_CLASS,
      replaceNestedFieldsIndex: true
    })

    table.template.prepare()
  }

  bindLineItemEvents($target) {
    $target.find('.link-btn').click(this.openProductPage.bind(this))
    if (this.readonly) return

    $target.find(`.${VALUE_CLASS}`).change(this.onSelectItem.bind(this))
    $target.find('input').focus(this.handleInputFocus.bind(this))
    $target.find('select').on('select2:open', this.handleInputFocus.bind(this))
    $target.find('.delete-btn').click(this.deleteItem.bind(this))
    $target.find('.price, .quantity').change(this.onCalculate.bind(this))
    $target.find('.price, .quantity').keyup(this.onCalculate.bind(this))
    $target.find('select.modifications-select').change(this.handleModificationChange.bind(this))
    $target.find('.open-products-catalog-modal-btn').click(this.openProductsCatalog.bind(this))
  }

  onCalculate(e) {
    const $item = $(e.target).closest('.line-item')
    const materialType = $item.data('material')

    this.calculate($item, materialType)
  }

  calculate($item, materialType) {
    if (materialType === 'product') {
      const price = Number($item.find('.price').val() || 0)
      const quantity = Number($item.find('.quantity').val() || 0)

      // TODO: Implement correct rounding
      $item.find('.amount').val(round(multiply(price, quantity), 2))
    }

    const productsAmount = sum(this.tables.product.body.find('.amount').map((_, item) => Number($(item).val() || 0)))
    const expensesAmount = sum(this.tables.expense.body.find('.amount').map((_, item) => Number($(item).val() || 0)))

    const total = round(add(productsAmount, expensesAmount), 2)

    $('#manufacture-total').text(total)
  }

  openProductPage(e) {
    const productId = $(e.target).closest('.line-item').find('.material-id').val()
    window.open(`${gon.locale_path}/platform/products/${productId}`, '_blank')
  }

  deleteItem(e) {
    const $item = $(e.target).closest('.line-item')

    if ($item.hasClass('empty')) return

    const itemId = $item.find('.line-item-id').val()

    if (itemId) {
      $item.find('.destroy-field').val(1)
      $item.find('.quantity').val(0)
      $item.find('.amount').val(0)
      $item.hide()
    } else {
      $item.remove()
    }
    this.onCalculate(e)
  }

  handleInputFocus(e) {
    this.el.find('tr, .table-row').removeClass('focused')
    $(e.target).closest('tr, .table-row').addClass('focused')
  }

  async onSelectItem(e) {
    const $item = $(e.target).closest('.line-item')
    const materialType = $item.data('material')
    $item.removeClass('empty')

    let item = {}

    if (materialType === 'product') {
      const itemId = $item.find('.material-id').val()
      item = await this.fetchProduct(itemId)
    }

    this.setLineItemFields($item, item, materialType)
    this.addEmptyLineItem(this.tables[materialType])
  }

  insertLineItemFromCatalog(item, materialType) {
    const table = this.tables[materialType]
    let $item = table.body.find('.line-item').last()
    const $materialSelect = $item.find('select.material-id')

    if ($materialSelect.val()) $item = this.addEmptyLineItem(table)

    $item.removeClass('empty')
    const title = `${compact([item.sku, item.title]).join(' ')} - ${item.code}`
    const option = new Option(title, item.id, true, true)
    $materialSelect.append(option).trigger('change')
    this.setLineItemFields($item, item, materialType)
    this.addEmptyLineItem(table)
  }

  addEmptyLineItem(table) {
    const $newItem = table.template.create()
    if ($newItem) this.bindLineItemEvents($newItem)
    return $newItem
  }

  setLineItemFields($item, item, materialType, { calculate = true } = {}) {
    const $quantity = $item.find('.quantity')

    if (item.quantity || item.quantity === 0) {
      $quantity.val(item.quantity)
    } else if (Number($quantity.val()) === 0) {
      $quantity.val(1)
    }

    if (materialType === 'product') {
      $item.attr('data-product', JSON.stringify(item))
      $item.find('.apply-disassembly').prop('checked', item.renewable_component)
      $item.find('.apply-disassembly').prop('disabled', !item.renewable_component)

      this.setModifications(item, $item)

      const modification = this.fetchSelectedModification(item, $item)

      this.setProductPrice(item, $item, modification)
      this.setProductRemains(item, $item, modification)
    }

    if (calculate) this.calculate($item, materialType)
  }

  setProductRemains(product, $item, modification) {
    const item = modification || product

    $item.find('.remains-box .remain').html(formattedQuantity(item.remains, { zero: '-', pretty: true }))
    $item.find('.remains-box .reserve .value').text(item.reserve)
    $item.find('.remains-box .reserve').toggle(Number(item.reserve || 0) > 0)
  }

  handleModificationChange(e) {
    const $item = $(e.target).closest('.line-item')
    const materialType = $item.data('material')

    if (materialType === 'product') {
      const product = JSON.parse($item.attr('data-product') || '{}')
      const modification = this.fetchSelectedModification(product, $item)
      this.setProductPrice(product, $item, modification)
      this.setProductRemains(product, $item, modification)
      this.calculate($item, materialType)
    }
  }

  setProductPrice(product, $item, modification) {
    let prices = product.prices

    if (modification && modification.use_prices) {
      prices = modification.prices
    }

    const price = prices[this.data.typeprice]

    $item.find('.price').val(price)
  }

  fetchSelectedModification(product, $item) {
    const $modificationSelect = $item.find('select.modifications-select')

    return product.modifications.find(item => item.id === $modificationSelect.val())
  }

  setModifications(product, $item) {
    const $modificationSelect = $item.find('select.modifications-select')

    if (!product.barcode_accounting || !$modificationSelect.length) {
      $modificationSelect.prop('disabled', true)
      return
    }

    $modificationSelect.prop('disabled', false)

    let selected = product.modifications[0]
    if (product.modificationId) {
      selected = product.modifications.find(m => m.id === product.modificationId)
    }

    addSelectOptions($modificationSelect, product.modifications, {
      label: 'to_s',
      value: 'id',
      clear: true,
      selected,
      allowBlank: true
    })
  }

  async fetchProduct(productId) {
    const params = {
      document_id: this.data.document_id,
      stock_id: this.data.stock_from_id,
      typeprice: this.data.typeprice,
      remains: true
    }
    const response = await axios.get(`${gon.locale_path}/platform/products/${productId}.json`, { params })

    return response.data
  }

  // Triggered from Catalog.js after user submit products selection
  applyCatalogSelection = (selectedProducts = {}) => {
    Object.keys(selectedProducts).forEach(productId => {
      const product = selectedProducts[productId]
      this.insertLineItemFromCatalog(product, 'product')
    })
  }
}

export default ManufactureWidget

import BaseWidget from './base_widget'

class AttachmentsWidget extends BaseWidget {
  bindEvents() {
    this.el.find('.upload-attachment-btn').click(this.onUploadClick.bind(this))
  }

  onUploadClick(e) {
    e.preventDefault()
    this.el.find('.upload-input').click()
  }
}

export default AttachmentsWidget

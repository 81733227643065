import { capitalize, delay, round, isNumber } from 'lodash'
import { camelizeKeys } from 'services/objects'
import Discount from 'services/discount'
import { fetchNumberInputValue, discountedAmount, add, subtract, multiply, divide } from 'services/numbers'
import { isTriggeredByElementWithClass } from 'services/events'

class LineItemCalculator {

  INPUTS = '.price, .quantity, .quantity-measure, .discount-field, .amount-expenses, .markup-retail, .markup-wholesale, .nds-rate, .price-netto'

  constructor(lineItem) {
    this.lineItem = lineItem
    this.el = lineItem.el
    this.doc = lineItem.doc
    this.$inputs = this.el.find(this.INPUTS)
    this.$discountField = this.el.find('.discount-field')
    // NOTE: Better to use lazyCalculate, but I don't like that calculation visually delayed
    // this.lazyCalculate = debounce(this.calculate.bind(this), 100)
    this.bindEvents()
  }

  bindEvents() {
    this.el.find('.discount-info-btn').click(this.openDiscountModal)

    if (this.doc.isReadonly()) return

    this.$inputs.on('keydown', this.saveCurrentKeydown)
    this.$inputs.on('keyup change', (event) => this.calculate({event}))
  }

  calculate = ({ event = null, recalculatePrices = false,
    recalculateDiscounts = false, recalculateCurrency = false, calculateDoc = true, save = true } = {}) => {

    if (this.shouldSkipKeyupCalculation(event)) return

    if (this.lineItem.blockCalculate || this.doc.isReadonly() || !this.lineItem.productId) return

    if (recalculatePrices) {
      // Force recalculate currency, because after resetPrices all prices will be changed
      recalculateCurrency = true
      this.resetPrices()
    }

    const quantity = this.calculateQuantity()
    this.lineItem.validateQuantity()

    this.doc.PRICE_TYPES.forEach(typeprice => {
      this.performPriceCalculation(event, typeprice, quantity, { recalculateDiscounts, recalculateCurrency })
    })

    // EDGE CASE: Discounts calculated after currency convertion. But! During discount calculation
    // price could be updated with special price, which is in accounting currency.
    // Thats why, when discounts allowed and multicurrency mode we should recalculate document price again,
    // in order to convert special price.
    if (this.doc.data.allow_discounts && this.doc.isMulticurrency()) {
      this.performPriceCalculation(event, 'document', quantity, { recalculateDiscounts, recalculateCurrency })
    }

    this.calculateBalancePriceFields(event, quantity)
    this.calculateNds(event, quantity)
    this.saveMetadataFields(event)

    if (calculateDoc) this.doc.calculate()
    if (save) this.lineItem.lazySave()
  }

  saveCurrentKeydown = (event) => {
    const $input = $(event.currentTarget)
    this.currentKeydown = { id: $input.attr('id'), value: $input.val() }
  }

  // Edge case. When keyup event fires, we shouldn't perform calculation if input wasn't changed.
  // If we do than it will lead to useless recalculation which will cause wierd cases.
  // For example, in sales doc with multicurrency if you just change price accounting and
  // press enter it will do following:
  // 1. Document price will be calculated because of 'change' event.
  // 2. Cursor will be moved to document price.
  // 3. Bacause of Enter's 'keyup' event, new calculation will be fired (document price change).
  // 4. Just edited accounting price will be calculated based on document price.
  // As a result you got recalculated just edited price, and results will be wierd, because
  // calculated price could be different than manually entered price (decimals).
  shouldSkipKeyupCalculation(event) {
    if (!event || event.type !== 'keyup' || !(this.currentKeydown || {}).id) return false

    const $input = $(event.currentTarget)

    if (this.currentKeydown.id === $input.attr('id')) {
      return this.currentKeydown.value === $input.val()
    } else {
      return true
    }
  }

  calculateQuantity() {
    if (this.lineItem.useTwoMeasures()) {
      const quantityBase = fetchNumberInputValue(this.el.find('.quantity-measure-base'))
      const quantitySecond = fetchNumberInputValue(this.el.find('.quantity-measure-second'))
      const measureRate = fetchNumberInputValue(this.el.find('.measure-rate')) || 1
      const quantity = round(add(quantityBase, divide(quantitySecond, measureRate)), 7)

      this.el.find('.quantity').val(quantity)
      this.el.find('.admin-raw-quantity').text(quantity)

      return quantity
    } else {
      const quantity = fetchNumberInputValue(this.el.find('.quantity'))

      this.el.find('.quantity-measure-base').val(quantity)
      this.el.find('.quantity-measure-second').val(0)

      return quantity
    }
  }

  openDiscountModal = (e) => {
    e.preventDefault()
    if (!this.lineItem.product) return
    const metadata = this.lineItem.fetchMetadata()
    this.setDiscountInfo(metadata)
    this.doc.openLineItemDiscountModal(this.lineItem)
  }

  // Line item prices should be reseted to product prices in case of
  // prices recalculation (contragent or typeprice changed), and
  // on initial line item insert.
  // NOTE: It's very important to recalculate currency after calling this method
  resetPrices() {
    const prices = this.lineItem.prices()
    const priceDocument = this.fetchProductPriceDocument()
    let priceSupply = prices.price_supply

    // For built in prices (supply, retail, etc) we set document price from product card and calculate
    // price_supply based on rate (here just set to 0).
    // But when custom price selected as typeprice we use it as price_supply.
    // This allows user to define supply prices in product card.
    if (this.doc.data.is_supply_contragent) {
      priceSupply = this.doc.data.is_custom_price ? priceDocument : 0
    }

    this.el.find('.price.price-accounting').val(priceDocument)
    this.el.find('.admin-price-accounting').text(priceDocument)
    this.el.find('.price.price-document').val(priceDocument)
    this.el.find('.price.price-supply').val(priceSupply)
    this.el.find('.price.price-retail').val(prices.price_retail)
    this.el.find('.price.price-wholesale').val(prices.price_wholesale)

    if (!this.doc.isManufacture()) return

    // Price set from product card, but for manufacture we don't know price before activation,
    // so just reset it to 0
    this.el.find('.price-document').val(0)

    const modification = this.lineItem.modifications.selected()
    let manufactureCalculatedPrice = this.lineItem.product.manufacture_calculated_price || 0

    if (modification && modification.manufacture_template_id) {
      manufactureCalculatedPrice = modification.manufacture_calculated_price || 0
    }

    this.el.find('.manufacture-calculated-price').text(manufactureCalculatedPrice)
  }

  // private

  // Important: here price may change only itself, but it shouldn't change another price.
  // For example, when accounting price performs calculation than only accounting could be updated,
  // and not document price, retail, etc.
  performPriceCalculation(event, typeprice, quantity, { recalculateDiscounts = false, recalculateCurrency = false } = {}) {
    const $price = this.el.find(`.price-${typeprice}`)
    if (!$price.length) return

    let price = fetchNumberInputValue($price)

    // Applicable only for receipt and only for retail and wholesale prices
    price = this.calculateSupplyMarkup(event, typeprice, price)
    // Applicable only for document price and documents with payments (receipt, sales, etc)
    // Also assign accounting price equal to document price when no convertion
    price = this.convertCurrency(event, typeprice, price, recalculateCurrency)
    // Discounts calculated only for accounting price and only for sales docs,
    // should be after currency convertion, because accounting price set in scope of it.
    price = this.calculateDiscounts({event, typeprice, quantity, $price, price, recalculateDiscounts})
    price = this.roundPrices(event, typeprice, price)

    // Handle case when user try to enter 0.01, 0.001, 2.05, etc.
    // If price is same, than don't modify field value,
    // otherwise user won't be able to enter decimal prices.
    if (price !== fetchNumberInputValue($price)) $price.val(price)
    // Just for superadmin, show accounting price for debugging
    if (typeprice === 'accounting') this.el.find('.admin-price-accounting').text(price)

    // It's just to notify user, we don't restrict anything here
    this.validateMinimalPrice($price, price, typeprice)

    // At the end calculate amount related for the price.
    // Even if price remains same you should calculate amount anyway.
    this.setFinalAmount(typeprice, quantity, price)
  }

  convertCurrency(event, typeprice, price, recalculateCurrency) {
    if (this.isPriceChangedManually(event, typeprice)) return price

    if (this.doc.isMulticurrency()) {
      return this.lineItem.currency.convert(event, typeprice, price, recalculateCurrency)
    } else if (typeprice === 'accounting') {
      return this.fetchPriceDocument()
    } else if (this.doc.isSupplyContragent() && this.doc.data.supplier_price_recalculation) {
      // Using function from currency convert, because it does exactly what needed,
      // when document price should be recalculated based on expenses and price supply.
      // TODO: Extract this function from currency as it's universal.
      return this.lineItem.currency.convertReceipt(event, typeprice, price, recalculateCurrency)
    } else if (typeprice === 'supply' && this.doc.isSupplyAll()) {
      // For supply documents in non-multicurrency mode price supply
      // should be equal to document price.
      return this.fetchPriceDocument()
    } else {
      return price
    }
  }

  setFinalAmount(typeprice, quantity, price) {
    const $amount = this.el.find(`.amount-${typeprice}`)
    // Fetch price again, because it may change after discount calculation, currency convertion, and etc.
    const rawAmount = multiply(quantity, price)
    let prc = 0

    if (['accounting', 'document'].includes(typeprice)) prc = fetchNumberInputValue(this.$discountField)

    // Almost same amount calculation done in discount.js, but these two amounts could be different.
    // In discounts we need amount to calculated bonuses, and calculation in accounting currency.
    // Here amount calculated after currency convertion.
    const amount = discountedAmount(rawAmount, prc, this.priceRounding(typeprice))

    // Just for superadmin, show accounting price for debugging
    if (typeprice === 'accounting') this.el.find('.admin-amount-accounting').text(amount)

    $amount.val(amount)
  }


  calculateSupplyMarkup(event, typeprice, price) {
    const $markup = this.el.find(`.markup-${typeprice}`)

    // Markup disabled in settings
    if (!$markup.length) return price

    const priceDocument = this.fetchPriceDocument()

    let markup

    // User just changed this price right now, so, update markup instead.
    if (this.isPriceChangedManually(event, typeprice)) {
      const markupAmount = subtract(price, priceDocument)
      markup = priceDocument ? round(multiply(divide(markupAmount, priceDocument), 100), 2) : 0
      markup = markup <= 0 ? null : markup
      $markup.val(markup)

      const metadata = this.lineItem.fetchMetadata()
      metadata[`markup${capitalize(typeprice)}`] = markup
      this.lineItem.setMetadata(metadata)

      return price
    }

    // Markup applicable only for receipt doc,
    // basically we add some percent on document price to calculated retail and wholesale prices
    if (!this.doc.isSupplyContragent() || !['retail', 'wholesale'].includes(typeprice)) return price

    markup = $markup.val()

    // If field empty, then product hasn't any markup
    if (!markup) return price

    markup = Number(markup)

    return round(add(priceDocument, divide(multiply(priceDocument, markup), 100)), 4)
  }

  roundPrices(event, typeprice, price) {
    return round(price, this.priceRounding(typeprice))
  }

  priceRounding(typeprice = null) {
    if (['retail', 'wholesale'].includes(typeprice)) {
      return this.doc.moneyRounding({forceSales: true})
    } else {
      return this.doc.moneyRounding()
    }
  }

  calculateBalancePriceFields(event, quantity) {
    if (this.doc.typedoc !== 'correction_balance') return

    const partyQuantity = Number(this.el.find('.correction-balance-party-quantity').text() || 0)

    let diffExcess = 0
    if (subtract(quantity, partyQuantity) > 0.00) diffExcess = subtract(quantity, partyQuantity)

    let diffShortage = 0
    if (subtract(quantity, partyQuantity) < 0.00) diffShortage = subtract(partyQuantity, quantity)

    this.el.find('.correction-balance-diff-excess').text(diffExcess)
    this.el.find('.correction-balance-diff-shortage').text(diffShortage)
  }

  calculateNds(event, quantity) {
    const $rate = this.el.find('.nds-rate')
    let priceNetto = this.fetchPriceAccounting()
    let amountNetto = fetchNumberInputValue(this.el.find('.amount-accounting'))
    let amountNds = 0

    if (this.doc.data.nds_enabled && $rate.length) {
      const prc = fetchNumberInputValue(this.$discountField)
      const rounding = this.priceRounding()
      const rate = fetchNumberInputValue($rate)
      const priceDocument = this.fetchPriceDocument()
      const amountDocument = fetchNumberInputValue(this.el.find('.amount-document'))
      const priceNds = multiply(divide(priceDocument, add(100, rate)), rate)
      priceNetto = round(subtract(priceDocument, priceNds), rounding)

      const rawAmountNetto = multiply(quantity, priceNetto)

      amountNetto = discountedAmount(rawAmountNetto, prc, rounding)
      amountNds = round(subtract(amountDocument, amountNetto), rounding)
    }

    if (!this.isPriceChangedManually(event, 'netto')) {
      this.el.find('.price-netto').val(priceNetto)
    }

    this.el.find('.amount-netto').val(amountNetto)
    this.el.find('.amount-nds').val(amountNds)
  }

  // Save markups to metadata because it inside metadata in DB.
  // And we can't update as separeate param to avoid conflicts.
  saveMetadataFields(event) {
    const metadata = this.lineItem.fetchMetadata()

    if (isTriggeredByElementWithClass(event, 'markup-retail')) {
      metadata.markupRetail = this.el.find('.markup-retail').val()
    }

    if (isTriggeredByElementWithClass(event, 'markup-wholesale')) {
      metadata.markupWholesale = this.el.find('.markup-wholesale').val()
    }

    this.lineItem.setMetadata(metadata)
  }

  fetchPriceDocument() {
    return fetchNumberInputValue(this.el.find('.price.price-document'))
  }

  fetchPriceAccounting() {
    return fetchNumberInputValue(this.el.find('.price.price-accounting'))
  }

  fetchProductPriceDocument() {
    return this.lineItem.prices()[this.doc.data.typeprice] || 0
  }

  calculateDiscounts({event, quantity, typeprice, $price, price, recalculateDiscounts} = {}) {
    if (!this.shouldCalculateDiscountsForPrice(typeprice)) return price

    const discount = this.initDiscountService(event, quantity, price).calculate(recalculateDiscounts)
    const { discountPercent, manualDiscountPercent, manualSpecialPrice, discountPrice, accruedBonus } = discount

    if (manualDiscountPercent === undefined) this.$discountField.val(discountPercent)
    if (manualSpecialPrice === undefined && isNumber(discountPrice)) price = discountPrice

    this.toggleBonuses(accruedBonus)
    this.setDiscountInfo(discount)
    this.lineItem.setMetadata({...this.lineItem.fetchMetadata(), ...discount})

    return price
  }

  initDiscountService(event, quantity, price) {
    return new Discount(camelizeKeys({
      discounts: this.doc.discounts,
      doc: this.doc.data,
      contragent: this.doc.contragent,
      settings: this.doc.companySettings,
      previousCalculation: this.previousDiscountData(),
      moneyRounding: this.doc.moneyRounding(),
      manualDiscountPercent: this.fetchManualDiscountPercent(event),
      manualSpecialPrice: this.fetchManualSpecialPrice(event),
      li: {
        quantity,
        price,
        product: camelizeKeys(this.lineItem.product),
        prices: this.lineItem.prices()
      }
    }))
  }

  // In case if user changed discount percent manually it will have more priority
  // than calculated percent.
  // But we still need to do discount calculation to update accrued bonuses and use special price
  fetchManualDiscountPercent(event) {
    const metadata = this.lineItem.fetchMetadata()
    let { manualDiscountPercent } = metadata

    if (isTriggeredByElementWithClass(event, 'discount-field')) {
      manualDiscountPercent = fetchNumberInputValue(this.$discountField)
      this.lineItem.setMetadata({...metadata, manualDiscountPercent})
    }

    if (manualDiscountPercent || manualDiscountPercent === 0) return manualDiscountPercent

    return undefined
  }

  // In case if user changed accounting or document (for non-multicurrency mode)  price manually
  // it will have more priority than calculated special price.
  fetchManualSpecialPrice(event) {
    const metadata = this.lineItem.fetchMetadata()
    let { manualSpecialPrice } = metadata

    if (this.isPriceChangedManually(event, 'accounting') ||
      (this.isPriceChangedManually(event, 'document') && !this.doc.isMulticurrency())) {

        manualSpecialPrice = fetchNumberInputValue($(event.currentTarget))
        this.lineItem.setMetadata({...metadata, manualSpecialPrice})
    }

    return manualSpecialPrice || undefined
  }

  // After discount calculation, we save all values to metadata field in order to:
  // - get all data for recalculation when quantity or price changed
  // - display applied discounts on line item
  // Saved data will be used each time instead of full calculation, except cases:
  // - user requested full recalculation
  // - contragent changed
  // - document typeprice changed
  previousDiscountData() {
    const metadata = this.lineItem.fetchMetadata()

    // If discount was already calculated or set manually then do nothing and return previousCalculation
    if (metadata.discountCalculated || metadata.discountPercent) return metadata

    return false
  }

  setDiscountInfo(discount) {
    const $modal = this.doc.$lineItemDiscountModal
    const { discountDisabled, accruedBonus, bonusEntryRatio, appliedDiscounts = [] } = discount

    $modal.find('.applied-discounts').empty()
    $modal.find('.empty').hide()
    $modal.find('.discount-disabled').hide()
    $modal.find('.discount-product').text(`${this.lineItem.product.title} - ${this.lineItem.product.code}`)
    this.el.find('.discount-info-btn').toggleClass('with-discounts', !!appliedDiscounts.length)

    if (!appliedDiscounts.length) {
      discountDisabled ? $modal.find('.discount-disabled').show() : $modal.find('.empty').show()
      return
    }

    const discountsHTML = appliedDiscounts.map(discount => {
    let indicator = `${round(discount.discountPercent, 4)}%`
    let value

      if (discount.kind === 'bonus') {
        indicator = `(${I18n.t('discounts.entry_ratio').toLowerCase()} - ${round(bonusEntryRatio, 1)})`
        value = `${round(accruedBonus, this.doc.moneyRounding())} ${I18n.t('discounts.points')}`
      }

      if (discount.kind === 'special') {
        indicator = `${round(discount.discountPrice, this.doc.moneyRounding())}`
      }

      return `<li>
        <span class='discount-name'>${discount.name}</span>
        <span class='discount-percent'>${indicator}</span>
        ${value ? `<span class='discount-amount'>${value}</span>` : ''}
      </li>`
    })

    $modal.find('.applied-discounts').html(discountsHTML)
  }

  validateMinimalPrice($price, price, typeprice) {
    if (!this.doc.data.is_sale_all) return
    if (!this.isAccountingOrDocumentNonMulticurrencyPrice(typeprice)) return

    const priceMin = Number(this.lineItem.prices().price_min || 0)

    if (price < priceMin) {
      $price.addClass('with-error')
      $price.tooltip({ placement: 'top' })
      $price.data('bs.tooltip').options.title = I18n.t('document_items.price_min_alert',
        { price: round(price, this.doc.moneyRounding()), price_min: round(priceMin, this.doc.moneyRounding()) }
      )
      $price.tooltip('show')
      delay(() => $price.tooltip('hide'), 20000)
    } else if ($price.hasClass('with-error')) {
      $price.removeClass('with-error')
      $price.data('bs.tooltip') && $price.tooltip('destroy')
    }
  }

  toggleBonuses(accruedBonus) {
    const sign = this.doc.data.is_return ? '-' : ''
    this.el.find('.accrued-bonus .value').text(`${sign}${round(accruedBonus, this.doc.moneyRounding())}`)
    this.el.find('.accrued-bonus').toggle(!!accruedBonus)
  }

  isPriceChangedManually(event, typeprice) {
    return isTriggeredByElementWithClass(event, `price-${typeprice}`)
  }

  isAccountingOrDocumentNonMulticurrencyPrice(typeprice) {
    return (typeprice === 'accounting' || (typeprice === 'document' && !this.doc.isMulticurrency()))
  }

  shouldCalculateDiscountsForPrice(typeprice) {
    return this.doc.data.allow_discounts && this.isAccountingOrDocumentNonMulticurrencyPrice(typeprice)
  }
}

export default LineItemCalculator

// Widget to handle interactions with features fields in
// modification views and modification modal in document, and in product card.
//
// Depends on data-featureable-id and data-featureable-type attributes and
// fetch from server all features and selected featureable feature items on each reload.
//
// Loads selected features that saved in feature_items for current featureable record (modification or product).
// Then handles creation, deletion and configuring new characterisctics (features).
// At the end, regular rails form able to submit and send data to server.
//
// Features represented as following object:
// {
//   parents: {
//     [id]: { id: 1, title: 'Size', datatype: 'select',  children: {} }, ... // here children is same as root children, but only for this parent
//   },
//   children: {
//     [id]: { id: 2, title: 'XXL' }, ...
//   }
// }
//
// Depending on feature parent datatype display different value field for child feature. If datatype is 'select', then
// shows select box. For all other types shows input field and dynamically changes it's type attirubute. This input field
// also exsits for 'select' datatype, which update input value after select changes. So, select box it's just a way
// to update 'feature-input', which will be submitted in the form.
//
// Widget has custom event 'reload-features' on this.el, which can be triggered outside (for now in document on opening modal).
// Call $(widget-element).trigger('reload-features') to reload features fields.

import BaseWidget from './base_widget'
import { compact, groupBy, pickBy } from 'lodash'
import Select from 'main/widgets/select_widget'
import shortid from 'shortid'
import axios from 'axios'
import { isAxiosNetworkError, showAlert } from 'services/errors'

// On browser back button it behaives like quants! When you open console to track it works correct,
// when you don't track it works unpredictable!

class FeaturesWidget extends BaseWidget {
  initialize() {
    this.readonly = this.el.data('readonly')
    this.featureableId = this.el.data('featureable-id')
    this.featureableType = this.el.data('featureable-type')
  }

  render() {
    this.reload()
  }

  // We should reload widget with cleanUp because document use one widget in modal for all line items,
  // when opening modal document replace data-product-id for this.el, and trigger event 'reload-modification'
  async reload() {
    this.unmount()

    if (!this.featureableType) return

    const data = await this.fetchData()
    this.allFeatures = data.all_features || {}
    this.selectedFeatures = data.selected_features
    this.selects = {}
    this.resetFields()
    this.createInitialSelects()
  }

  // It's important to cleanUp because of Turbolinks cache and becase of using widget in document in modal
  unmount() {
    this.el.find('.features').empty()
  }

  async fetchData(callback) {
    try {
      $('.features-alert').hide()
      const params = { featureable_id: this.featureableId, featureable_type: this.featureableType}
      const response = await axios.get(`${gon.locale_path}/platform/settings/features/widget`, { params })
      return response.data || {}
    } catch (e) {
      if (isAxiosNetworkError(e)) $('.features-alert').html(window.I18n.t('errors.network_error')).show()
      console.error(e)
      return {}
    }
  }

  bindEvents() {
    if (this.readonly) return
    this.el.find('#add-feature-btn').click(this.onAddFeature.bind(this))
    this.el.on('click', '.remove-feature-btn', this.onRemoveFeature.bind(this))
    this.el.bind('reload-features', this.reload.bind(this))
  }

  resetFields() {
    if (!this.selectedFeatures) return
    this.selectedFeatures.forEach(featureItem => this.renderFeatureBox(featureItem))
  }

  onAddFeature(e) {
    e.preventDefault()
    if (!this.validateUserErrors()) return
    this.renderFeatureBox()
  }

  onRemoveFeature(e) {
    e.preventDefault()
    $(e.target).closest('.feature-group').remove()
    this.validateUserErrors()
  }

  createInitialSelects() {
    if (Object.keys(this.selects).length) return
    this.renderFeatureBox()
  }

  // Renders feature group of two select box:
  // 1. For parent feature, like Color or Size
  // 2. For child feature connected with selected parent, like S, M, L for Size parent
  // Each rendered select data stores in this.selects object in order to update options of select when parent changed.
  // For feature with datatype != 'select' renders input specific to datatype.
  renderFeatureBox(featureItem) {
    const $feature = $(this.featureTemplate())
    this.el.find('.features').append($feature)

    const guid = shortid.generate()
    const parent = {}
    const child = {}

    parent.$select = $feature.find('.feature-parent-select')
    child.$select = $feature.find('.feature-select')
    child.$input = $feature.find('.feature-input')

    parent.$select.data('guid', guid)
    child.$select.data('guid', guid)

    parent.select = new Select(parent.$select)
    child.select = new Select(child.$select)

    parent.feature = this.fetchParentFeatureByChild(featureItem)

    this.selects[guid] = { $parent: parent.$select, parent: parent.select,
      child: child.select, $child: child.$select, $input: child.$input }

    this.addFeatureOptions(parent.select, this.parentFeatureOptions(), parent.feature)
    this.resetChildFeature(parent.feature, this.selects[guid], featureItem)

    this.bindParentFeatureEvents(parent.$select)
    this.bindChildFeatureEvents(child.$select)

    return this.selects[guid]
  }

  resetChildFeature(parent, select, selectedFeature) {
    const $childSelectBox = select.$child.closest('.feature-select-box')
    let value

    if (parent && parent.datatype === 'select') {
      const featureId = selectedFeature ? selectedFeature.feature_id : null
      const selected = this.allFeatures.children[featureId]
      this.addFeatureOptions(select.child, this.childFeatureOptions(parent), selected)
      $childSelectBox.show()
      select.$input.val(featureId)
      value = featureId
    } else {
      this.addFeatureOptions(select.child, [])
      $childSelectBox.hide()
      value = selectedFeature ? selectedFeature.value : null
    }

    this.modifyFeatureInput(parent, select.$input, value)
  }

  // Feature could be in different datatypes,
  // here we change html input type to display correct field.
  modifyFeatureInput(parent, $input, value) {
    $input.removeAttr('step')

    switch (parent ? parent.datatype : null) {
      case 'select':
        $input.prop('type', 'text')
        $input.hide()
        break
      case 'integer':
        $input.prop('type', 'number')
        $input.prop('step', 'any')
        $input.show()
        break
      case 'date':
        $input.prop('type', 'date')
        $input.show()
        break
      default:
        $input.prop('type', 'text')
        $input.show()
    }

    $input.val(value)
  }

  // Generally just show warning if user did something strange
  validateUserErrors() {
    const $alert = $('.features-alert')
    $alert.hide()
    const selectedIds = compact(Array.from($('.feature-parent-select')).map(el => $(el).val()))
    const duplicateIds = Object.keys(pickBy(groupBy(selectedIds), x => x.length > 1))

    if (duplicateIds.length) {
      $alert.html(window.I18n.t(`features.duplicate_${this.featureableType.toLowerCase()}_feature`))
      $alert.show()
      return false
    }
    return true
  }

  // When user select parent feature like Color or Size, change all options of child select
  onParentFeatureSelect(e) {
    // On Network Error allFeatures will be empty
    if (!this.allFeatures.parents) return

    const select = this.selects[$(e.target).data('guid')]
    const selectedId = $(e.target).val()
    const parent = this.allFeatures.parents[selectedId]

    this.validateUserErrors()
    this.resetChildFeature(parent, select)
  }

  bindParentFeatureEvents(select) {
    select.change(this.onParentFeatureSelect.bind(this))
  }

  bindChildFeatureEvents(select) {
    select.change(this.onChildFeatureSelect.bind(this))
  }

  fetchParentFeatureByChild(featureItem) {
    if (!featureItem) return null

    // If no parent, then this feature is a parent, and it's datatype !== 'select'
    // In FeatureItem model we save value connected to parent itself, and not to child feature.
    if (!featureItem.parent_id) return this.allFeatures.parents[featureItem.feature_id]

    const child = this.allFeatures.children[featureItem.feature_id]
    if (!child) return null
    return this.allFeatures.parents[child.parent_id]
  }

  parentFeatureOptions() {
    return Object.values(this.allFeatures.parents || {})
  }

  childFeatureOptions(parent) {
    return parent ? Object.values(parent.children || {}) : []
  }

  onChildFeatureSelect(e) {
    const $select = $(e.target)
    const $input = $select.closest('.feature-group').find('.feature-input')

    $input.val($select.val())
  }

  addFeatureOptions(select, options, selected) {
    select.addOptions(options, { label: 'title', value: 'id', clear: true, selected, allowBlank: true })
  }

  featureTemplate() {
    const name = (field) => `${this.featureableType.toLowerCase()}[features][][${field}]`

    // To allow create parent feature just add data-tags=true to feature-parent-select
    // For now, parent may be created only via settings
    return `
    <div class='feature-group'>
      <div class='group-item'>
        <select ${this.readonly ? 'disabled' : ''} class='feature-parent-select' name='${name('parent')}' data-search=true>
        </select>
      </div>
      <div class='group-item'>
        <div class='feature-select-box'>
          <select ${this.readonly ? 'disabled' : ''} class='feature-select' data-tags=true>
          </select>
        </div>
        <input ${this.readonly ? 'disabled' : ''} type='text' class='feature-input form-control' name='${name('child')}' />
        ${this.readonly ? '' : "<button class='btn btn-sm btn-danger remove-feature-btn' type='button'><i class='fas fa-times' /></button>"}
      </div>
    </div>
    `
  }
}

export default FeaturesWidget

import printJS from 'print-js'
import bootbox from 'bootbox'
import axios from 'axios'

export default class Print {
  constructor(client) {
    this.client = client
  }

  call = async (e) => {
    this.client.beforePrint()

    if (this.client.skipPrint()) return

    e.preventDefault()

    this.$modal = bootbox.dialog({
      title: window.I18n.t('print.loader_modal.title'),
      message: `<div>
        <div class='loader blue'></div>
        <p>${window.I18n.t('print.loader_modal.text')}</p>
      <div>`,
      onEscape: false,
      backdrop: 'static',
      animate: true,
      className: 'modal-loader'
    })

    const response = await axios.get(this.client.url(), { params: this.client.params() })
    const data = response.data
    const size = data.page_size ? data.page_size : `${data.page_width}mm ${data.page_height}mm`

    this.$modal.modal('hide')

    printJS({
      type: 'raw-html',
      printable: data.inline,
      // showModal: true,
      modalMessage: window.I18n.t('print.loader_modal.text'),
      css: '/styles/pdf.css',
      onError: () => {
        // $('#printJS-Modal').remove()
      },
      style: `
        @page {
          size: ${size};
          overflow: hidden;
          margin-top: ${data.margin.top}mm !important;
          margin-bottom: ${data.margin.bottom}mm !important;
          margin-left: ${data.margin.left}mm !important;
          margin-right: ${data.margin.right}mm !important;
        }

        body {
          margin: 0 !important;
        }
      `
    })
  }
}

import BaseWidget from './base_widget'

class QuantityToggleWidget extends BaseWidget {

  initialize() {
    this.$input = this.el.find('input')
    this.min = Number(this.data.min || 0)
    this.max = this.data.max ? Number(this.data.max) : null
  }

  bindEvents() {
    this.el.find('.plus').click(this.increment.bind(this))
    this.el.find('.minus').click(this.decrement.bind(this))
  }

  increment(e) {
    e.preventDefault()

    let value = Number(this.$input.val() || 0) + 1
    if (this.max && this.max < value) value = this.max
    this.$input.val(value)
    this.$input.trigger('change')
  }

  decrement(e) {
    e.preventDefault()

    let value = Number(this.$input.val() || 0) - 1
    if (this.min > value) value = this.min
    this.$input.val(value)
    this.$input.trigger('change')
  }
}

export default QuantityToggleWidget

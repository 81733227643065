import BaseWidget from './base_widget'
import Print from 'services/print'

class PrintModalWidget extends BaseWidget {
  initialize() {
    this.print = new Print(this)
  }

  bindEvents() {
    this.el.find('form').submit(this.print.call.bind(this))
  }

  skipPrint() {
    return !this.printData().front
  }

  beforePrint() {
    this.el.modal('hide')
  }

  url() {
    const documentId = this.el.data('settings').document_id
    return `${gon.locale_path}/platform/documents/${documentId}/print.json`
  }

  params() {
    const only_with_remains = this.el.find('.only-with-remains').is(':checked')
    return { ...this.printData(), only_with_remains }
  }

  printData() {
    return JSON.parse(this.el.find('.print-data-select').val())
  }
}

export default PrintModalWidget

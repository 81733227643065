import shortid from 'shortid'
import { connectWidgets } from 'main/widgets/helpers'

class Page {

  constructor(el) {
    this.el = el
    this.$sections = this.el.find('.sections')
    this.$sectionsRU = this.el.find('#ru-content .sections')
    this.$sectionsUA = this.el.find('#ua-content .sections')
    this.$templatesRU = this.el.find('#ru-templates')
    this.$templatesUA = this.el.find('#ua-templates')
    this.connectEditor(this.$sections)
    this.bindEvents()
  }

  bindEvents() {
    this.el.find('.add-section').click(this.onAddSection)
    this.el.find('.destroy-section-btn').click(this.onDestroySection)
    this.el.find('.up-section-btn').click(this.handleMoveSectionUp)
    this.el.find('.down-section-btn').click(this.handleMoveSectionDown)
  }

  onDestroySection = (e) => {
    e.preventDefault()
    this.connectedSections($(e.target)).forEach(el => el.remove())
  }

  handleMoveSectionUp = (e) => {
    e.preventDefault()
    this.connectedSections($(e.target)).forEach(el => {
      el.prev().insertAfter(el)
    })
  }

  handleMoveSectionDown = (e) => {
    e.preventDefault()
    this.connectedSections($(e.target)).forEach(el => {
      el.next().insertBefore(el)
    })
  }

  onAddSection = (e) => {
    e.preventDefault()
    const name = $(e.target).data('section')
    const sectionId = shortid.generate()
    const $sectionRU = this.createSection(this.$templatesRU, name, sectionId)
    const $sectionUA = this.createSection(this.$templatesUA, name, sectionId)
    this.$sectionsRU.append($sectionRU)
    this.$sectionsUA.append($sectionUA)
  }

  createSection($templates, name, sectionId) {
    const $section = $templates.find(`.section-${name}`).clone(true)
    $section.attr('data-section-id', sectionId)
    this.connectEditor($section)
    return $section
  }

  connectEditor($section) {
    $section.find('.editor').each((_, el) => {
      $(el).attr('data-widget', 'Editor')
    })
    connectWidgets($section)
  }

  connectedSections($section) {
    const id = $section.closest('section').data('section-id')
    const list = []
    $(`section[data-section-id="${id}"]`).each((_, el) => list.push($(el)))
    return list
  }
}

export default Page

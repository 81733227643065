export function connectWidgets($selector) {
  if (!$selector) return

  $selector.find('[data-widget]').each(function() {
    connectWidgetToElement(this)
  })
  connectWidgetToElement($selector[0])
}

function connectWidgetToElement(element) {
  if (!element) return

  if (window.widgets[element.dataset.widget]) {
    new window.widgets[element.dataset.widget](element)
  }
}

export function destroyWidgets($selector) {
  $.map($selector.find('[data-widget]:not([data-widget=""])'), (widget) => {
    widget.widgetInstance && widget.widgetInstance.unmount()
  })
}

import Rails from '@rails/ujs'
import { textInsideBrackets } from 'services/regex'

export function formToObject($form, nestedKey = null) {
  return $form.serializeArray().reduce((acc, item) => {
    if (item.name.includes(nestedKey)) {
      acc[nestedKey][textInsideBrackets(item.name)] = item.value
    } else {
      acc[item.name] = item.value
    }
    return acc
  }, {[nestedKey]: {}})
}

export function submitForm($form) {
  if ($form.data('remote')) {
    return Rails.fire($form[0], 'submit')
  } else {
    return $form.submit()
  }
}

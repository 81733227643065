// Usage: add data attribute to select control data-widget='Select'
// You may customize select with following additional data attributes:
// - create: Add create button, with ability to create options.
//   Pass true or string (for button name).
//   To make it work pass modal option and return correct json response in controller action.
//   Without modal just add 'create option' without any logic.
// - modal: Uniq modal selector for create option. Uniq modal for this select should be presented on page.
// - label: Name for label property, default 'title' || 'name' || 'label'
// - value: Name for value property, default 'id'
// - search: Set true to enable search
// - custom_create: boolean, if true then won't do anything on submit create form, use it if you need implement
// specific logic on submit, like passing additional props or do some stuff after create, like in LineItem or ImportExportWidget
// - styles: object, represents set of styling attributes for options, like { id: { color: '...', ...} }
// - multiple: if you want multiple select
// - max_items: if you want limit items in multiple select

import BaseWidget from './base_widget'
import { CREATE_OPTION, onSubmitCreateOption } from 'services/select'
import { addSelectOptions } from 'services/select'
import shortid from 'shortid'

class Select extends BaseWidget {
  initialize() {
    this.$modal = $(`${this.data.modal}`)
    this.select = this.el.select2(this.options())
    this.$parent = this.data.parent && $(`${this.data.parent.selector}`)
    this.ajaxParams = this.el.data('ajax-params') || {}
  }

  render() {
    if (this.data.create) this.addCreateFeature()
    if (this.$parent) this.onParentChange(null, { clear: false })
    // this.toggleTooltip()
  }

  bindEvents() {
    // https://github.com/select2/select2/issues/4698
    if (this.data.closeOnSelect === false && this.data.tags) this.select.change(this.reOpen.bind(this))
    if (this.$parent) this.$parent.change(this.onParentChange.bind(this))
  }

  // Select could depend on some parent component.
  // For example, streets select depends on cities select. When user change city,
  // then only selected city streets should be available.
  // For such cases parent data property exists with two properties:
  // - selector: id or class name to target parent element.
  // - name: field name to pass it into ajax params.
  // Example for streets select data:
  // parent: { selector: '#recipient-global-city-select', name: :city_id }
  //
  // Currently this feature works only for ajax selects.
  onParentChange(e, { clear = true } = {}) {
    // ajaxParams will be passed to ajax request.
    this.ajaxParams = { ...this.ajaxParams, [this.data.parent.name]: this.$parent.val() }
    // Clear current value when parent changed. We don't clear on initial render.
    if (clear) this.el.val(null).trigger('change')
  }

  reOpen() {
    // We reOpen only if it has some value in search
    // to avoid keeping search text inside select
    if (this.el.data('select2').selection.$search.val()) {
      this.select.select2('close')
      this.select.select2('open')
    }
  }

  // TODO: Too many pitfals with tooltip, unresolved issues:
  // - On opened select tooltip shouldn't be visible
  // - Double tooltips (bootstrap and default browser)
  // bindEvents() {
  //   this.select.change((e) => this.toggleTooltip())
  //   this.select.on('select2:opening', this.hideTooltip.bind(this))
  // }

  // hideTooltip() {
  //   const container = this.el.next('.select2-container')
  //
  //   if (!container.data('bs.tooltip')) return
  //
  //   container.tooltip('hide')
  // }
  //
  // toggleTooltip() {
  //   if (!this.el.data('tooltip')) return
  //
  //   const title = Array.from(this.el.select2('data')).map(item => item.text).join(' ')
  //   const container = this.el.next('.select2-container')
  //
  //   if (container.data('bs.tooltip')) {
  //     container.data('bs.tooltip').options.title = title
  //   } else {
  //     container.tooltip({title: title, placement: 'auto', trigger: 'hover'})
  //   }
  // }

  unmount() {
    if (this.el.first().data('select2') === undefined) return
    this.el.select2('destroy')
  }

  options() {
    return {
      language: {
        noResults: () => this.data.noResultsText || window.I18n.t('main.nothing_here'),
        maximumSelected: (e) => window.I18n.t('main.max_select_items', { max: e.maximum }),
        searching: () => null,
        loadingMore: () => `${window.I18n.t('main.loading')}...`,
        inputTooShort: (e) => window.I18n.t('main.min_search_length', { min: e.minimum })
      },
      placeholder: this.data.placeholder || undefined,
      templateResult: this.templateResult.bind(this),
      templateSelection: this.templateResult.bind(this),
      escapeMarkup: (markup) => markup,
      theme: `default ${this.el.prop('class')}`,
      tags: this.data.tags,
      tokenSeparators: this.el.data('tags-separators'),
      minimumResultsForSearch: this.data.search || this.data.tags ? 0 : -1,
      minimumInputLength: this.data.minChars ? Number(this.data.minChars) : 0,
      maximumSelectionLength: this.el.data('max-items'),
      ajax: this.ajaxOption()
    }
  }

  ajaxOption() {
    if (!this.data.ajax) return

    return {
      url: this.data.ajax,
      dataType: 'json',
      type: 'GET',
      data: (params) => {
        return { ...this.ajaxParams, query: params.term, page: params.page }
      },
      processResults: (data) => {
        let results = data.items.map((item) => ({ text: item.text, id: item.id }))
        const $empty = this.el.find('option[value=""]')
        const firstPage = !data.page || data.page === 1
        const selected = this.select.select2('data')[0] || {}

        // Include selected value
        if (firstPage && selected.id && !results.find(item => item.id === selected.id)) {
          results = [{ text: selected.text, id: selected.id }, ...results]
        }

        // Include empty option
        if ($empty.length && firstPage) results = [{ text: $empty.text(), id: '' }, ...results]

        // Ajax will clear any options, even create options.
        // Add create option on first page request.
        if (this.data.create && firstPage) results = [this.createRecordOption(), ...results]

        return {
          results,
          // Check for items length, because kaminari return last_page = false when no results
          pagination: { more: data.items.length && !data.last_page }
        }
      }
    }
  }

  templateResult(option) {
    const styles = this.data.styles
    if (!styles) return option.text

    const optionStyle = styles[option.id]

    if (!optionStyle) return option.text

    const optionHtml = `<span class="custom-styles" style="${optionStyle}">${option.text}</span>`

    return $(optionHtml)
  }

  addOptions(items, options) {
    addSelectOptions(this.select, items, options)
  }

  addCreateFeature() {
    let newOption = this.el.find('option').toArray().find(option => $(option).val() === CREATE_OPTION)

    if (!newOption) {
      const { id, text } = this.createRecordOption()
      this.el.prepend(new Option(text, id, false, false))
    }

    if (this.el.val() === CREATE_OPTION) this.el.val(null)

    if (!this.$modal) return

    this.select.on('select2:selecting', (e) => {
      if (e.params.args.data.id === CREATE_OPTION) return this.onSelectCreateOption(e)
    })

    // Means that create logic handled outside of this class
    if (this.data.customCreate) return

    this.$modal.find('form').submit((e) => {
      // onSubmitCreateOption is async
      onSubmitCreateOption(e, this.select, this.$modal, { label: this.data.label, value: this.data.value })
    })
  }

  onSelectCreateOption(e) {
    e.preventDefault()
    this.select.select2('close')
    this.openModal()
  }

  openModal() {
    this.$modal.find(".form-group input:not([type='checkbox']), .form-group textarea").val(null)
    this.$modal.modal('show')

    // Set default record attributes provided via select data-attributes
    Object.keys(this.data.attributes || {}).forEach((selector) => {
      this.$modal.find(selector).val(this.data.attributes[selector])
    })

    this.$modal.find('.form-group input, .form-group textarea').first().focus()
  }

  createRecordOption() {
    const text = typeof this.data.create === 'string' ? this.data.create : window.I18n.t('buttons.create')
    return { text, id: CREATE_OPTION }
  }
}

export default Select

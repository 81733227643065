// Helper class to dynamically create html chunks based on initial template.
// Template can be created from existing html.
// Then template can be inserted via 'create' function.
// New elemented insterted after the last child, so it works good with list of items.
// Able to work with nested array of fields. Replaces id and name attributes with correct values,
// so, if you have last input like 'scope[some_name][2]price', the inserted item will have
// value 'scope[some_name][3]price'.
//
// Usage:
// Initial HTML should have prepared template with some uniq class to find it.
// After page load initialize class with following params:
// @param container [jQuery object] - Items parent container.
// @param itemClass [String] - Common class that have all items.
// @param templateClass [String] - Class to find prepared template, this class will be removed.
// @param valueClass [String] - If you wan't to prevent creating more than one empty item,
//   than pass class of value to check.
// @param replaceNestedFieldsIndex [Boolean] - Set true if you want to replace index of attribute names.
//
// Call 'prepare', which will save in memory html template.
// Call 'create' to insert new item in the list.

import shortid from 'shortid'
import { connectWidgets, destroyWidgets } from 'main/widgets/helpers'
import { stringCopy } from 'services/string'

class ListItemTemplate {
  constructor(container, { itemClass, templateClass, valueClass,
    replaceNestedFieldsIndex = false } = options) {

    this.container = container
    this.itemClass = itemClass
    this.valueClass = valueClass
    this.templateClass = templateClass
    this.replaceNestedFieldsIndex = replaceNestedFieldsIndex
  }

  prepare() {
    const template = this.container.find(`.${this.templateClass}`).removeClass(this.templateClass)
    destroyWidgets(template)
    this.template = template[0].outerHTML
  }

  // Not used
  templateCopy() {
    return stringCopy(this.template)
  }

  fetchLastItem() {
    return this.container.find(`.${this.itemClass}`).last()
  }

  create() {
    const $lastItem = this.fetchLastItem()
    const $lastValue = $lastItem.find(`.${this.valueClass}`)

    if (this.valueClass && !$lastValue.val()) return

    this.container.append(this.template)

    const $newItem = this.fetchLastItem()

    const index = this.fetchNestedAttributeIndex($lastValue)
    this.replaceInputAttributes($newItem, index)
    connectWidgets($newItem)

    return $newItem
  }

  replaceInputAttributes($newItem, index) {
    $.map($newItem.find('input, select'), input => {
      if (index) {
        if ($(input).attr('name')) {
          const name = $(input).attr('name').replace(/\[\d+\]/, `[${index}]`)
          $(input).attr('name', name)
        }
      }

      const inputId = $(input).attr('id')

      if (inputId) $(input).attr('id', `${shortid.generate()}_${inputId}`)
    })
  }

  fetchNestedAttributeIndex($lastValue) {
    if (!this.replaceNestedFieldsIndex) return

    return Number($lastValue.attr('name').match(/\[(\d+)\]/)[1]) + 1
  }
}

export default ListItemTemplate

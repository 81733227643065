import { connectWidgets, destroyWidgets } from 'main/widgets/helpers'
import axios from 'axios'
import qs from 'qs'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { optimizeMobileTable } from 'services/table'
import { bindGlobalEvents } from 'services/common'
import { ajaxGlobalResponse } from 'services/ajax'

window.widgets = {}
window.ajaxGlobalResponse = ajaxGlobalResponse
window.connectWidgets = connectWidgets

// Format nested params correctly
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    })
  }

  config.headers['Accept'] = 'application/json'

  return config
})

bindGlobalEvents()

document.addEventListener('turbolinks:load', function() {
  connectWidgets($('body'))

  // Add csrtToken to all axios requests, without it rails will block not GET requests.
  // If, for some reasons it won't work, check https://github.com/rails/webpacker/issues/1015
  const csrfToken = document.querySelector("meta[name=csrf-token]") || {content: 'no-csrf-token'}
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken.content

  if (window.RAILS_ENV === 'production') {
    Sentry.init({
      dsn: 'https://d8b60ffa13a943cfbf7cad3ae9e74643@o139978.ingest.sentry.io/5644360',
      integrations: [new BrowserTracing()],
      ignoreErrors: ['Non-Error promise rejection captured']

      // // Set tracesSampleRate to 1.0 to capture 100%
      // // of transactions for performance monitoring.
      // // We recommend adjusting this value in production
      // tracesSampleRate: 0.2
    })
  }

  optimizeMobileTable('.table-mobile-tranformation')
})

document.addEventListener('turbolinks:before-cache', function() {
  destroyWidgets($(document))
  $('.modal').modal('hide')
  $('[data-toggle="popover"]').popover('hide')
})

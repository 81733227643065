import axios from 'axios'
import { errorMessage } from 'services/errors'
import { debounce, groupBy, compact } from 'lodash'
import { fetchNumberInputValue, formattedQuantity } from 'services/numbers'
import qs from 'qs'
import shortid from 'shortid'

// TODO: Remove this.configured - seems useless now

class ProductsCatalog {
  constructor(el, master) {
    this.el = el
    this.master = master // Doc or ManufactureWidget
    this.settings = this.el.data('settings')
    this.query = ''
    this.page = null
    this.categoryId = null
    this.$searchField = this.el.find('.search-products-field')
    this.$products = this.el.find('.products-table tbody')
    this.lazySearch = debounce(this.onSearch, 300)
    this.$selectAll = this.el.find('.products-table input.select-all')
    this.lazyChangeQuantity = debounce(this.onChangeQuantity, 300)
    this.products = {}
    this.selected = {}
    this.configured = {}
    this.bindEvents()
  }

  bindEvents() {
    this.el.find('.toggle-categories').click(this.onToggleCategories)
    this.el.find('.tree-item-box').click(this.onSelectCategory)
    this.el.find('.submit-btn').click(this.onSubmit)
    this.$selectAll.change(this.onSelectAll)
    this.$searchField.keyup(this.lazySearch)
    this.el.on('shown.bs.modal', this.onOpenModal)
    this.el.on('hide.bs.modal', this.onCloseModal)
  }

  bindProductsTableEvents() {
    this.el.find('.pagination a').click(this.onPaginate)
    this.$products.find('.select-record').change(this.onSelectRecord)
    this.$products.find('.record-title').click(this.toggleRecordSelected)
    this.$products.find('.modifications-toggle').click(this.toggleModifications)
    this.$products.find('.catalog-quantity').keyup(this.lazyChangeQuantity)
    this.$products.find('.catalog-quantity').change(this.lazyChangeQuantity)
    // this.$products.find('.add-product').click(this.onAddProduct)
    // this.$products.find('.remove-product').click(this.onRemoveProduct)
  }

  onOpenModal = (e) => {
    this.selected = {}
    this.products = {}
    this.page = null
    this.configured = groupBy(this.master.lineItems || [], 'productId')
    this.loadProducts()
  }

  onCloseModal = (e) => {
    this.selected = {}
    this.products = {}
    this.page = null
    this.$selectAll.prop('checked', false)
    this.$products.empty()
  }

  onToggleCategories = (e) => {
    e.preventDefault()

    this.el.find('.catalog-categories').toggleClass('mobile-visible')
  }

  onAddProduct = (e) => {
    e.preventDefault()

    const $row = $(e.target).closest('.product-row')
    const productId = $row.data('product-id')

    $row.addClass('configured')
    $row.find('.select-product').prop('checked', false).prop('disabled', true)
    this.configured[productId] = [{ product: this.products[productId] }]
  }

  onRemoveProduct = (e) => {
    e.preventDefault()

    const $row = $(e.target).closest('.product-row')
    const productId = $row.data('product-id')

    $row.removeClass('configured')
    $row.find('.select-product').prop('disabled', false)
    delete this.configured[productId]
  }

  toggleModifications = (e) => {
    e.preventDefault()

    const $row = $(e.target).closest('.product-row')
    const productId = $row.data('product-id')

    const $modifications = this.fetchModificationsRow(productId)

    if ($row.hasClass('modifications-opened')) {
      $row.removeClass('modifications-opened')
      $modifications.hide()
    } else {
      $row.addClass('modifications-opened')
      $modifications.show()
    }
  }

  onSubmit = (e) => {
    e.preventDefault()

    $(e.currentTarget).prop('disabled', true)

    this.master.applyCatalogSelection(this.selected, this.configured)

    // Hide should be after apply, becuase it clean up all selection data
    this.el.modal('hide')
    $(e.currentTarget).prop('disabled', false)
  }

  onSelectAll = (e) => {
    e.preventDefault()

    const checked = $(e.target).prop('checked')

    this.$products.find('.select-record').map((_, item) => {
      const $row = $(item).closest('.record-row')
      $(item).prop('checked', checked)
      this.selectRecord($row, checked)
    })
  }

  selectRecord($row, checked) {
    const { productId, modificationId, selectedId } = this.fetchItemRowData($row)
    let quantity

    if (checked) {
      const $quantity = $row.find('.catalog-quantity')

      // Quantity disabled for serial and product with modification
      if ($quantity.length) {
        quantity = 1
        $quantity.prop('disabled', false).val(quantity).select()
      }

      this.updateSelected({selectedId, productId, modificationId, quantity})
    } else {
      $row.find('.catalog-quantity').prop('disabled', true).val(null)
      delete this.selected[selectedId]
    }
  }

  onSelectRecord = (e) => {
    e.preventDefault()

    const checked = $(e.target).prop('checked')
    const $row = $(e.target).closest('.record-row')

    this.selectRecord($row, checked)
  }

  toggleRecordSelected = (e) => {
    const $row = $(e.currentTarget).closest('.record-row')

    if ($row.hasClass('show-modifications')) return this.toggleModifications(e)

    const $checkbox = $row.find('.checkbox-column input.select-record')
    const checked = !$checkbox.prop('checked')
    $checkbox.prop('checked', checked)

    this.selectRecord($row, checked)
  }

  onChangeQuantity = (e) => {
    const quantity = fetchNumberInputValue($(e.target))
    const $row = $(e.target).closest('.record-row')
    const { productId, modificationId, selectedId } = this.fetchItemRowData($row)

    this.updateSelected({selectedId, productId, modificationId, quantity})
  }

  onPaginate = (e) => {
    e.preventDefault(e)

    const link = $(e.currentTarget).attr('href')
    const page = link.match(/page=(\d+)/)
    this.page = page ? page[1] : null
    this.loadProducts()
  }

  onSearch = (e) => {
    e.preventDefault()
    this.query = this.$searchField.val()
    this.page = null
    this.loadProducts()
  }

  onSelectCategory = (e) => {
    e.preventDefault()
    this.el.find('.tree-item-box').removeClass('active')
    const $category = $(e.currentTarget).find('.tree-item')
    const categoryId = $category.data('item-id')
    this.page = null

    if (this.categoryId === categoryId) {
      this.categoryId = null
    } else {
      this.categoryId = categoryId
      $category.closest('.tree-item-box').addClass('active')
    }

    this.loadProducts()
  }

  fetchItemRowData($row) {
    const productId = $row.data('product-id')
    const modificationId = $row.data('modification-id')
    const selectedId = modificationId || productId

    return { productId, modificationId, selectedId }
  }

  updateSelected({selectedId, productId, modificationId, quantity} = {}) {
    const product = this.products[productId]

    // Edge case. How product could be undefined?
    // Well, it's possible when quantity input focused and user click to pagination and in the same time
    // changing quantity via keydown or just pressing some buttton.
    if (!product) return

    this.selected[selectedId] = { ...product, modificationId, quantity }
  }

  setProducts(products = []) {
    this.products = products.reduce((acc, product) => {
      acc[product.id] = product
      return acc
    }, {})
  }

  loadProducts = async () => {
    if (this.cancelLoadProductsRequest) this.cancelLoadProductsRequest.cancel('Load products canceled')

    this.cancelLoadProductsRequest = axios.CancelToken.source()

    const params = {
      query: this.query,
      document_id: this.master.data.id,
      typedoc: this.master.data.typedoc,
      typeprice: this.master.data.typeprice,
      stock_id: this.master.data.stock_from_id || this.master.data.stock_to_id,
      product_type: this.settings.product_types,
      group_product_id: this.categoryId,
      permitted_for_manufacture_master_id: this.settings.manufacture_master_id,
      page: this.page,
      modifications_mode: true,
      pagination: true
    }

    try {
      this.el.find('.alert').hide()

      const response = await axios.get(`${gon.locale_path}/platform/products/search.json`, {
        params, cancelToken: this.cancelLoadProductsRequest.token
      })

      if (response.data.error) return this.handleRequestError(response.data.error)
      this.setProducts(response.data.products)
      this.renderProducts(response.data)
      this.$selectAll.prop('checked', false)
      this.scrollToTableTop()
    } catch(e) {
      if (axios.isCancel(e)) return console.error(e.message)

      this.handleRequestError(errorMessage(e))
    }
  }

  renderProducts(data) {
    const rows = (data.products || []).map(product => this.productTemplate(product))
    this.$products.html(rows)
    this.el.find('.products-pagination').replaceWith(data.pagination)

    this.bindProductsTableEvents()
  }

  productTemplate(product) {
    const options = {
      supply: this.settings.prices_read_policy.supply,
      retail: this.settings.prices_read_policy.retail,
      wholesale: this.settings.prices_read_policy.wholesale,
      remains: this.settings.read_remains,
      reserve: this.settings.read_reserves,
      showModifications: product.barcode_accounting && !!product.modifications.length,
      serialAccounting: product.serial_accounting,
      configured: false // !!this.configured[product.id]
    }
    // const colspan = 3 + compact([
    //   options.supply, options.retail, options.wholesale, options.remains, options.reserve
    // ]).length
    const colspan = 4 + compact([
      options.remains, options.reserve
    ]).length

    const rowClass = [
      'product-row record-row',
      options.configured ? 'configured' : '',
      options.showModifications ? 'show-modifications' : '',
    ].join(' ')

    return `
      <tr class='${rowClass}' data-product-id='${product.id}'>
        ${this.catalogColumnsTemplate(product, 'product', options)}
      </tr>
      ${options.showModifications ?
        `<tr class='product-modifications-row' id='product-modifications-row-${product.id}' style='display: none'>
          <td class='modifications-cell' colspan='${colspan}'>
            <table class='product-modifications table table-hover table-responsive'>
              <tbody>
                ${product.modifications.map(modification => {
                  return `<tr class='record-row' data-modification-id='${modification.id}' data-product-id='${product.id}'>
                    ${this.catalogColumnsTemplate(modification, 'modification', options)}
                  </tr>`
                }).join('')}
              </tbody>
            </table>
          </td>
        </tr>`
      : ''}
    `
  }

  catalogColumnsTemplate(record, recordType, options) {
    const selected = this.selected[record.id]
    const isSelected = !!selected
    const isProduct = recordType === 'product'
    const isProductWithModifications = isProduct && options.showModifications
    const title = isProduct ? [record.title, record.sku].join(' ') : record.title
    const price = record.prices[this.settings.typeprice] || 0

    return `
      <td class='checkbox-column'>
        ${isProductWithModifications ?
          `<button type='button' class='modifications-toggle'>
            <i class='fas fa-caret-right closed-icon'></i>
            <i class='fas fa-sort-down opened-icon'></i>
          </button>`
          :
          `<div class='checkbox select-product-box'>
            <input type='checkbox' ${isSelected ? 'checked' : ''} ${options.configured ? 'disabled' : ''} class='select-record' />
          </div>`
        }
        ${isProduct ?
          `<button type='button' class='btn btn-success add-product'><i class='fas fa-plus'></i></button>
           <button type='button' class='btn btn-danger remove-product'><i class='fas fa-minus'></i></button>` : ''
        }
      </td>
      <td class='record-title'>
        <div class='title'>${title}</div>

        ${options.remains ?
          `<div class='mobile-hint visible-xs hint-box'>
              ${this.settings.show_price ? `<span style='display: inline-block; min-width: 100px'><span>${window.I18n.t('main.price')}: </span><strong>${price}</strong></span>` : ''}
              <span>${window.I18n.t('main.remain')}: </span><strong>${formattedQuantity(record.remains, { zero: '0', pretty: true })}</strong>
              ${options.reserve && Number(record.reserve || 0) > 0 ? `/ <strong>${record.reserve}</strong>` : ''}
          </div>` : ''
        }
      </td>
      <td class='catalog-quantity-box'>
        ${isProductWithModifications || options.serialAccounting ? '' :
          `<input type='number' ${isSelected ? '' : 'disabled'} value='${isSelected ? selected.quantity : ''}' class='form-control catalog-quantity' step='any' min='0' autocomplete='off'>`
        }
      </td>
      ${this.settings.show_price ? `<td class='price-cell'>${price}</td>` : ''}
      ${options.remains ? `<td class='remains-cell'>${formattedQuantity(record.remains, { zero: '', pretty: true })}</td>` : ''}
      ${options.reserve ? `<td class='reserve-cell'>${record.reserve || ''}</td>` : ''}
    `

    // ${options.supply ? `<td>${record.prices.price_supply}</td>` : ''}
    // ${options.retail ? `<td>${record.prices.price_retail}</td>` : ''}
    // ${options.wholesale ? `<td>${record.prices.price_wholesale}</td>` : ''}
  }

  fetchModificationsRow(productId) {
    return $(`#product-modifications-row-${productId}`)
  }

  show() {
    this.el.modal('show')
  }

  scrollToTableTop() {
    this.el.find('.table-box')[0].scrollTop = 0
  }

  handleRequestError(message) {
    console.error(message)
    this.el.find('.alert').text(message).show()
  }
}

export default ProductsCatalog

// Responsible for calculating various discounts for line item.
// #calculate should be called per line item

import { toArray, sortBy, last, round, isNumber } from 'lodash'
import { decamelizeKeys } from 'services/objects'
import { isFirstDateBefore } from 'services/dates'
import { discountedAmount, add, subtract, multiply, divide } from 'services/numbers'

const CUMULATIVE_DISCOUNT = 'cumulative'
const SPECIAL_DISCOUNT = 'special'
const FIXED_DISCOUNT = 'fixed'
const BONUS_DISCOUNT = 'bonus'
const FIXED_TYPEPRICE = 'price_fixed'
const NO_DISCOUNT = { discountPercent: 0, bonusEntryRatio: 0, discountCalculated: true, accruedBonus: 0, appliedDiscounts: [] }

class Discount {
  constructor({discounts, doc, contragent, settings, li, previousCalculation, manualDiscountPercent, manualSpecialPrice, moneyRounding}) {
    this.allDiscounts = discounts
    this.doc = doc
    this.contragent = contragent
    this.settings = settings
    this.li = li
    this.moneyRounding = moneyRounding
    this.manualDiscountPercent = manualDiscountPercent
    this.manualSpecialPrice = manualSpecialPrice
    this.appliedDiscounts = []
    this.previousCalculation = previousCalculation
  }

  calculate(force = false) {
    if (force) {
      this.manualDiscountPercent = undefined
      this.manualSpecialPrice = undefined
    }

    const { discountPercent, discountPrice, typeprice,
      bonusEntryRatio, appliedDiscounts, discountPriceMin } = this.performDiscountCalculations(force)
    const { rawAmount, discountsAmount, amount, priceWithDiscount } = this.calculateAmounts(discountPrice, discountPercent)

    return {
      discountDisabled: this.li.product.discountDisabled,
      // For aggregation case, when several discounts applied with Special discount,
      // both discountPercent and discountPrice should be used to calculate amount.
      // discountPrice and typeprice has value only when Special discount applied.
      discountPercent,
      // User may set discount percent manually regardless of any calculations,
      // in this case manual discount will have more priority.
      manualDiscountPercent: this.manualDiscountPercent,
      manualSpecialPrice: this.manualSpecialPrice,
      price: this.li.price,
      discountPrice,
      // typeprice here just for information, we don't use it in the code yet
      typeprice,
      rawAmount,
      discountsAmount,
      amount,
      bonusEntryRatio,
      // Just amount / quantity
      priceWithDiscount,
      // We should save priceMin that was at the moment of calculation,
      // that's why it's here.
      discountPriceMin,
      // Even if we use previousCalculation, bonuses should be recalculated,
      // because it depends on amount, and amount could be changed even if quantity changed.
      accruedBonus: this.calculateBonuses(amount, bonusEntryRatio),
      appliedDiscounts,
      discountCalculated: true
    }
  }

  // Return amount of money that contragent able to
  // use as payment for document via field paid_by_bonus
  // Not used for now, maybe will be helpful in skypos
  // availableBonusesToPay() {
  //   const { documentBonus, amount } = this.doc
  //   if (!documentBonus) return 0
  //
  //   const moneyInBonuses = Number(this.contragent.bonuses) / documentBonus.bonusPayRatio
  //
  //   const maxPayment = amount * documentBonus.bonusMaxPay / 100
  //
  //   return round(Math.min(moneyInBonuses, maxPayment), 2)
  // }

  // private

  performDiscountCalculations(force) {
    // For new calucation we return product current price_min,
    // but for existing (maybe old deactivated document) calculation we should use this.previousCalculation
    const discountPriceMin = Number(this.li.prices.priceMin || 0)

    if (!this.allDiscounts.length || this.li.product.discountDisabled) {
      return { ...NO_DISCOUNT, discountPriceMin }
    }

    if (!force && this.previousCalculation) return this.previousCalculation

    let discounts
    if (this.settings.discountsAggregation) {
      discounts = this.aggregation()
    } else {
      discounts = this.priority()
    }

    return {
      ...discounts,
      bonusEntryRatio: this.bonusEntryRatio(),
      appliedDiscounts: this.formattedAppliedDiscounts(),
      discountPriceMin
    }
  }

  calculateAmounts(discountPrice, discountPercent) {
    const percent = this.manualOrCalculatedDiscountPercent(discountPercent)
    const price = this.manualOrCalculatedSpecialPrice(discountPrice)
    const rawAmount = multiply(this.li.quantity, price)
    const amount = discountedAmount(rawAmount, percent, this.moneyRounding)
    const discountsAmount = round(subtract(rawAmount, amount), this.moneyRounding)
    const priceWithDiscount = round(divide(amount, this.li.quantity || 1), this.moneyRounding)
    return { amount, discountsAmount, rawAmount: round(rawAmount, this.moneyRounding), priceWithDiscount }
  }

  manualOrCalculatedDiscountPercent(discountPercent) {
    if (this.manualDiscountPercent || this.manualDiscountPercent === 0) return this.manualDiscountPercent
    return discountPercent
  }

  manualOrCalculatedSpecialPrice(discountPrice) {
    if (isNumber(this.manualSpecialPrice)) return this.manualSpecialPrice

    return isNumber(discountPrice) ? discountPrice : this.li.price
  }

  // Return bonuses in entry ratio according to line item's amount
  calculateBonuses(amount, bonusEntryRatio) {
    if (!bonusEntryRatio) return 0
    return round(divide(amount, bonusEntryRatio), this.moneyRounding)
  }

  aggregation() {
    const { discountPrice, typeprice } = this.specialDiscount()
    const discountPercent = add(this.fixedDiscount().discountPercent, this.cumulativeDiscount().discountPercent)

    return { discountPrice, typeprice, discountPercent }
  }

  priority() {
    const specialDiscount = this.specialDiscount()

    if (specialDiscount.typeprice) return specialDiscount

    const fixedDiscount = this.fixedDiscount()
    if (fixedDiscount.discountPercent > 0) return fixedDiscount

    const cumulativeDiscount = this.cumulativeDiscount()
    if (cumulativeDiscount.discountPercent > 0) return cumulativeDiscount

    return NO_DISCOUNT
  }

  formattedAppliedDiscounts() {
    return this.appliedDiscounts.map(discount => {
      return {
        id: discount.id,
        name: discount.name,
        discountPercent: discount.discountPercent,
        discountPrice: discount.discountPrice,
        useFixedPrice: discount.useFixedPrice,
        kind: discount.kind,
        bonusEntryRatio: discount.bonusEntryRatio
      }
    })
  }

  bonusEntryRatio() {
    if (!this.contragent) return 0
    // Bonus discount could be just one, so pick first one
    const discount = this.applicableDiscounts(BONUS_DISCOUNT)[0]
    if (!discount || this.hasBlockingDiscount(discount)) return 0
    // Here we just return entry ratio, because bonus calculation
    // should be after calculating line item amount.
    if (discount.bonusEntryRatio) this.appliedDiscounts.push(discount)
    return discount.bonusEntryRatio
  }

  hasBlockingDiscount(discount) {
    return discount.blockingDiscountIds.some((discountId) => {
      return this.appliedDiscounts.map(d => d.id).includes(discountId)
    })
  }

  cumulativeDiscount() {
    if (!this.contragent) return NO_DISCOUNT
    const discounts = this.applicableDiscounts(CUMULATIVE_DISCOUNT)
    if (!discounts.length) return NO_DISCOUNT
    // Take max cumulative discount rate in case if there are many cumulative discounts
    const rates = this.cumulativeRates(discounts)
    if (!rates.length) return NO_DISCOUNT

    const discount = this.selectBestDiscount(rates, 'rate')
    const discountPercent = discount.discountPercent

    if (discountPercent) this.appliedDiscounts.push({...discount, discountPercent})
    return { discountPercent }
  }

  cumulativeRates(discounts) {
    return discounts.reduce((acc, discount) => {
      const rates = discount.levels
        .filter(level => parseFloat(level.amount) < parseFloat(this.contragent.purchasesAmount))
        .map(level => level.rate)

      if (rates.length) acc.push({...discount, discountPercent: Math.max(...rates)})
      return acc
    }, [])
  }

  fixedDiscount() {
    const discounts = this.applicableDiscounts(FIXED_DISCOUNT)
    if (!discounts.length) return NO_DISCOUNT

    const discount = this.selectBestDiscount(discounts)
    const discountPercent = discount.fixedRate

    if (discount.fixedRate) this.appliedDiscounts.push({...discount, discountPercent })
    return { discountPercent }
  }

  specialDiscount() {
    const discounts = this.applicableDiscounts(SPECIAL_DISCOUNT)
    if (!discounts.length) return NO_DISCOUNT

    const discount = this.selectBestPrice(discounts)
    const discountPrice = discount.discountPrice

    if (discount.typeprice) this.appliedDiscounts.push({...discount, discountPercent: 0, discountPrice})

    return { discountPrice, discountPercent: 0, typeprice: discount.typeprice }
  }

  // Best means that we took discount with highest percent
  selectBestDiscount(discounts, field = 'fixedRate') {
    return last(sortBy(discounts, [field]))
  }

  // Only for Special discount. Best means that we took discount with lowest price,
  // even if price is zero. Validation on minimum price implemented on line item.
  selectBestPrice(discounts) {
    const discountsWithPrice = discounts.map((discount) => {
      let discountPrice
      let typeprice = discount.typeprice

      if (discount.useFixedPrice) {
        discountPrice = Number(discount.fixedPrice || 0)
        typeprice = FIXED_TYPEPRICE
      } else {
        discountPrice = decamelizeKeys(this.li.prices)[discount.typeprice] || 0
      }

      return { ...discount, discountPrice, typeprice }
    })

    return sortBy(discountsWithPrice, ['discountPrice'])[0]
  }

  applicableDiscounts(kind) {
    return this.allDiscounts.filter(discount => {
      return discount.kind === kind &&
        this.isProductApplicable(discount) &&
        this.isContragentApplicable(discount) &&
        this.isPeriodApplicable(discount)
    })
  }

  isProductApplicable(discount) {
    return discount.allProducts ||
      toArray(discount.groupProductIds).includes(this.li.product.groupId) ||
        toArray(discount.productIds).includes(this.li.product.id)
  }

  isContragentApplicable(discount) {
    if (discount.allContragents) return true
    if (!this.contragent) return false

    return toArray(discount.groupContragentIds).includes(this.contragent.groupId)
  }

  // If date is empty than it's applicable, otherways check if period includes date
  isPeriodApplicable(discount) {
    const startTimeFits = !discount.startTime ||
      isFirstDateBefore(discount.startTime, this.doc.createdAt, { equal: true })
    const endTimeFits = !discount.endTime ||
      isFirstDateBefore(this.doc.createdAt, discount.endTime, { equal: true })

    return startTimeFits && endTimeFits
  }
}

export default Discount

import BaseWidget from './base_widget'
import { debounce, trim } from 'lodash'
import { errorMessage } from 'services/errors'
import axios from 'axios'

class MasterTemplateWidget extends BaseWidget {
  initialize() {
    this.form = this.el.find('.master-template-form')
    this.$preview = this.el.find('.master-template-preview-content')
    this.lazySave = debounce(this.save, 100)
    this.$editors = this.el.find('.master-template-editor')
    this.format = this.el.data('format')
  }

  bindEvents() {
    this.form.submit(this.handleSubmit.bind(this))
    if (!this.isEditorsEnabled()) {
      this.el.find('.free-image-upload-field').change(this.onCustomizationImageUpload.bind(this))
      // For non-editor templates we should save and update preview on each change
      this.form.find('input, select').change(this.handleChange.bind(this))
    } else {
      this.el.find('.add-variable-btn').click(this.addVariable.bind(this))
    }
  }

  handleChange(e) {
    e.preventDefault()

    this.lazySave({reload: false})
  }

  handleSubmit(e) {
    e.preventDefault()

    this.lazySave({reload: true})
  }

  onCustomizationImageUpload(event) {
    const file = event.target.files[0]
    const $fileUpload = $(event.target)
    const $fileInputID = $(event.target).closest('.form-group').find('.free-image-id')
    const reader = new FileReader()

    reader.onload = (e) => {
      this.uploadImage(e.target.result, (response) => {
        $fileUpload.val(null)
        $fileInputID.val(response.id)
        this.el.find('.free-image-box').html(`<img src="${response.standard}" class="img-responsive"/>`)
        this.lazySave({reload: false})
      })
    }

    reader.readAsDataURL(file)
  }

  uploadImage(file, callback) {
    $.ajax({
      method: 'POST',
      data: { image: { file, kind: 'main' }, imageable_id: this.el.data('template-id'),
        imageable_type: 'MasterTemplate' },
      url: `${gon.locale_path}/platform/images`,
      dataType: 'json',
      success: callback,
      error(error) {
        console.error(error.status, error.statusText)
      }
    })
  }

  addVariable(e) {
    e.preventDefault()
    const editor = this.activeEditor()
    const variable = trim($(e.currentTarget).find('.value').text())

    if (!editor || !variable || !variable.length) return

    if (this.isHtmlEditor()) {
      this.insertVariableToHtmlEditor(editor, variable)
    } else {
      this.insertVariableToTextInput(editor, variable)
    }
  }

  insertVariableToHtmlEditor(editor, variable) {
    // Inserts an HTML element or HTML string or plain string at the current cursor position
    // @param {Boolean} notCleaningData If true, inserts the HTML string
    //   without refining it with core.cleanHTML.
    // @param {Boolean} checkCharCount If true, if "options.maxCharCount"
    //   is exceeded when "element" is added, null is returned without addition.
    editor.insertHTML(variable, false, true)
  }

  insertVariableToTextInput($editor, variable) {
    const cursor = $editor.prop('selectionStart')
    const value = $editor.val() || ''
    const before = value.substring(0,  cursor)
    const after  = value.substring(cursor, value.length)

    $editor.val(before + variable + after)
  }

  async save({ reload = false } = {}) {
    this.el.find('.submit-btn').prop('disabled', true)
    this.el.find('.alert').hide()

    try {
      if (this.isHtmlEditor()) this.allEditors().map(editor => editor.save())
      const method = this.form.attr('method')
      const url = `${this.form.attr('action')}.json`
      const data = new FormData(this.form[0])
      const response = await axios[method](url, data)
      if (response.data.error) return this.handleError(response.data.error)
      if (!this.isEditorsEnabled()) this.reloadPreview(response.data)
      if (reload) window.location.reload()
    } catch (e) {
      const message = e.response && e.response.data.error
      this.handleError(message || errorMessage(e))
    } finally {
      this.el.find('.submit-btn').prop('disabled', false)
    }
  }

  reloadPreview(data) {
    if (!this.$preview.length || !data || !data.preview) return

    this.$preview.html(data.preview)
  }

  handleError(message) {
    const $alert = this.el.find('.alert')
    $alert.text(message).show()
    // Scroll to the begining of modal
    this.el[0].scrollIntoView()
  }

  isHtmlEditor() {
    return this.format !== 'sms' && this.isEditorsEnabled()
  }

  isEditorsEnabled() {
    return !!this.$editors.length
  }

  allEditors() {
    if (!this.isEditorsEnabled()) return []

    return Array.from(this.$editors).map(el => this.isHtmlEditor() ? el.widgetInstance : el)
  }

  activeEditor() {
    const $editor = this.el.find('.tab-pane.active .master-template-editor')

    if (!$editor.length) return

    if (this.isHtmlEditor()) {
      return $editor[0].widgetInstance
    } else {
      return $editor
    }
  }
}

export default MasterTemplateWidget

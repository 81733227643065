import dayjs from 'dayjs'
import { DATE_FORMAT, DATETIME_FORMAT } from 'main/constants'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

export function formattedDate(datetime = null) {
  const date = datetime || dayjs()
  return date.toISOString()
}

export function displayDate(stringDatetime, { time = false } = {}) {
  const date = dayjs(stringDatetime || undefined)
  return date.format(time ? DATETIME_FORMAT : DATE_FORMAT)
}

export function isFirstDateBefore(date1, date2, format = {}, options = {}) {
  const day1 = dayjs(date1, format.date1)
  const day2 = dayjs(date2, format.date2)

  if (options.equal && formattedDate(day1) === formattedDate(day2)) return true

  return day1.isBefore(day2)
}

export function day(string, format) {
  return dayjs(string, format)
}

// Widget to handle interactions with prices changes form

import BaseWidget from './base_widget'
import axios from 'axios'

class PricesChangeWidget extends BaseWidget {
  initialize() {
    this.$form = this.el.find('.prices-change-form')
    this.$alert = this.el.find('.alert')
  }

  bindEvents() {
    this.el.find('.calculation-method input[type=radio]')
      .change(this.handleCalculationMethodChange.bind(this))
    this.el.find('.action-operator-select')
      .change(this.handleActionOperatorChange.bind(this))
    this.$form.submit(this.handleSubmit.bind(this))
  }

  async handleSubmit(e) {
    e.preventDefault()

    try {
      this.toggleLoader(true)
      this.$alert.hide()

      const url = this.$form.prop('action')
      const method = this.$form.prop('method')
      const params = this.$form.serialize()
      const response = await axios[method](url, params)
      const { status, error, redirect_url } = response.data

      if (status !== 'success') {
        this.toggleLoader(false)
        return this.showAlert(error)
      }

      this.afterSuccessPricesChange(redirect_url)
    } catch(e) {
      this.toggleLoader(false)
      console.error(e)
      this.showAlert(e.response && e.response.data.error)
    }
  }

  afterSuccessPricesChange(redirect_url) {
    this.el.find('.prices-change-loader').hide()
    this.el.find('.prices-change-completed').show()

    setTimeout(() => {
      // In case if we need redirect somewhere else
      // response should include redirect_url
      if (redirect_url) {
        window.location = redirect_url
      } else {
        window.location.reload()
      }
    }, 5000)
  }

  toggleLoader(show) {
    this.el.find('.prices-change-container').toggle(!show)
    this.el.find('.prices-change-loader').toggle(show)
  }

  showAlert(message) {
    this.$alert.show()
    this.$alert.text(message || window.I18n.t('errors.unexpected_error'))
  }

  handleCalculationMethodChange(e) {
    const method = $(e.target).val()

    switch (method) {
      case 'absolute':
        this.el.find('.calculation-method-price-container').hide()
        this.el.find('.absolute-value-container').show()
        break
      case 'product_price':
        this.el.find('.absolute-value-container').hide()
        this.el.find('.document-price-selection-box').hide()
        this.el.find('.convert-to-current-rate-box').hide()
        this.el.find('.calculation-method-price-container').show()
        break
      case 'document_price':
        this.el.find('.absolute-value-container').hide()
        this.el.find('.document-price-selection-box').show()
        this.el.find('.convert-to-current-rate-box').show()
        this.el.find('.calculation-method-price-container').show()
    }
  }

  handleActionOperatorChange(e) {
    const operator = $(e.target).val()

    if (!operator || operator === 'equal') {
      this.el.find('.action-value-box').hide()
      this.el.find('.rounding-box').hide()
      this.el.find('#active-value-input').val(null)
    } else {
      this.el.find('.action-value-box').show()
      this.el.find('.rounding-box').show()
    }
  }
}

export default PricesChangeWidget

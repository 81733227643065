import BaseWidget from './base_widget'

class ExportExcelWidget extends BaseWidget {
  initialize() {
    this.modal = this.el.closest('.modal')
    this.form = this.el.find('form')
  }

  bindEvents() {
    this.form.submit(this.submit.bind(this))
  }

  // Open new tab to download file, and reload current page.
  // Otherwise after downloading file user will see same export modal.
  async submit(e) {
    e.preventDefault()

    const path = `${this.form.attr('action')}?${this.form.serialize()}`
    this.modal.modal('hide')
    window.open(path, '_blank')
  }
}

export default ExportExcelWidget

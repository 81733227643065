import axios from 'axios'
import { connectWidgets } from 'main/widgets/helpers'

class DocumentExpenses {
  constructor(el, doc) {
    this.el = el
    this.doc = doc

    if (!this.doc.isManufacture()) return

    this.bindEvents()
  }

  bindEvents() {
    this.doc.el.find('.document-expenses-tab').on('show.bs.tab', this.reload)
  }

  reload = async () => {
    this.renderMockContent({loading: true})

    try {
      const contentPath = `${gon.locale_path}/platform/documents/${this.doc.data.id}/expenses.json`
      const response = await axios.get(contentPath)
      if (response.data.error) return this.renderMockContent({error: true})

      this.el.html(response.data)
      connectWidgets(this.el)
      this.bindContentEvents()
    } catch(e) {
      console.error(e)
      this.renderMockContent({error: true})
    }
  }

  renderMockContent({loading = false, error = false} = {}) {
    const content = `<div class='panel panel-danger document-materials mock-panel'>
      <div class='panel-heading'>
        <strong>${window.I18n.t('main.expenses')}</strong>

        ${loading ? "<div class='loader loader-sm blue'></div>" : ''}
        ${error ? "<button class='reload-btn'><i class='fas fa-sync-alt'></i></button>" : ''}
      </div>
      ${error ? '' : "<div class='panel-body' style='min-height: 200px'></div>"}
    </div>`

    this.el.html(content)
    this.el.find('.reload-btn').click((e) => {
      e.preventDefault()
      this.reload()
    })
  }

  bindContentEvents() {
  }
}

export default DocumentExpenses

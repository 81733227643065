export function escapeRegex(string) {
  if (!string) return string
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function textInsideBrackets(string) {
  const matched = string.match(/\[(.*?)\]/)
  if (!matched) return

  return matched[1]
}

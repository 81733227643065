import BaseWidget from './base_widget'
import suneditor from 'suneditor'
import plugins from 'suneditor/src/plugins'
import { tableStylePlugin } from 'services/editor'
import axios from 'axios'
import suneditorLanguages from 'suneditor/src/lang'
import { trim, omitBy, isUndefined } from 'lodash'

// TODO: Delete image from storage when it's deleted from editor

class SuneditorWidget extends BaseWidget {
  render() {
    this.editor = suneditor.create(this.el[0], this.options())
  }

  bindEvents() {
    this.editor.onImageUploadBefore = this.onImageUploadBefore.bind(this)
  }

  options() {
    return omitBy({
      plugins: {...plugins, tableStyle: tableStylePlugin()},
      buttonList: [
        ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
        ['bold', 'underline', 'italic', 'fontColor', 'hiliteColor'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'tableStyle', 'image', 'link', 'showBlocks',  'codeView', 'fullScreen'],
      ],
      resizeEnable: false,
      height: 'auto',
      width: '100%',
      attributesWhitelist: {
        all: 'style'
      },
      frameAttrbutes: {
        spellcheck: false
      },
      lang: suneditorLanguages[window.I18n.locale],
      height: this.data.height,
      minHeight: this.data.minHeight,
      maxHeight: this.data.maxHeight,
      width: this.data.width,
      toolbarContainer: this.data.toolbarContainer,
    }, isUndefined)
  }

  unmount() {
    this.editor.destroy()
  }

  save() {
    // Save doesn't work when codeView enabled, so we need to switch to normal mode
    if (this.editor.core._variable.isCodeView) this.editor.core.toggleCodeView()

    this.editor.save()
  }

  // Inserts an HTML element or HTML string or plain string at the current cursor position
  // @param {Boolean} notCleaningData If true, inserts the HTML string
  //   without refining it with core.cleanHTML.
  // @param {Boolean} checkCharCount If true, if "options.maxCharCount"
  //   is exceeded when "element" is added, null is returned without addition.
  insertHTML(htmlOrString, notCleaningData, checkCharCount) {
    this.editor.insertHTML(htmlOrString, notCleaningData, checkCharCount)
  }

  // Called before the image is uploaded.
  // If true is returned, the internal upload process runs normally.
  // If false is returned, no image upload is performed.
  // If new fileList are returned, replaced the previous fileList.
  // If undefined is returned, it waits until "uploadHandler" is executed.
  onImageUploadBefore(files, info, core, uploadHandler) {
    if (typeof FileReader !== 'function') {
      console.warn("You browser doesn't support FileReader for images upload")
      return false
    }

    const fileReader = new FileReader()

    fileReader.readAsDataURL(files[0])
    fileReader.onload = (e) => this.uploadImage(e.target.result, files[0], uploadHandler)

    return undefined
  }

  async uploadImage(file, meta, callback) {
    try {
      const response = await axios.post(`${gon.locale_path}/platform/images`, { image: { file } })
      const data = { result: [
        {
          url: response.data.original,
          name: meta.name,
          size: meta.size
        }
      ]}

      callback(data)
    } catch(e) {
      callback(e.message)
    }
  }
}

export default SuneditorWidget

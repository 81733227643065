class LineItemSerials {
  constructor(lineItem) {
    this.lineItem = lineItem
    this.el = lineItem.el
    this.doc = lineItem.doc
    this.$serialInput = this.el.find('.serials-input')

    this.bindEvents()
  }

  bindEvents() {
    this.el.find('.serials-btn').click(this.openSerialsModal)
  }

  openSerialsModal = (e) => {
    e && e.preventDefault()
    if (!this.lineItem.product) return

    this.doc.openLineItemSerialsModal(this.lineItem)
  }

  fetchSerials() {
    return JSON.parse(this.el.find('.serials-input').val() || '[]')
  }

  resetSerials() {
    const isSerial = this.lineItem.isSerial()
    this.$serialInput.val(null)

    this.el.find('.quantity-box').toggleClass('with-serials', isSerial)
    this.el.find('.quantity-box input.quantity').prop('readonly', isSerial && !this.doc.data.is_order && this.doc.typedoc !== 'payment_invoice')
  }

  updateSerials(serials) {
    this.$serialInput.val(JSON.stringify(serials))
    this.el.find('.quantity').val(serials.length)
    this.el.find('.quantity').trigger('change')
    this.lineItem.parties.setPartySelect({serialNumber: serials[0]})
  }

  selectAllSerials() {
    this.doc.$serialsSelect.find('option').prop('selected', true)
    this.doc.$serialsSelect.trigger('change')
  }

  deselectAllSerials() {
    this.doc.$serialsSelect.find('option').prop('selected', false)
    this.doc.$serialsSelect.trigger('change')
  }
}

export default LineItemSerials

import { debounce, escape, compact } from 'lodash'
import { barcodeScannerOn, barcodeScannerOff, isWeightBarcode, extractCodeFromWeightBarcode } from 'services/barcode'

class LineItemSearch {
  constructor(lineItem) {
    this.lineItem = lineItem
    this.el = lineItem.el
    this.doc = lineItem.doc
    this.$search = this.el.find('.search-field')
    this.$searchBox = this.el.find('.search-results-box')
    this.query = this.$search.val()
    this.title = this.$search.val()
    this.searchItems = []
    this.lazySearch = debounce(this.onSearch, 300)
    this.searching = false
    this.searchRequest = null
    this.bindEvents()
  }

  bindEvents() {
    if (this.doc.isReadonly()) return

    this.$search.keyup(this.lazySearch)
    this.$search.blur(this.onBlurSearch)
    this.$search.click(this.openSearchBoxOnClick)
    this.$search.dblclick(this.lineItem.onEdit)
    barcodeScannerOn(this.$search[0], this.onBarcodeScan, this.onPaste)
  }

  onEnterEditMode() {
    this.query = null
    this.$search
      .val(null)
      .prop('readonly', false)
      .focus()
  }

  onCancelEditMode() {
    this.$search
      .prop('readonly', true)
      .val(this.title)
  }

  onSetLineItem(item) {
    this.title = `${compact([item.sku, item.title]).join(' ')} - ${item.code}`
    this.$search.val(this.title)
    this.$search.tooltip({ placement: 'top' })
    this.$search.data('bs.tooltip').options.title = this.title
    this.$search.prop('readonly', true)
  }

  handleSearchEnter = () => {
    if (this.searching) return
    this.selectFirstSearchItem()
  }

  // private

  onBlurSearch = () => {
    this.closeSearchBox()
    // setTimeout becuase need some time to click "+" button (open product modal)
    // you may remove it, if this button will be visible not only in edit mode
    if (this.lineItem.editMode) setTimeout(() => this.lineItem.cancelEdit(), 300)
  }

  openSearchBoxOnClick = (e) => {
    if (this.el.find('.product-id').val()) return
    this.openSearchBox()
  }

  openSearchBox = () => {
    if (!this.searchItems.length && (!this.query || !this.query.length)) return
    this.$searchBox.show()
  }

  // setTimeout because not possible to click button in dropdown, it's immediately hide
  closeSearchBox = () => {
    setTimeout(() => this.$searchBox.hide(), 300)
  }

  clearSearchResults() {
    this.searchItems = []
    this.$searchBox.find('.search-items').empty()
  }

  onSearch = (e) => {
    if (this.lineItem.productId && !this.lineItem.editMode) return

    const prevQuery = this.query
    let query = e.target.value

    if (isWeightBarcode(query, this.doc.companySettings.weight_barcode_prefix)) {
      query = extractCodeFromWeightBarcode(query)
    }

    this.query = query

    if (query.length && (query !== prevQuery)) {
      this.performSearch(query)
    }
  }

  onBarcodeScan = (value) => {
    this.barcodeScan = true
  }

  onPaste = (value) => {
    this.$search.val(value)
  }

  performSearch(query) {
    this.searching = true

    // Cancel previous search, as it's not relevant anymore
    if (this.searchRequest) this.searchRequest.abort()

    const data = {
      query,
      document_id: this.doc.data.id,
      typedoc: this.doc.data.typedoc,
      typeprice: this.doc.data.typeprice,
      stock_id: this.doc.data.stock_from_id || this.doc.data.stock_to_id,
      modifications_mode: true
    }

    this.searchRequest = $.ajax({
      method: 'GET',
      data,
      url: `${gon.locale_path}/platform/products/search`,
      dataType: 'json',
      success: (response) => this.onSuccessSearch(response, query),
      error(error) {
        console.error(error.status, error.statusText)
        this.searching = false
        this.barcodeScan  = false
      }
    })
  }

  onSuccessSearch = (response, query) => {
    // We should check that results will be related to last query,
    // otherwise on multiple queries last relevant query could be replaced with old one,
    // that took more time to load.
    if (query === this.query) {
      this.searchItems = response.products || []
      this.showSearchResults()

      if (this.barcodeScan) this.selectFirstSearchItem()
    }

    this.searching = false
    this.barcodeScan = false
  }

  showSearchResults() {
    const $searchItems = this.filterSearchResults(this.searchItems)
      .map((item, index) => this.searchItemTemplate(item, index))

    const $container = this.$searchBox.find('.search-items')
    $container.html($searchItems)
    $container.find('.search-item').click(this.onItemSelect)
    this.openSearchBox()
  }

  filterSearchResults(items) {
    // Do not allow insert product with serials into correction_balance document, because it's not supported
    return items.filter(item => !(item.serial_accounting && this.doc.typedoc === 'correction_balance'))
  }

  searchItemTemplate(item, index) {
    const modification = this.lineItem.modifications.matchedModification(item)
    const remains = Number((modification || item).remains || 0)
    const reserve = Number((modification || item).reserve || 0)
    let image = (item.images || {}).micro
    if (modification && (modification.images || {}).micro) image = modification.images.micro

    return `<div class='search-item' data-search-index='${index}'> \
      ${image ? `<img src='${image}' class='search-item-image' />` : ''}
      <div class='title'>
        <strong>${item.sku ? `${item.sku} ` : ''}</strong><span>${escape(item.title)}</span> - <span>${item.code}</span>
        ${modification ? `<div class='modification'>${modification.to_s}</div>` : '' } \
      </div> \
      <div class='search-item-remains'>
        ${remains}
        ${reserve > 0 ? ` / <span class='search-item-reserve'>${reserve}</span>` : ''}
      </div>
    </div>`
  }

  onItemSelect = (e) => {
    const index = $(e.target).closest('.search-item').data('search-index')
    const item = this.searchItems[index]
    this.selectSearchItem(item)
  }

  selectFirstSearchItem() {
    const index = this.$searchBox.find('.search-item').first().data('search-index')
    const item = this.searchItems[index]
    this.selectSearchItem(item)
  }

  selectSearchItem(item) {
    if (!item) return false
    this.lineItem.setLineItem(item)
    this.query = null
    this.editMode = false
    this.$searchBox.hide()

    if (this.doc.scanMode) {
      this.doc.lastLineItem().search.$search.focus()
    } else {
      this.lineItem.navigateToNextField(this.$search)
    }

    return true
  }
}

export default LineItemSearch

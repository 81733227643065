import { round } from 'lodash'
import { fetchNumberInputValue, add, subtract } from 'services/numbers'
import { isTriggeredByElementWithClass } from 'services/events'

class LineItemCurrency {
  constructor(lineItem) {
    this.lineItem = lineItem
    this.el = lineItem.el
    this.doc = lineItem.doc
    this.currencyRate = Number(this.doc.data.currency_rate)
    this.accountingCurrencyRate = Number(this.doc.data.accounting_currency_rate)
  }

  convert(event, typeprice, price, recalculateCurrency) {
    // Applicable only for documents that supports currency,
    // and for any sale doc in order to sync account price with document price
    if (!this.doc.isMulticurrency()) return price
    // User just changed this price right now, so, don't do anything.
    if (this.lineItem.calculator.isPriceChangedManually(event, typeprice)) return price

    if (this.doc.isSupplyContragent()) {
      return this.convertReceipt(event, typeprice, price, recalculateCurrency)
    } else if (this.doc.data.is_sale_all) {
      return this.convertSales(event, typeprice, price, recalculateCurrency)
    } else {
      return price
    }
  }

  // private

  convertReceipt(event, typeprice, price, recalculateCurrency) {
    const priceDocument = fetchNumberInputValue(this.el.find('.price.price-document'))
    const priceSupply = fetchNumberInputValue(this.el.find('.price.price-supply'))
    const amountExpenses = fetchNumberInputValue(this.el.find('.amount-expenses'))
    const isImportSupplyPrice = recalculateCurrency && priceDocument == 0 && priceSupply > 0
    const isImportDocumentPrice = recalculateCurrency && priceDocument > 0 && priceSupply == 0

    if (typeprice === 'accounting') {
      // Make sure that accounting price always set equal to document price
      return priceDocument
    } else if (this.shouldConvertReceiptSupplyPrice(event, typeprice, {
      recalculateCurrency, isImportSupplyPrice, isImportDocumentPrice })) {
      // Supply price should be calculated on document price, becuase this price available for user.
      // User may change document price directly.
      // Accounting price in receipt always hidden so we ignore it, and update it on document price change.
      return round(
        subtract(((priceDocument / this.currencyRate) * this.accountingCurrencyRate), amountExpenses),
        this.doc.moneyRounding()
      )
    } else if (this.shouldConvertReceiptDocumentPrice(event, typeprice, {
      recalculateCurrency, isImportSupplyPrice, isImportDocumentPrice })) {
      // Is it correct? We should add expenses to price and not to amount?
      // - Yes, it's correct. In general, it's complex functionality in all systems.
      // Because, we should also allocate common expenses in document into it's line items,
      // and not only from product expenses.
      // Each product has it's weight and sizes, so delivery of each item require some costs.
      // Even there are some sofware that do only costs allocations.
      return round((add(priceSupply, amountExpenses) * this.currencyRate) / this.accountingCurrencyRate, this.doc.moneyRounding())
    } else {
      return price
    }
  }

  convertSales(event, typeprice, price, recalculateCurrency) {
    const priceDocument = fetchNumberInputValue(this.el.find('.price.price-document'))
    const priceAccounting = fetchNumberInputValue(this.el.find('.price.price-accounting'))
    // handle case when user didn't change price-accounting, but document price is zero.
    // this may happen after import from excel when accouting price provided, but currency price empty.
    const isImportAccountingPrice = recalculateCurrency && priceDocument == 0 && priceAccounting > 0
    // Handle case when user didn't change price-document, but accounting price is zero.
    // This may happen after import from excel when currency price provided, but accounting price empty.
    const isImportDocumentPrice = recalculateCurrency && priceDocument > 0 && priceAccounting == 0

    if (this.shouldConvertSalesAccountingPrice(event, typeprice, {
      recalculateCurrency, isImportAccountingPrice, isImportDocumentPrice })) {
      return round((priceDocument * this.currencyRate) / this.accountingCurrencyRate, this.doc.moneyRounding())
    } else if (this.shouldConvertSalesDocumentPrice(event, typeprice, {
      recalculateCurrency, isImportAccountingPrice, isImportDocumentPrice })) {
      return round((priceAccounting / this.currencyRate) * this.accountingCurrencyRate, this.doc.moneyRounding())
    } else {
      return price
    }
  }

  shouldConvertSalesAccountingPrice(event, typeprice, { recalculateCurrency, isImportAccountingPrice, isImportDocumentPrice } = {}) {
    if (typeprice !== 'accounting' || isImportAccountingPrice) return false

    return (recalculateCurrency && !this.isStandardPriceCalculation()) ||
      // Always recalculate accounting price on document price change
      this.lineItem.calculator.isPriceChangedManually(event, 'document') ||
      // Price document imported from excel, it's same as user manually changed document price
      isImportDocumentPrice
  }

  shouldConvertSalesDocumentPrice(event, typeprice, { recalculateCurrency, isImportAccountingPrice, isImportDocumentPrice } ={}) {
    if (typeprice !== 'document' || isImportDocumentPrice) return false

    return (recalculateCurrency && this.isStandardPriceCalculation()) ||
      // Always recalculate document price on accounting price change
      this.lineItem.calculator.isPriceChangedManually(event, 'accounting') ||
      // Price accounting imported from excel, it's same as user manually changed accounting price
      isImportAccountingPrice
  }

  shouldConvertReceiptSupplyPrice(event, typeprice, { recalculateCurrency, isImportSupplyPrice, isImportDocumentPrice } = {}) {
    // Price supply should be calculated regardless of supplier_price_recalculation value
    if (typeprice !== 'supply' || isImportSupplyPrice) return false

    return (recalculateCurrency && this.isStandardPriceCalculation()) ||
      // Always recalculate supply price on document price change
      this.lineItem.calculator.isPriceChangedManually(event, 'document') ||
      isImportDocumentPrice
  }

  shouldConvertReceiptDocumentPrice(event, typeprice, { recalculateCurrency, isImportSupplyPrice, isImportDocumentPrice } = {}) {
    if (typeprice !== 'document' || !this.doc.data.supplier_price_recalculation || isImportDocumentPrice) return false

    return (recalculateCurrency && !this.isStandardPriceCalculation()) ||
      // Always recalculate document price on supply price or expenses amount change
      this.lineItem.calculator.isPriceChangedManually(event, 'supply') ||
      isTriggeredByElementWithClass(event, 'amount-expenses') ||
      isImportSupplyPrice
  }

  // Standard price calculation for sales:
  // Calculate document price based on accounting price. So, initially set price_accounting from product card,
  // and then calculate document price based on currency rate.
  //
  // Non-standard price calculation for sale:
  // Reverse calculation, first set document price because it's currency price from product card.
  // And then calculate accounting price based on currency rate.
  //
  // Standard price calculation for supply:
  // Calculate supply price based on document price. Initially set document price from product card,
  // and then calculate price_supply based on currency rate.
  //
  // Non-standard price calculation for supply:
  // Reverse calculation, first set supply price from product card and then calculate document price.
  isStandardPriceCalculation() {
    return !this.doc.data.custom_price_in_currency
  }
}

export default LineItemCurrency

import BaseWidget from './base_widget'
import Sortable from 'sortablejs'
import axios from 'axios'

class ImagesUpload extends BaseWidget {
  render() {
    this.setParentClass()
    if (this.el.data('multi')) {
      this.sortable = new Sortable(this.el.find('.images-container')[0], {
        draggable: '.image-item',
        fallbackOnBody: true,
        animation: 500,
        onEnd: this.onPositionChange.bind(this)
      })
    }
  }

  async onPositionChange(event) {
    const $image = $(event.item)
    const ord = event.newDraggableIndex
    const imageId = $image.data('image-id')

    if (!imageId) return this.revertPositionChange(event)

    try {
      const url = `${gon.locale_path}/platform/images/${imageId}`
      const data = { model: this.el.data('model'), image: { ord_position: ord }}

      const response = await axios.put(url, data)
      if (response.data.error) this.revertPositionChange(event)
    } catch (error) {
      this.revertPositionChange(event)
      console.error(error)
    }
  }

  revertPositionChange(event) {
    const tagName = event.item.tagName
    const items = this.el.find('.images-container .image-item')

    if (event.oldDraggableIndex > event.newDraggableIndex) {
      event.from.insertBefore(event.item, items[event.oldDraggableIndex + 1])
    } else {
      event.from.insertBefore(event.item, items[event.oldDraggableIndex])
    }
  }

  setParentClass() {
    this.el.toggleClass('no-images', !this.el.find('.image-item:not(.empty-image-item)').length)
  }

  bindEvents() {
    this.el.find('.upload-icon').click(this.onUploadClick.bind(this))
    this.el.find('.upload-input').change(this.onImagesUpload.bind(this))
    this.el.find('.image-item .destroy-link').click(this.onDestroy.bind(this))
  }

  onUploadClick(e) {
    e.preventDefault()
    this.el.find('.upload-input').click()
  }

  onImagesUpload(e) {
    const files = e.target.files
    this.el.find('.image-item.not-saved').each(function() {
      $(this).remove()
    })

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = this.afterImageUpload.bind(this)
      reader.readAsDataURL(files[i])
    }
    this.setParentClass()
  }

  afterImageUpload(e) {
    const item = `<div class='image-item not-saved' style='background-image: url(${e.target.result})'></div>`
    if (this.el.data('multi')) {
      this.el.find('.images-container').append(item)
    } else {
      this.el.find('.images-container .image-item').remove()
      this.el.find('.images-container').append(item)
    }
    this.setParentClass()
  }

  onDestroy(e) {
    e.preventDefault()
    e.stopPropagation()
    const $image = $(e.target).closest('.image-item')
    const imageId = $image.data('image-id')
    if (!imageId) return
    $.ajax({
      method: 'DELETE',
      url: `${gon.locale_path}/platform/images/${imageId}`,
      data: { model: this.el.data('model') },
      dataType: 'json',
      success: () => {
        $image.hide('slow')
        this.setParentClass()
      },
      error(error) {
        console.error(error.status, error.statusText)
      }
    })
  }

}

export default ImagesUpload

import BaseWidget from './base_widget'
import { round } from 'lodash'

class TariffsWidget extends BaseWidget {

  initialize() {
    this.discount = Number(this.el.data('discount') || 0)
  }

  render() {
    this.el.find('form.tariff-item').each((_, form) => this.calculate($(form)))
  }

  bindEvents() {
    this.el.find('input').change(this.handleChange.bind(this))
    this.el.find('input').keypress(this.handleChange.bind(this))
  }

  handleChange(e) {
    const $form = $(e.target).closest('form')
    this.calculate($form)
  }

  calculate($form) {
    const $inputs = []
    $form.find('input').each((_, el) => $inputs.push($(el)))

    const amount = $inputs.reduce((acc, $input) => {
      const inputType = $input.prop('type')
      const price = $input.data('price')
      const min = Number($input.attr('min') || 0)
      let value

      if (inputType === 'checkbox') {
        value = $input.prop('checked') ? 1 : 0
      } else if (inputType === 'number') {
        value = min > 0 ? Number($input.val() || min) - min : Number($input.val())
      }

      if (value < 0) value = 0

      if (value && price) {
        acc = acc + (price * value)
      }
      return acc
    }, 0)

    const $totalPrice = $form.find('.total-price')
    const $totalPriceWithoutDiscount = $form.find('.total-price-without-discount')
    const totalWithoutDiscount = Number($totalPrice.data('base')) + amount
    const total = round(totalWithoutDiscount - (totalWithoutDiscount * this.discount / 100))

    if (this.discount > 0) {
      $totalPriceWithoutDiscount.text(totalWithoutDiscount)
    }

    $totalPrice.val(total.toFixed(0))
  }
}

export default TariffsWidget

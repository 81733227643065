// NOT USED
import BaseWidget from './base_widget'

const OPTIONS = {
}

class Datepicker extends BaseWidget {
  render() {
    const d = $('#12').data('datepicker')
    console.log(d)
    // this.el.datepicker({})
  }
}

export default Datepicker

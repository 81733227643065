import Sortable from 'sortablejs'
import axios from 'axios'
import { errorMessage, showAlert } from 'services/errors'
import bootbox from 'bootbox'

class Kanban {
  constructor(el) {
    this.el = el
    this.reload()
    this.bindEvents()
  }

  reload() {
    this.stages = {}
    this.el.find('.kanban-stage').each((index, stage) => {
      this.stages[index] = new Sortable($(stage).find('.kanban-items')[0], {
        group: 'kanban-stage',
        draggable: '.kanban-item',
        fallbackOnBody: true,
        animation: 500
      })
    })
  }

  bindEvents() {
  }
}

export default Kanban

export function isTriggeredByElementWithClass(event, ...classNames) {
  if (!event) return false

  const names = Array.isArray(classNames) ? classNames : [classNames]
  const targetClassName = event.target.className

  if (!targetClassName) return false

  return names.some(c => targetClassName.includes(c))
}

import Rails from '@rails/ujs'
import BaseWidget from './base_widget'
import axios from 'axios'
import { keyBy } from 'lodash'
import qs from 'qs'
import { showAlert } from 'services/errors'
import Bootbox from 'bootbox'
import { connectWidgets } from 'main/widgets/helpers'
import DocumentPay from 'main/document/pay'

class StatusWidget extends BaseWidget {
  STATES_WITH_TARGET_DOCUMENTS = ['generated', 'partially_completed', 'completed']
  COMPLETED = ['completed']
  PLACEMENTS = ['document', 'table', 'kanban']

  initialize() {
    this.$select = this.el.find('.status-select')
    this.$loader = this.el.find('.loader-box')
    this.placement = this.el.data('placement')
    this.statuses = keyBy(this.el.data('statuses'), 'id')
    this.doc = this.el.data('document')
    this.form = this.el
    this.currentStatus = this.statuses[this.$select.val()]
  }

  bindEvents() {
    this.$select.change(this.onChange.bind(this))
  }

  onChange(e) {
    e.preventDefault()

    if (!this.PLACEMENTS.includes(this.placement)) {
      console.error('Unxpected status placement')
      return
    }

    const statusId = $(e.target).val()

    if ((statusId || null) === ((this.currentStatus || {}).id || null)) return
    if (this.willDestroyTargets(statusId)) return this.askDestroyConfirmation(statusId)
    if (this.isRequirePayment(statusId)) return this.processTargetDocumentPay(statusId)

    this.submit(statusId)
  }

  submit(statusId, paymentData) {
    if (this.isInsideDocument()) {
      this.ajaxSubmit(statusId, paymentData)
    } else {
      this.directSubmit(statusId, paymentData)
    }
  }

  async directSubmit(statusId, paymentData) {
    this.$loader.show()

    try {
      const response = await axios.put(`${this.submitUrl()}.json`, this.submitParams({paymentData}))
      if (response.data.error) return this.handleError(response.data.error)
      this.afterSuccessStatusChange(statusId, response.data)
    } catch (e) {
      console.error(e)
      const message = e.response && e.response.data.error
      this.handleError(message)
    } finally {
      this.$loader.hide()
    }
  }

  // By making ajax request document page will be refreshed after success status change
  ajaxSubmit(statusId, paymentData) {
    this.$loader.show()

    Rails.ajax({
      type: 'PUT',
      url: `${this.submitUrl()}.js?`,
      data: this.submitParams({formData: true, paymentData}),
      error: (data, error, xhr) => {
        // 422 is correct error response handled via global ajax
        if (xhr.status !== 422) {
          this.handleError(window.I18n.t('errors.unexpected_error'))
        } else {
          this.setPreviousStatus()
        }
      },
      success: (data) => this.afterSuccessStatusChange(statusId, data),
      complete: () => this.$loader.hide()
    })
  }

  afterSuccessStatusChange(statusId, data) {
    this.setStatus(statusId)

    switch (this.placement) {
      case 'document':
        return // nothing to do, ajax will handle everything
      case 'table':
        const $row = this.el.closest('tr')
        $row.html($(data).html())
        connectWidgets($row)
        return
      case 'kanban':
        return
    }
  }

  isRequirePayment(statusId) {
    if (!statusId) return false

    return this.COMPLETED.includes(this.statuses[statusId].state) &&
      !this.COMPLETED.includes(this.currentStatus.state)
  }

  async processTargetDocumentPay(statusId) {
    try {
      const response = await axios.get(`${gon.locale_path}/platform/documents/${this.doc.id}/document_pay`)
      if (response.data.error) return this.handleError(response.data.error)
      if (response.data.payment_done) return this.submit(statusId)

      const $targetPayModal = Bootbox.dialog({
        message: response.data,
        title: window.I18n.t(`statuses.target_document_${this.doc.typedoc}_pay_title`),
        onEscape: false, // We should set previous status
        className: 'document-pay-modal target-document-pay',
        buttons: []
      })

      this.bindTargetDocumentPayEvents($targetPayModal, statusId)
    } catch (e) {
      console.error(e)
      const message = e.response && e.response.data.error
      this.handleError(message)
    }
  }

  bindTargetDocumentPayEvents($targetPayModal, statusId) {
    connectWidgets($targetPayModal)
    new DocumentPay($targetPayModal, {
      payableAmount: () => Number($targetPayModal.find('.document-pay-form').data('target-to-pay') || 0)
    })

    $targetPayModal.find('.cancel-btn, .modal-header .close').click(this.setPreviousStatus)

    $targetPayModal.find('form').submit((e) => {
      e.preventDefault()
      e.stopPropagation()
      const paymentData = qs.parse($targetPayModal.find('form').serialize()).document
      $targetPayModal.modal('hide')
      this.submit(statusId, paymentData)
    })
  }

  askDestroyConfirmation(statusId) {
    if (!statusId) return

    Bootbox.dialog({
      message: window.I18n.t(`statuses.confirm_destroy_${this.doc.typedoc}_target_documents`),
      title: window.I18n.t('errors.attention'),
      onEscape: false, // We should set previous status
      buttons: {
        confirm: {
          label: window.I18n.t('buttons.confirm'),
          className: 'btn btn-danger',
          callback: () => this.submit(statusId),
        },
        cancel: {
          label: window.I18n.t('buttons.cancel'),
          className: 'btn btn-default',
          callback: () => this.setPreviousStatus()
        }
      }
    })
  }

  willDestroyTargets(statusId) {
    if (!statusId || !this.currentStatus) return false

    return this.STATES_WITH_TARGET_DOCUMENTS.includes(this.currentStatus.state) &&
      !this.STATES_WITH_TARGET_DOCUMENTS.includes(this.statuses[statusId].state)
  }

  handleError = (message) => {
    this.setPreviousStatus()

    showAlert(message, {
      title: window.I18n.t('statuses.status_change_failed'),
      className: 'alert alert-danger no-margin'
    })
  }

  setPreviousStatus = () => {
    this.$select.val((this.currentStatus || {}).id || null).trigger('change')
  }

  setStatus(statusId) {
    this.currentStatus = this.statuses[statusId]
  }

  isInsideDocument() {
    return this.placement === 'document'
  }

  isInsideTableRow() {
    return this.placement === 'table'
  }

  isInsideKanban() {
    return this.placement === 'kanban'
  }

  submitUrl() {
    return `${gon.locale_path}/platform/documents/${this.doc.id}/change_status`
  }

  submitParams({formData = false, paymentData = {}} = {}) {
    const params = {
      placement: this.placement,
      status_id: this.$select.val(),
    }

    if (!formData) return { ...params, pay_attributes: paymentData }

    const data = new FormData()
    const { parent_binds_attributes = [], ...payment  } = paymentData

    Object.keys(params).forEach(key => data.append(key, params[key]))
    Object.keys(payment).forEach(key => {
      if (payment[key]) {
        data.append(`pay_attributes[${key}]`, payment[key])
      }
    })
    parent_binds_attributes.forEach((bind, index) => {
      Object.keys(bind).forEach(key => {
        data.append(`pay_attributes[parent_binds_attributes][${index}][${key}]`, bind[key])
      })
    })
    return data
  }
}

export default StatusWidget

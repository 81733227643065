import BaseWidget from './base_widget'
import Print from 'services/print'

class PrintWidget extends BaseWidget {
  initialize() {
    this.print = new Print(this)
  }

  bindEvents() {
    this.el.click(this.print.call.bind(this))
  }

  skipPrint() {
    return false
  }

  beforePrint() {}

  url() {
    return this.el.prop('href')
  }

  params() {
    return {}
  }
}

export default PrintWidget

import axios from 'axios'
import { errorMessage, showAlert } from 'services/errors'
import bootbox from 'bootbox'

class Subscriptions {
  constructor(el) {
    this.el = el
    this.bindEvents()
  }

  bindEvents() {
    this.el.find('form.tariff-item').submit(this.onCreateSubscription)
    this.el.find('.fetch-invoice-btn').click(this.onFetchInvoice)
    this.el.find('.renew-btn').click(this.onRenewSubscription)
  }

  onFetchInvoice = (e) => {
    e.preventDefault()

    const $button = $(e.currentTarget)
    const url = $button.attr('href')
    const method = $button.data('method')
    $button.prop('disabled', true)

    this.performRequest($button, url, method)
  }

  onRenewSubscription = (e) => {
    e.preventDefault()

    const $button = $(e.currentTarget)
    const url = $button.attr('href')
    const method = $button.data('method')
    $button.prop('disabled', true)

    this.performRequest($button, url, method)
  }

  onCreateSubscription = (e) => {
    e.preventDefault()

    const form = $(e.target)
    const $button = form.find('.submit-tariff')
    $button.prop('disabled', true)
    const method = form.attr('method')
    const url = `${form.attr('action')}.json`
    const data = new FormData(form[0])

    this.performRequest($button, url, method, data)
  }

  async performRequest($button, url, method, data) {
    try {
      const response = await axios[method](url, data)

      if (response.data.error) {
        $button.prop('disabled', false)
        return showAlert(response.data.error)
      }

      $button.prop('disabled', false)
      this.processResponse(response.data)
    } catch (e) {
      const message = e.response && e.response.data.error
      $button.prop('disabled', false)
      showAlert(message, { error: e })
    }
  }

  processResponse(data) {
    let title
    let body
    let buttons
    const callback = () => window.location.href = data.redirect_url

    if (data.invoice) {
      title = data.invoice.description
      body = this.invoicePreviewTemplate(data.invoice)
      buttons = {
        confirm: {
          label: window.I18n.t('buttons.pay'),
          className: 'btn-success',
          callback
        },
        cancel: {
          label: window.I18n.t('buttons.cancel'),
          className: 'btn-default',
        }
      }
    } else {
      title = data.title
      body = `<strong>${data.message}</strong>`
      buttons = {
        confirm: {
          label: window.I18n.t('buttons.continue'),
          className: 'btn-success',
          callback
        }
      }
    }

    this.$modal = bootbox.dialog({
      title,
      message: body,
      onEscape: false,
      backdrop: null,
      animate: true,
      className: 'subscription-preview-modal',
      buttons
    })
  }

  invoicePreviewTemplate(invoice) {
    const tariff = invoice.tariff

    return `
      <div class='row margin-top-10'>
        <div class='col-xs-12'>
          <p>
            ${window.I18n.t('subscriptions.tariff')} - <strong>${window.I18n.t(`tariffs.${tariff.title}.title`)}</strong>.
            ${window.I18n.t('subscriptions.max_users')} - <strong>${tariff.users_count || 0}</strong>.
            ${window.I18n.t('subscriptions.max_pos')} - <strong>${tariff.computers_count || 0}</strong>.
            ${window.I18n.t('subscriptions.max_shopwires')} - <strong>${tariff.shopwires_count || 0}</strong>.
            ${window.I18n.t('subscriptions.max_filials')} - <strong>${tariff.filials_count || 0}</strong>.
            </p>
            <p>
            ${window.I18n.t('subscriptions.active_features')} - ${this.tariffFeatures(tariff)}.
          </p>
          ${invoice.personal_discount > 0 ? `<p class='info-color'><strong>${window.I18n.t('subscriptions.personal_discount')} - ${invoice.personal_discount}%</strong></p>` : ''}
          ${invoice.paid_by_deposit > 0 ? `<p style='max-width: 80%'>${window.I18n.t('subscriptions.deposit_to_be_used')} - <strong class='success-color'>${invoice.paid_by_deposit} ${invoice.currency}</strong>.</p>` : ''}
        </div>
      </div>
      <div class='row margin-top-10'>
        <div class='col-xs-12'>
          <div class='metric metric-warning'>
            <label class='title'>${window.I18n.t('subscriptions.amount_to_pay')}</label>
            <div class='value'>${invoice.paid_by_payout} ${invoice.currency}</div>
          </div>
        </div>
      </div>
    `
  }

  tariffFeatures(tariff) {
    let features = tariff.features || []

    if (tariff.title === 'company') {
      features = ['all_features']
    } else if (!features.length) {
      features = ['no_features']
    }

    return features.map(feature => {
      return `<span>${window.I18n.t(`subscriptions.${feature}`)}</span>`
    }).join(', ')
  }
}

export default Subscriptions

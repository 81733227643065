import { round, floor } from 'lodash'
import Big from 'big.js'

// You should always use these methods to add and substruct, instead of direct calculation
// https://dev.to/damxipo/handle-money-with-js-4a13
export function add(n1, n2) {
  return ((new Big(n1 || 0)).plus(new Big(n2 || 0))).toNumber()
}

export function subtract(n1, n2) {
  return ((new Big(n1 || 0)).minus(new Big(n2 || 0))).toNumber()
}

export function multiply(n1, n2) {
  return ((new Big(n1 || 0)).times(new Big(n2 || 0))).toNumber()
}

export function divide(n1, n2) {
  return ((new Big(n1 || 0)).div(new Big(n2 || 0))).toNumber()
}

// Use it to avoid broken calculation like 0.1 + 0.2 = 0.30000000000000004
// Example of usage:
// safeSum([2, 2, 2]) => 6
// safeSum([{a: 2}, {a: 2}, {a: 2}], 'a') => 6
// safeSum([{a: 2}, {a: 2}, {a: 2}], (item) =>  item.a) => 6
export function safeSum(items, iterator = null) {
  return (items || []).reduce((acc, current) => {
    let value = current

    if (typeof iterator === 'string') {
      value = current[iterator]
    } else if (typeof iterator === 'function') {
      value = iterator(current)
    }

    return add(acc, value)
  }, 0)
}

export function discountedAmount(amount, percent, rounding) {
  const prc = Number(percent || 0)
  let discount = 0

  if (prc > 0) discount = round(divide(multiply(amount, prc), 100), rounding)

  amount = subtract(amount, discount)

  return round(amount, rounding)
}

// Return amount rounding, and not rounded amount. So, for 1.32 it will return -0.32
export function totalAmountRounding(initialAmount, roundingMethod, precision) {
  if (roundingMethod === 'to_nbu') return nbuRounding(initialAmount)

  return mathRounding(initialAmount, precision)
}

export function mathRounding(initialAmount, precision) {
  let amount = Number(initialAmount || 0)
  const roundedAmount = round(amount, precision)
  const diff = subtract(roundedAmount, amount)

  return round(diff, 4)
}

// According to National Bank of Ukraine (NBU) amount should be rounded,
// if no possibility to make payment for amount because there is absent of small coins,
// like 1, 2, 5, 25 cents.
// https://news.dtkt.ua/debet-kredit/partner-news/48961
// Here we round such amount
// Як саме заокруглюватиметься сума розрахунку до 0?
// Заокруглення відбуватиметься за математичним правилом.
// Якщо сума розрахунку закінчується на 1, 2, 3, 4 копійки – вона заокруглюється до 0
// у бік зменшення суми. Якщо сума розрахунку закінчується
// на 5, 6, 7, 8, 9 копійок – вона заокруглюється до 0 у бік збільшення суми.
export function nbuRounding(initialAmount) {
  let amount = Number(initialAmount || 0)
  const cents = round(multiply(amount, 100) % 10)
  let amountRounding = 0

  // No rounding needed, just return amount
  if (!cents) return amountRounding

  if (cents < 5) {
    amountRounding = - divide(cents, 100)
  } else {
    amountRounding = divide(subtract(10, cents), 100)
  }

  amountRounding = round(amountRounding, 4)

  return amountRounding
}

// NOTE: This method doesn't handle thousands separators
// Accepts string or number and return number
export function toRealNumber(string, { brutal = false } = {}) {
  if (!string) return 0
  let number = string

  if (typeof string !== 'number') {
    string = string.replace(/[^\d,.-]/g, '') // strip everything except numbers, dots, commas and negative sign
    string = string.replace(/,/g, '.') // replace comma with dot
    if (brutal) string = brutalConvertStringToNumber(string)
    number = parseFloat(string)
  }

  // handle (195 * 0.205 = 39.974999999999994) or 4.99999999999955 cases,
  // it should be '39.9750000000000', and not '39.974999939999994'
  return Number(number.toFixed(10))
}

function brutalConvertStringToNumber(string) {
  // if not in German locale and matches #,###.######
  if (navigator.language.substring(0, 2) !== "de" && /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/.test(string)) {
    string = string.replace(/,/g, '') // strip out commas
    return parseFloat(string)
  // either in German locale or not match #,###.###### and now matches #.###,########
  } else if (/^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:,\d+)?$/.test(string)) {
    string = string.replace(/\./g, '') // strip out dots
    string = string.replace(/,/g, '.') // replace comma with dot
    return parseFloat(string)
  // try #,###.###### anyway
  } else {
    string = string.replace(/,/g, '') // strip out commas
    return parseFloat(string) // convert to number
  }
}

export function fetchNumberInputValue($input) {
  return Number($input.val() || 0)
}

export function formattedQuantity(field, { zero = '-', pretty =  false } = {}) {
  const quantity = Number(field || 0)

  if (quantity === 0) return zero

  const rounded = floor(quantity, 3)

  if (!pretty || quantity === rounded) return quantity

  return `
    <span class='pretty-quantity'>
      <span class='quantity-rounded'>${rounded}</span>
      <button class='btn btn-default btn-icon show-real-quantity-btn'>
        <i class='fas fa-caret-right'></i>
      </button>
      <span class='quantity-real'>${quantity}</span>
    </span>
  `
}

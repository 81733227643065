import BaseWidget from './base_widget'
import axios from 'axios'

// Responsible for showing right fields for different letter kinds and provider channel.
// Able to insert variables into letter content and appliying master template.
class LetterWidget extends BaseWidget {
  initialize() {
    this.providers = this.el.data('providers') || []
    this.templates = this.el.data('templates') || []
    this.kind = this.el.data('kind')
  }

  render() {
    if (!['email', 'sms'].includes(this.kind)) return

    this.reload()
  }

  reload() {
    this.setProvider()
    this.toggleFields()
  }

  bindEvents() {
    this.el.find('.providers-select').change(this.reload.bind(this))
    this.el.find('.templates-select').change(this.applyTemplate.bind(this))
    this.el.find('.template-variables-select').change(this.insertVariable.bind(this))
    this.el.find('.images-container .upload-input').change(this.onImageUpload.bind(this))
  }

  toggleFields() {
    if (this.kind === 'sms') {
      const channel = this.provider ? this.provider.fields.channel || '' : ''
      const isMessenger = channel.includes('viber') || channel.includes('rcs')

      this.el.find('.letter-body').toggle(!channel || channel.includes('sms'))
      this.el.find('.letter-messenger').toggle(isMessenger)
      this.el.find('.letter-attachments').toggle(isMessenger)
    }
  }

  insertVariable(e) {
    const $select = $(e.target)
    const variable = $select.val()

    if (!variable) return

    $select.val(null)

    const $editor = $select.closest('.letter-content').find('textarea')
    const cursor = $editor.prop('selectionStart')
    const value = $editor.val() || ''
    const before = value.substring(0,  cursor)
    const after  = value.substring(cursor, value.length)

    $editor.val(before + variable + after)
  }

  applyTemplate(e) {
    const templateId = this.el.find('.templates-select').val()
    const template = this.templates.find(item => item.id == templateId)

    if (!template) return

    const content = template.content

    this.el.find('.letter-button-input').val(content.button)
    this.el.find('.letter-link-input').val(content.link)
    this.el.find('.letter-subject-input').val(content.subject)
    this.el.find('.letter-body-editor').val(content.body)
    this.el.find('.letter-messenger-editor').val(content.messenger)

    if (!template.image_url) return

    // Replace clear image file_tag and add image url into remote_file_url
    // This code ensure that manual uploading functionality still works.
    const $image = this.el.find('.letter-attachments .images-container .image-item')
    $image.closest('.images-upload').removeClass('no-images')
    $image.removeClass('empty-image-item')
    $image.css('background-image', `url(${template.image_url})`)
    this.el.find('.remote-image-file-url').val(template.image_url)
    this.el.find('.images-container .upload-input').val([])
  }

  // If user manuall upload image clear image remote_file_url to avoid conflicts
  onImageUpload(e) {
    const files = e.target.files
    if (!files.length) return

    this.el.find('.remote-image-file-url').val(null)
  }

  setProvider() {
    const providerId = this.el.find('.providers-select').val()
    this.provider = this.providers.find(item => item.id == providerId)
  }
}

export default LetterWidget

import Rails from '@rails/ujs'
Rails.start()
import 'bootstrap'
import 'select2'
import 'chartkick/chart.js'
import I18n from 'i18n-js'
window.I18n = I18n

import './global'
import './widgets'
import './pages'
import './document'
import './content'
import './shop'

import BaseWidget from './base_widget'
import telInput from 'intl-tel-input'
import { lowerCase } from 'lodash'

class PhoneWidget extends BaseWidget {
  render() {
    this.$phone = $(`${this.el.data('phone-field')}`)
    this.phone = this.$phone.val()
    this.$country = $(`${this.el.data('country-field')}`)
    this.input = telInput(this.el[0], {
      onlyCountries: [
        'al', 'ad', 'at', 'be', 'ba', 'bg', 'hr', 'z', 'dk', 'cz',
        'ee', 'fo', 'fi', 'fr', 'de', 'gi', 'gr', 'va', 'hu', 'is', 'ie', 'it', 'lv',
        'li', 'lt', 'lu', 'mk', 'mt', 'md', 'mc', 'me', 'nl', 'no', 'pl', 'pt', 'ro',
        'sm', 'rs', 'sk', 'si', 'es', 'se', 'ch', 'ua', 'gb', 'us', 'mx',
        'tr', 'ge', 'am', 'kg', 'kz', 'tm', 'tj', 'uz',
        'au', 'az', 'br', 'de', 'gl', 'ge', 'il', 'in', 'ca', 'cy', 'id', 'is', 'my', 'nz', 'ae',
        'sg', 'ph', 'lk', 'jp'
      ],
      initialCountry: lowerCase(this.el.data('country') || 'ua'),
      preferredCountries: ['ua', 'pl', 'cz', 'de', 'es', 'lv', 'lt', 'ee'],
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js'
    })

    if (this.phone) this.input.setNumber(this.phone)
  }

  bindEvents() {
    this.el.on('keyup, change', this.handleChange.bind(this))
    this.el.on('countrychange', this.handleChange.bind(this))
  }

  // Use it to change number outside widget
  changeNumber = (value) => {
    this.input.setNumber(value)
    this.change(value)
  }

  handleChange() {
    if (!this.$phone.length) return

    this.change(this.input.getNumber())
  }

  change(phone) {
    this.phone = phone

    if (!this.input.isValidNumber()) {
      this.$phone.val(null)
      this.$phone.closest('.form-group').addClass('has-error')
      return
    }

    this.$phone.closest('.form-group').removeClass('has-error')
    this.$phone.closest('.form-group').addClass('has-success')

    const country = ((this.input.getSelectedCountryData() || {}).iso2 || '').toUpperCase() || null

    this.$phone.val(this.phone)
    this.$country.val(country)
  }
}

export default PhoneWidget

import BaseWidget from './base_widget'
import Bootbox from 'bootbox'
import axios from 'axios'
import { connectWidgets } from 'main/widgets/helpers'
import { showAlert } from 'services/errors'
import { submitForm } from 'services/form'

class SpellWidget extends BaseWidget {
  initialize() {
    this.spell = this.el.data('spell')
    this.form = this.el.find('.spell-form')
    // this.$conditions = this.el.find('.spell-conditions')
    // this.conditions = []
  }

  render() {
    this.reload()
  }

  async reload() {
    this.unmount()
    // this.renderConditions()
  }

  unmount() {
    // this.$conditions.empty()
  }

  bindEvents() {
    this.el.find('.open-letter-template-btn').click(this.onOpenLetterTemplate.bind(this))
    this.el.find('.event-model-select, .event-column-select').change(this.onEventChange.bind(this))
    this.el.find('.action-type-select').change(this.submit.bind(this))
  }

  submit(e) {
    submitForm(this.form)
  }

  onEventChange(e) {
    this.el.find('.event-change-select').val(null)
    submitForm(this.form)
  }

  async onOpenLetterTemplate(e) {
    e.preventDefault()
    const $action = $(e.target).closest('.spell-action')
    const letterId = $(e.currentTarget).data('letter-id')

    try {
      const response = await axios.get(
        `${gon.locale_path}/platform/settings/spells/${this.spell.id}/letter_template`,
        {params: { letter_id: letterId }}
      )
      if (response.data.error) return this.handleError(response.data.error)

      const $letterModal = Bootbox.dialog({
        message: response.data,
        title: window.I18n.t('letters.letter_modal_title'),
        onEscape: true,
        backdrop: true,
        className: 'letter-modal',
        buttons: []
      })

      connectWidgets($letterModal)
      $letterModal.find('form').submit((e) => this.submitLetterTemplate(e, $letterModal, $action))
    } catch (e) {
      console.error(e)
      const message = e.response && e.response.data.error
      this.handleError(message)
    }
  }

  async submitLetterTemplate(e, $letterModal, $action) {
    e.preventDefault()
    e.stopPropagation()

    $letterModal.find('.submit-btn').prop('disabled', true)
    $letterModal.find('.alert').empty().hide()

    try {
      const form = $letterModal.find('form')
      const method = form.attr('method')
      const url = `${form.attr('action')}.json`
      const data = new FormData(form[0])
      data.append('letter[spell_mockup]', 1)
      data.append('draft', true)

      const response = await axios[method](url, data)
      if (response.data.error) return this.handleError(response.data.error, { modal: $letterModal })

      $action.find('.letter-id-input').val(response.data.id)
      $letterModal.find('.submit-btn').prop('disabled', false)
      $letterModal.modal('hide')
      this.submit()
    } catch (e) {
      const message = e.response && e.response.data.error
      this.handleError(message, { modal: $letterModal })
      console.error(e)
    }
  }

  handleError = (message, { modal = false } = {}) => {
    if (!modal) showAlert(message, { className: 'alert alert-danger no-margin' })

    modal.find('.submit-btn').prop('disabled', false)
    modal.find('.alert').text(message).show()
    // Scroll to the begining of modal
    modal.find('.modal-content')[0].scrollIntoView()
  }


  // TODO: Implement conditions

  // const sampleConditions = [
  //   {
  //     items: [
  //       {
  //         model: 'document',
  //         column: 'status',
  //         sign: '==',
  //         value: 'a7106122-f225-11ec-9119-8ebad84f9396',
  //         operator: 'and'
  //       },
  //       {
  //         model: 'document',
  //         column: 'status',
  //         sign: '!=',
  //         value: 'a71383d4-f225-11ec-9119-8ebad84f9396',
  //         operator: 'and'
  //       }
  //     ],
  //     operator: 'or'
  //   },
  //   {
  //     items: [
  //       {
  //         model: 'delivery',
  //         column: 'status',
  //         sign: '==',
  //         value: 'transport',
  //         operator: 'and'
  //       },
  //       {
  //         model: 'delivery',
  //         column: 'status',
  //         sign: '!=',
  //         value: 'completed',
  //         operator: 'and'
  //       }
  //     ],
  //     operator: 'or'
  //   },
  // ]

  // renderConditions() {
  //   const conditionsHtml = this.conditions.map((group, index) => {
  //     const $group = $(this.conditionGroupTemplate(group, index))
  //     this.bindConditionGroupEvents($group)
  //     return $group
  //   })
  //   this.$conditions.html(conditionsHtml)
  // }
  //
  // bindConditionGroupEvents($group) {
  //   $group.find('.toggle-group-operator-btn').click(this.onToggleGroupOperator.bind(this))
  //   $group.find('.toggle-item-operator-btn').click(this.onToggleItemOperator.bind(this))
  //   $group.find('.add-condition-group-btn').click(this.onAddConditionGroup.bind(this))
  //   $group.find('.add-condition-item-btn').click(this.onOpenConditionItemModal.bind(this))
  // }
  //
  // onToggleGroupOperator(e) {
  //   e.preventDefault()
  //   const $group = $(e.target).closest('.condition-group')
  // }
  //
  // onToggleItemOperator(e) {
  //   e.preventDefault()
  //   const $group = $(e.target).closest('.condition-group')
  //   const $item = $(e.target).closest('.condition-item')
  // }
  //
  // onAddConditionGroup(e) {
  //   e.preventDefault()
  //
  //   const index = this.$conditions.find('.condition-group').last().data('index')
  //   const $group = $(this.conditionGroupTemplate({ operator: 'or' }, index))
  //   this.bindConditionGroupEvents($group)
  //   this.$conditions.append($group)
  // }
  //
  // onOpenConditionItemModal(e) {
  //   e.preventDefault()
  //   const $group = $(e.target).closest('.condition-group')
  //   const $item = $(e.target).closest('.condition-item')
  // }
  //
  // conditionGroupTemplate(group, index) {
  //   return `
  //     <div class='condition-group' data-index='${index}'>
  //       <input type='hidden' name='spell[conditions][${index}]operator' value='${group.operator}'>
  //       <div class='condition-group-items'>
  //         ${(group.items || []).map(item => this.conditionItemTemplate(item, index))}
  //       </div>
  //       <button class='toggle-group-operator-btn'>${group.operator}</button>
  //       <button class='add-condition-group-btn'>Додати группу</button>
  //     </div>
  //   `
  // }
  //
  // conditionItemTemplate(item, index) {
  //   const prefix = `spell[conditions][${index}]`
  //   return `
  //     <div class='condition-item'>
  //       <input type='hidden' name='${prefix}[items][]model' value='${item.model}'>
  //       <input type='hidden' name='${prefix}[items][]column' value='${item.column}'>
  //       <input type='hidden' name='${prefix}[items][]value' value='${item.value}'>
  //       <input type='hidden' name='${prefix}[items][]sign' value='${item.sign}'>
  //       <input type='hidden' name='${prefix}[items][]operator' value='${item.operator}'>
  //       <div class='title'>${item.model}.${item.column} ${item.sign} ${item.value}</div>
  //       <button class='toggle-item-operator-btn'>${item.operator}</button>
  //       <button class='add-condition-item-btn'><i class='fas fa-plus'></i></button>
  //     </div>
  //   `
  // }
}

export default SpellWidget

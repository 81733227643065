import { round, max, debounce, castArray, uniq, compact, difference, last, delay } from 'lodash'
import { errorMessage } from 'services/errors'
import LineItem from './line_item'
import Product from './product'
import Catalog from './catalog'
import DocumentExpenses from './expenses'
import DocumentMaterials from './materials'
import ManufactureUsage from './manufacture_usage'
import DocumentPay from './pay'
import { onSubmitCreateOption, addSelectOptions, clearSelectOptions } from 'services/select'
import { camelizeKeys, fetchParams } from 'services/objects'
import Discount from 'services/discount'
import { totalAmountRounding, fetchNumberInputValue, add, subtract, multiply } from 'services/numbers'
import { formToObject } from 'services/form'
import axios from 'axios'
import { isTriggeredByElementWithClass } from 'services/events'
import ListItemTemplate from 'services/list_item_template'
import qs from 'qs'
import { submitForm } from 'services/form'

class Doc {
  // Order is important! Document price should be calculated before retail and wholesale,
  // because sales prices will be calculated based on document price when markup enabled in receipt doc.
  PRICE_TYPES = ['supply', 'document', 'retail', 'wholesale', 'accounting']

  constructor(el) {
    this.el = el
    this.params = fetchParams()
    this.data = this.el.data('document')
    this.typedoc = this.data.typedoc
    this.discounts = this.el.data('discounts')
    this.contragent = this.el.data('contragent')
    this.currency = this.el.data('currency')
    this.companySettings = this.el.data('company-settings')
    this.collaborators = this.el.data('collaborators')
    this.scanMode = false
    this.form = this.el.find('.document-form')
    this.$table = this.el.find('#document-table .table-body')
    this.nextLineItemPosition = 0
    this.lineItems = $.map(this.$table.find('.line-item'), (el) => new LineItem(this, $(el)))
    this.$fields = this.el.find('.document-fields')
    this.amounts = {}
    this.activeLineItem = null
    this.$modificationModal = this.el.find('#modification-modal')
    this.$lineItemDiscountModal = this.el.find('#line-item-discounts-modal')
    this.$serialsModal = this.el.find('#line-item-serials-modal')
    this.$serialsSelect = this.$serialsModal.find('select.serials-select')
    this.$askRecalculateModal = this.el.find('#ask-recalculate-document-modal')
    this.$performAmountRounding = this.el.find('#perform-amount-rounding')
    this.prepareLineItemTemplate()
    this.documentPay = new DocumentPay($('#document-pay-modal'), { payableAmount: this.payableAmount })
    this.documentExpenses = new DocumentExpenses($('#document-expenses-content'), this)
    this.documentMaterials = new DocumentMaterials($('#document-materials-content'), this)
    this.manufactureUsage = new ManufactureUsage($('#document-manufacture-usage-content'), this)
    this.productModal = new Product($('#product-modal'))
    this.$productsCatalog = new Catalog($('#products-catalog-modal'), this)

    this.bindEvents()
    this.tryOrAskRecalculateLineItems()
    this.calculate()
    this.hightlight()
  }

  // Just hightlight empty line item, it looks good
  hightlight = () => {
    if (this.data.closed || this.lineItems.length !== 1) return

    this.lineItems[0].el.addClass('focused')
  }

  roundingMethod(forceSales = false) {
    if (this.data.is_sale_all || forceSales) {
      return this.companySettings.sales_rounding
    } else {
      return this.companySettings.money_rounding
    }
  }

  moneyRounding({forceSales = false} = {}) {
    if (forceSales) {
      return this.data.sales_rounding_decimals
    } else {
      return this.data.rounding_decimals
    }
  }

  isSelectPartyMode() {
    return this.data.select_price_balance
  }

  isSupplyContragent() {
    return this.data.is_supply_contragent
  }

  isSupplyAll() {
    return this.data.is_supply_all
  }

  isManufacture() {
    return this.typedoc === 'manufacture'
  }

  bindEvents() {
    this.el.find('input, select').keydown(this.navigate)
    this.$lineItemDiscountModal.on('hide.bs.modal', () => this.activeLineItem = null)
    this.el.find('#document-tabs a').click(this.onChangeActiveTab)

    if (this.isReadonly()) {
      this.$fields.find('.document-force-submit').change((e) => this.submit(e, true))
      return
    }

    this.el.find('.calculate').keyup(this.calculate)
    this.el.find('.calculate').change(this.calculate)
    this.$performAmountRounding.change(this.onPerformAmountRounding)
    this.$modificationModal.find('form').submit(this.onCreateModification)
    this.$modificationModal.on('hide.bs.modal', () => this.activeLineItem = null)
    this.$serialsModal.find('form').submit(this.onSubmitSerials)
    this.$serialsModal.find('.select-all-btn').click(this.onSelectAllSerials)
    this.$serialsModal.find('.deselect-all-btn').click(this.onDeselectAllSerials)
    this.$serialsModal.on('hide.bs.modal', () => this.activeLineItem = null)
    this.$lineItemDiscountModal.find('.recalculate-btn').click(this.recalculateLineItemDiscount)
    this.$fields.find('select, input').change(this.submit)
    this.$askRecalculateModal.find('.recalculate-line-items-btn').click(this.recalculateLineItems)
    this.$askRecalculateModal.find('.cancel-recalculate-btn').click(this.clearRecalculateParams)
  }

  isReadonly() {
    return this.el.data('readonly')
  }

  submit = (e, force = false) => {
    if (this.isReadonly() && !force) return
    if (!this.isReadonly()) this.setRecalculateParams(e)

    submitForm(this.form)
  }

  onChangeActiveTab = (e) => {
    const tab = $(e.currentTarget).data('active-tab')
    this.el.find('#active-tab-field').val(tab)
  }

  isLineItemsExists() {
    // One line item is empty, so if we have just 1, then document without line items
    return this.lineItems.length > 1
  }

  setRecalculateParams(e) {
    const reDiscounts = this.data.allow_discounts && isTriggeredByElementWithClass(e, 'contragent-select')
    const rePrices = isTriggeredByElementWithClass(e, 'typeprice-select')
    const recalcalculate = reDiscounts || rePrices ||
      isTriggeredByElementWithClass(e, 'currency-select', 'currency-rate-input') ||
      (isTriggeredByElementWithClass(e, 'contragent-select') && this.isSupplyContragent())

    this.el.find('#recalculate-input').val(recalcalculate)
    this.el.find('#recalculate-prices-input').val(rePrices)
    this.el.find('#recalculate-discounts-input').val(reDiscounts)
  }

  isMulticurrency() {
    return this.data.is_support_currency && this.data.currency_id !== this.companySettings.currency_accounting_products
  }

  navigate = (e) => {
    if (e.which !== 13) return
    e.preventDefault()
  }

  recalculateLineItemDiscount = (e) => {
    e.preventDefault()
    this.activeLineItem.calculate({recalculateDiscounts: true})
  }

  openLineItemDiscountModal(lineItem) {
    this.activeLineItem = lineItem
    this.$lineItemDiscountModal.modal('show')
  }

  async openLineItemSerialsModal(lineItem) {
    try {
      this.$serialsModal.find('.alert').text(null).hide()
      this.$serialsModal.find('.loader').show()
      this.$serialsModal.find('.line-item-serials-form').hide()
      this.$serialsModal.modal('show')

      this.activeLineItem = lineItem
      const $serialsInput = this.activeLineItem.el.find('.serials-input')

      const selectedSerials = JSON.parse($serialsInput.val() || '[]')
      const availableSerials = await this.fetchProductSerials(lineItem.productId)
      const allSerials = compact(uniq([...availableSerials, ...selectedSerials]))

      clearSelectOptions(this.$serialsSelect)

      addSelectOptions(this.$serialsSelect, allSerials.map((number) => ({ label: number, value: number })), {
        label: 'label',
        value: 'value',
        selected: selectedSerials.map((number) => ({ label: number, value: number }))
      })

      this.$serialsModal.find('.alert').hide()
      this.$serialsModal.find('.loader').hide()
      this.$serialsModal.find('.line-item-serials-form').show()
    } catch (e) {
      this.$serialsModal.find('.alert').text(errorMessage(e)).show()
      this.$serialsModal.find('.loader').hide()
      console.error(e)
    }
  }

  async fetchProductSerials(productId) {
    const response = await axios.get(`${gon.locale_path}/platform/products/${productId}/serials.json`)
    return response.data
  }

  onSubmitSerials = (e) => {
    e.preventDefault()

    if (this.isReadonly()) return

    const serials = castArray(this.$serialsSelect.val())

    this.activeLineItem.serials.updateSerials(serials)
    this.$serialsModal.modal('hide')
  }

  onSelectAllSerials = (e) => {
    e.preventDefault()

    if (this.isReadonly()) return

    this.activeLineItem.serials.selectAllSerials()
  }

  onDeselectAllSerials = (e) => {
    e.preventDefault()

    if (this.isReadonly()) return

    this.activeLineItem.serials.deselectAllSerials()
  }

  openModificationModal(lineItem) {
    this.activeLineItem = lineItem

    this.$modificationModal.find('#modification-product-id').val(lineItem.productId)
    this.$modificationModal.find('#modification-barcode').val(null)
    this.$modificationModal.find('select').val(null).trigger('change')

    this.$modificationModal.find('.features-widget')
      .data('featureable-id', null)
      .trigger('reload-features')

    this.$modificationModal.find('.panel-body.collapse').collapse('hide')
    this.$modificationModal.find('.modification-prices input[type="number"]').val(null).attr('readonly', true)
    this.$modificationModal.find('.modification-prices input[type="checkbox"]').prop('checked', false)

    this.$modificationModal.modal('show')
  }

  similarLineItems(lineItem) {
    return this.lineItems.filter(item => {
      return item.productId === lineItem.productId && item.id !== lineItem.id
    })
  }

  onDestroyLineItem(id) {
    if (this.isReadonly()) return

    // In case if destroy fails on server side, we should rollback them here
    this.previousLineItems = [...this.lineItems]
    this.lineItems = this.lineItems.filter(li => li.id !== id)
    this.calculate()
  }

  rollbackLineItems() {
    this.lineItems = [...this.previousLineItems]
    this.calculate()
  }

  // Modification it's just select, so we use standard create function from select.
  // But we can't use create feature of select widget, because we haven't uniq modal for each line item.
  onCreateModification = (e) => {
    // Here prevent default create select feature to avoid double create
    e.preventDefault()

    if (this.isReadonly()) return
    // Each line item with same product should be updated with new modification
    const selects = this.similarLineItems(this.activeLineItem).map(lineItem => lineItem.modifications.$select)
    // onSubmitCreateOption is async
    onSubmitCreateOption(e, this.activeLineItem.modifications.$select, this.$modificationModal, {
      label: 'to_s',
      value: 'id',
      similarSelects: selects,
      callback: this.afterCreateModification.bind(this)
    })
  }

  // In order to add new modification to each lineItem instance with same product.
  // NOTE: It doesn't append new modification in select box, just into lineItem.
  // In select box new modification added via similarSelects.
  afterCreateModification(modification) {
    if (!modification || !modification.id || !modification.product_id) return

    this.lineItems
      .filter(lineItem => modification.product_id === lineItem.productId)
      .forEach(lineItem => {
        // Exlude modification if it's already exists.
        const modifications = lineItem.product.modifications.filter(m => m.id !== modification.id)

        // Add new modification to line item product.
        modifications.push(modification)
        lineItem.product.modifications = modifications
      })
  }

  // Grab outerHTML, instead of using html string, because it's complex,
  // and may have different fields depending on document type.
  prepareLineItemTemplate() {
    if (this.data.closed || this.lineItemTemplate) return

    this.lineItemTemplate = new ListItemTemplate(this.$table, {
      itemClass: 'line-item',
      templateClass: 'new-template-line-item',
      valueClass: 'product-id'
    })

    this.lineItemTemplate.prepare()
  }

  // New line item will be added if last item is not empty,
  // we also need to update name and id attributes of each form input
  addEmptyLineItem() {
    const $newLineItem = this.lineItemTemplate.create()

    if (!$newLineItem) return

    $newLineItem.addClass('empty')
    $newLineItem.find('.create-btn').tooltip()
    $newLineItem.find('.scan-btn').tooltip().toggleClass('active', this.scanMode)
    const newLineItem = new LineItem(this, $newLineItem)
    this.lineItems.push(newLineItem)

    return newLineItem
  }

  toggleScanMode = (e) => {
    e.preventDefault()
    this.scanMode = !this.scanMode
    $(e.currentTarget)
      .toggleClass('active', this.scanMode)
      .closest('.line-item').find('.search-field').focus()
  }

  lastLineItem() {
    return last(this.lineItems)
  }

  insertLineItem(item) {
    let emptyLineItem = this.lastLineItem()

    if (emptyLineItem.productId) {
      this.addEmptyLineItem()
      emptyLineItem = this.lastLineItem()
    }

    emptyLineItem.setLineItem(item)
  }

  calculate = () => {
    const moneyRounding = this.moneyRounding()

    this.resetAmounts()
    this.el.find('#items-total-quantity').text(this.amounts.quantity)
    this.el.find('#items-total-accounting').text(this.amounts.accounting)
    this.el.find('#items-total-document').text(this.amounts.document)
    this.el.find('#items-total-supply').text(this.amounts.supply)
    this.el.find('#items-total-retail').text(this.amounts.retail)
    this.el.find('#items-total-wholesale').text(this.amounts.wholesale)
    this.el.find('#amount-products-discount').val(this.amounts.amountProductsDiscount)
    this.el.find('#amount-products').val(this.amounts.amountProducts)
    this.el.find('#amount-nds').val(this.amounts.amountNds)
    this.el.find('#amount-netto').val(this.amounts.amountNetto)
    // only for receipt and return
    this.el.find('#amount-contragent').text(this.amounts.amountContragent)

    // this one to display in the table footer
    this.el.find('#accrued-bonus-total .value').text(`${this.data.is_return ? '-' : ''}${this.amounts.accruedBonus}`)
    // this one to save in hidden input
    this.el.find('#document-accured-bonus').val(this.amounts.accruedBonus)
    this.el.find('#document-amount-nds').text(this.amounts.amountNds)
    this.el.find('#document-amount-netto').text(this.amounts.amountNetto)

    this.toggleAccruedBonus(!!this.amounts.accruedBonus)
    this.resetDeliveryEvaluationAmount()

    if (this.isReadonly()) return

    this.roundTotalAmount()
    this.documentPay.togglePaymentStatus(this.payableAmount())

    // this one to display in the table footer
    this.el.find('#visible-total-amount').text(this.amounts.total === 0 ? '0.0' : this.amounts.total)
    // this one to save in hidden input
    this.el.find('#total-amount').val(this.amounts.total)

    if (this.autoSubmit) this.submit()
  }

  payableAmount = () => {
    return this.data.supplier_price_recalculation ? this.amounts.amountContragent : this.amounts.total
  }

  // TODO: Add support for multicurrency and Novaposhta. For now it's blocked inside DeliveryWidget
  resetDeliveryEvaluationAmount() {
    // Document may have many deliveries in future
    this.el.find('.document-delivery').each((_, element) => {
      const $delivery = $(element)

      if ($delivery.find('.delivery-auto-evaluation-amount').is(':checked')) {
        $delivery.find('.delivery-evaluation-amount').val(this.amounts.amountProducts)
      }
    })
  }

  onPerformAmountRounding = (e) => {
    this.autoSubmit = true
    this.calculate()
  }

  roundTotalAmount() {
    if (!this.$performAmountRounding.length) return

    const rounding = this.el.find('#perform-amount-rounding').prop('checked')
    const amount = this.amounts.total
    const precision = this.data.amount_rounding_decimals

    let total
    let amountRounding

    if (rounding) {
      amountRounding = totalAmountRounding(amount, this.data.amount_rounding_method, precision)
      total = round(add(amount, amountRounding), precision)
    } else {
      amountRounding = 0
      total = amount
    }

    this.el.find('#amount-rounding').val(amountRounding)
    this.amounts.total = total
  }

  resetAmounts() {
    const moneyRounding = this.moneyRounding()
    const salesRounding = this.moneyRounding({forceSales: true})

    this.amounts = { quantity: 0, accruedBonus: 0, amountProductsDiscount: 0,
      amountProducts: 0, amountContragent: 0, amountNds: 0, amountNetto: 0 }
    this.resetAddionalAmounts()
    this.PRICE_TYPES.forEach(type => this.amounts[type] = 0)
    this.resetItemsAmounts()

    if (['receipt', 'receipt_return', 'supplier_order'].includes(this.typedoc)) {
      this.amounts.amountContragent = add(this.amounts.supply, this.amounts.contragentExpenses)
    }

    this.amounts.total = this.totalAmount()

    Object.keys(this.amounts).forEach(key => {
      const rounding = ['retail', 'wholesale'].includes(key) ? salesRounding : moneyRounding
      this.amounts[key] = round(this.amounts[key], rounding)
    })
  }

  resetAddionalAmounts() {
    this.amounts.discount = fetchNumberInputValue(this.el.find('#amount-discount'))
    this.amounts.markup = fetchNumberInputValue(this.el.find('#amount-markup'))
    this.amounts.delivery = fetchNumberInputValue(this.el.find('#amount-delivery'))
    this.amounts.other = fetchNumberInputValue(this.el.find('#amount-other'))
    this.amounts.contragentExpenses = fetchNumberInputValue(this.el.find('#amount-contragent-expenses'))
  }

  resetItemsAmounts() {
    this.lineItems.forEach(item => {
      this.PRICE_TYPES.forEach(type => {
        this.amounts[type] = add(this.amounts[type], fetchNumberInputValue(item.el.find(`.amount-${type}`)))
      })
      const metadata = item.fetchMetadata()
      const priceDocument = fetchNumberInputValue(item.el.find('.price-document'))
      const quantity = fetchNumberInputValue(item.el.find('.quantity'))
      const amount = fetchNumberInputValue(item.el.find('.amount-document'))
      const amountProducts = multiply(quantity, priceDocument)
      const amountProductsDiscount = max([subtract(amountProducts, amount), 0])

      this.amounts.quantity = add(this.amounts.quantity, quantity)
      this.amounts.accruedBonus = add(this.amounts.accruedBonus, metadata.accruedBonus || 0)
      this.amounts.amountProductsDiscount = add(this.amounts.amountProductsDiscount, amountProductsDiscount)
      this.amounts.amountProducts = add(this.amounts.amountProducts, amountProducts)
      this.amounts.amountNds = add(this.amounts.amountNds, fetchNumberInputValue(item.el.find('.amount-nds')))
      this.amounts.amountNetto = add(this.amounts.amountNetto, fetchNumberInputValue(item.el.find('.amount-netto')))
    })
  }

  // Updates all line items and document fields in one request.
  // For now it used after prices recalculation.
  // TODO: Add errors handling
  async bulkUpdate() {
    if (this.isReadonly()) return
    try {
      const path = [gon.locale_path, 'platform', 'documents', this.data.id, 'bulk_update_items.json'].join('/')
      const params = {
        document:  {
          ...formToObject(this.form, 'document').document,
          document_items_attributes: this.lineItems
            .filter(li => !!li.productId)
            .map(li => formToObject(li.form, 'document_item').document_item)
        }
      }
      const response = await axios.put(path, params)

      this.clearRecalculateParams()
      this.submit()
    } catch (e) {
      console.log(e)
    }
  }

  // Items recalculation should be done in following cases:
  // - typeprice changed: ask user for recalculate via askRecalculateLineItems
  // - contragent changed: silent recalculation (no, for now also we will ask,
  //   because all user work will be cleared if he just forgot to choose contragent)
  // - document was generated from another document with different typeprices
  // Action can't be done on select change, because document immediatelly triggers submit.
  // So, we on change we just add params = recalculate, in order to do it on page load.
  // Calculate document in this case shouldn't be done on each line item recalculate,
  // only at the end of the proccess.
  recalculateLineItems = (e = null) => {
    e && e.preventDefault()

    if (this.isReadonly()) return

    this.lineItems.forEach(item => item.calculate({
      recalculatePrices: this.params.recalculate_prices,
      recalculateDiscounts: this.params.recalculate_discounts,
      recalculateCurrency: true,
      calculateDoc: false,
      save: false
    }))

    this.calculate()
    this.bulkUpdate()
  }

  clearRecalculateParams = () => {
    window.history.replaceState(window.history.state, '', window.location.pathname)
  }

  tryOrAskRecalculateLineItems() {
    if (this.isReadonly()) return
    if (!this.params.recalculate) return

    // Discounts recalculation should happen automatically without confirmation,
    // because we trigger it only when contragent changed. If we allow skip it,
    // then user will be able to apply discounts from one contragent to another one, without permission.
    // Also, on import we do recalculation without asking.
    // Provide params[:recalculate_silent] if don't want to ask user.
    if (this.params.recalculate_prices && !this.params.recalculate_silent) {
      this.$askRecalculateModal.modal('show')
    } else {
      this.recalculateLineItems()
    }
  }

  toggleAccruedBonus(show) {
    this.el.find('#accrued-bonus-total').toggle(!!show)
  }

  totalAmount() {
    return add(subtract(this.amounts.document, this.amounts.discount),
      add(add(this.amounts.markup, this.amounts.delivery), this.amounts.other)
    )
  }

  // Triggered from Catalog.js after user submit products selection
  applyCatalogSelection = (selectedProducts = {}, configuredProducts = {}) => {
    this.applyProductsFromCatalogPending = true
    // this.lineItems.map(li => !configuredProducts[li.productId] && li.destroy())

    // const addProductIds = difference(Object.keys(selectedProducts), this.lineItems.map(li => li.productId))

    // addProductIds.forEach(productId => {
    Object.keys(selectedProducts).forEach(productId => {
      const product = selectedProducts[productId]
      this.insertLineItem(product)
    })
    this.applyProductsFromCatalogPending = false
  }
}

export default Doc

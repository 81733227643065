import BaseWidget from './base_widget'

// Widget to handle interactions with address addresses selection radio boxes
// kind-field, and kinds is an array with visible kinds.
// Widget will filter addresses based on kind value, but if kind-field attribute is not provided,
// then no any filters applies.
// So, in document delivery we provide kind, because we want all addresses to be filtered by current kind.
// But, in filials or provider settings we show all addresses.
class AddressSelectionWidget extends BaseWidget {
  initialize() {
    this.$kind = this.fetchKindField()
    this.$addresses = this.el
  }

  render() {
    this.reload()
  }

  bindEvents() {
    if (this.$kind) this.$kind.change(this.reload.bind(this))
    this.$addresses.find('.address-option').change(this.onAddressChange.bind(this))
  }

  // Show addresses that match selected delivery kind and preselect address or open new address form
  // Initially all address items hidden in css
  reload() {
    let $visible = this.$addresses.find('.address-item:not(.new-address-item)')

    // If kind-field not provided than hide all other addresses
    if (this.$kind && this.$kind.val()) {
      const kind = this.$kind.val()
      $visible = this.$addresses.find(`.address-item[data-kind='${kind}']`)

      const $hidden = this.$addresses.find(`.address-item[data-kind!='${kind}']:not(.new-address-item)`)
      $hidden.hide().find('.address-option').prop('checked', false)
    }

    const $newAddressItem = this.$addresses.find('.new-address-item')

    $visible.show()
    $newAddressItem.toggle(!!$visible.length)

    // Already selected
    if ($visible.find('.address-option:checked').length) return

    const $primary = $visible.closest(`.address-item[data-primary='true']`)
    let $selected

    if ($primary.length) {
      // Select primary
      $selected = $primary
    } else if ($visible.closest(':not(.new-address-item)').length) {
      // Select first one
      $selected = $visible.first()
    } else {
      // No adderesses available for current kind, open new address form
      $selected = $newAddressItem
    }

    const $option = $selected.find('.address-option')
    $option.prop('checked', true)

    // Trigger change doesn't work for radio buttons, that's why I call event manually
    this.onAddressChange(null, { addressId: $option.val() })
  }

  onAddressChange(e, options = {}) {
    const addressId = options.hasOwnProperty('addressId') ? options.addressId : $(e.target).val()
    const isNewOption = !addressId

    this.$addresses.find('.new-address-container').toggle(isNewOption)

    this.$addresses.find('.address-option').each((_, option) => {
      const isSelected = $(option).val() === addressId
      const $radio = $(option).closest('.radio')

      $(option).closest('.radio').toggleClass('radio-default', !isSelected)
      $(option).closest('.radio').toggleClass('radio-info', isSelected && !isNewOption)
      $(option).closest('.radio').toggleClass('radio-success', isSelected && isNewOption)
    })
  }

  fetchKindField() {
    if (!this.el.data('kind-field')) return null

    return  $(this.el.data('kind-field'))
  }
}

export default AddressSelectionWidget

// Always add new widget function name to config/webpack/production terser regexp
// to make it work in production

const widgets = require.context('.', true, /_widget\.js$/)

const widgetClasses = []

widgets.keys().forEach(function(file, index) {
  module = widgets(file)
  window.widgets[module.default.name] = module.default
})

import BaseWidget from './base_widget'
import Bootbox from 'bootbox'

class Lightbox extends BaseWidget {
  bindEvents() {
    this.el.find('.image-item').click(this.openLightbox.bind(this))
  }

  openLightbox(e) {
    e.preventDefault()

    const imageUrl = $(e.currentTarget).data('image-large')

    const dialog = Bootbox.dialog({
      className: 'lightbox',
      // message: `<div class='image-preview' style='background-image: url(${imageUrl})'>`,
      message: `<img class='image-preview' src='${imageUrl}'>`,
      closeButton: true,
      onEscape: true,
      backdrop: true,
      animate: true
    })

    // Becuase image take full screen width it's not possible to close modal only when user clicked
    // outside of image. So, we just close modal on any user click.
    dialog.find('.modal-content').click((e) => dialog.modal('hide'))
  }
}

export default Lightbox

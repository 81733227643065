import onScan from 'onscan.js'
import { divide } from 'services/numbers'

export function barcodeScannerOn(dom, scanCallback, pasteCallback) {
  onScan.attachTo(dom, {
    suffixKeyCodes: [], // enter-key expected at the end of a scan (was 13, but it cause bug and quick enter press
    onScan: scanCallback,
    reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
    onPaste: pasteCallback
  })
}

export function barcodeScannerOff(dom) {
  onScan.detachFrom(dom)
}

export function extractCodeFromWeightBarcode(barcode) {
  if (!barcode) return

  return barcode.slice(0, 7)
}

export function extractWeightFromBarcode(barcode) {
  if (!barcode || barcode.length !== 13) return

  const weightInGrams = Number(barcode.slice(7, 12))

  if (Number.isNaN(weightInGrams)) return

  return divide(weightInGrams, 1000)
}

export function isWeightBarcode(barcode, prefix, { checkIsFull = false } = {}) {
  return prefix && barcode.slice(0, 3) === prefix &&
    (!checkIsFull || barcode.length === 13)
}

// Widget to handle interactions with product categories select modal
import BaseWidget from './base_widget'
import bootbox from 'bootbox'

class GroupProductsSelectWidget extends BaseWidget {
  initialize() {
    this.$field = this.el.find('.tree-list-select-field-box')
  }

  bindEvents() {
    this.$field.click(this.handleOpenModal.bind(this))
    this.el.find('.remove-selected ').click(this.handleDeselect.bind(this))
  }

  bindModalContentEvents() {
    this.$modal.find('.select-list .tree-item-box').click(this.handleSelect.bind(this))
    // this.$modal.find('.edit-item').tooltip({ placement: 'top', title: 'Edit' })
    // this.$modal.find('.create-item').tooltip({ placement: 'top', title: 'Create' })

  }

  handleOpenModal(e) {
    this.$modal = bootbox.dialog({
      title: I18n.t('group_products.select_modal_title'),
      message: this.el.data('modal-content'),
      onEscape: true,
      backdrop: true,
      animate: false
    })

    this.bindModalContentEvents()
  }

  handleSelect(e) {
    e.preventDefault()

    if ($(e.target).hasClass('tree-item-toggle')) return

    const $item = $(e.target).closest('.tree-item-box').find('.tree-item')
    const itemClass = $item.data('item-class')
    const itemId = $item.data('item-id')
    const itemTitle = $item.data('item-title')
    let fullTitle = []

    $item.parents('.tree-item-container').map((_, parent) => {
      const parentItem = $(parent).children('.tree-item-box').find('.tree-item')
      const parentTitle = parentItem.data('item-title')
      fullTitle.push(parentTitle)
    })

    fullTitle = fullTitle.reverse().join(' / ')

    if (itemClass === 'GroupProduct') {
      this.el.find('.selected-id').val(itemId)
      this.el.find('.selected-title').html(itemTitle)
      this.$modal.modal('hide')
      this.el.find('.remove-selected').show()

      this.$field.tooltip({ placement: 'top' })
      if (this.$field.data('bs.tooltip')) {
        this.$field.data('bs.tooltip').options.title = fullTitle
      }

      if (this.el.data('submit-on-select')) this.el.closest('form').submit()
    }
  }

  handleDeselect(e) {
    e.preventDefault()
    e.stopPropagation()

    this.el.find('.selected-id').val(null)
    this.el.find('.selected-title').html('')
    this.el.find('.remove-selected').hide()
    if (this.$field.data('bs.tooltip')) {
      this.$field.data('bs.tooltip').options.title = null
    }
    this.$field.tooltip('hide')
    if (this.el.data('submit-on-select')) this.el.closest('form').submit()
  }


}

export default GroupProductsSelectWidget


export function optimizeMobileTable(selector) {
  const $tables = $(document).find(selector)

  $tables.each((_, table) => {
    const $table = $(table)
    const $th = $table.find('thead th')
    const labels = Array.from($th).map(el => el.innerText)

    $table.find('tbody tr').each((_, tr) => {
      $(tr).children().each((index, td) => $(td).attr('label', labels[index]))
    })
  })
}

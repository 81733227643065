import BaseWidget from './base_widget'
import axios from 'axios'
import { connectWidgets } from 'main/widgets/helpers'
import { errorMessage } from 'services/errors'
import Product from 'main/document/product'

class ProductWidget extends BaseWidget {
  initialize() {
    this.product = new Product(this.el, null, { onCreate: this.afterSave.bind(this), onUpdate: this.afterSave.bind(this) })
  }

  afterSave(data) {
    if (!data.id) return

    window.location = `${gon.locale_path}/platform/products/${data.id}`
  }
}

export default ProductWidget

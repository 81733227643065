// Implemented as function (not just variable) in order to have access to window.I18n
export function tableStylePlugin() {
  return {
    name: 'tableStyle',
    display: 'container',
    title: window.I18n.t('editor.table_style'),
    buttonClass: '',
    innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" style="width:19px;height:19px;"><path d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"/></svg>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      const context = core.context
      context.tableStyle = {}

      // Generate submenu HTML
      // Always bind "core" when calling a plugin function
      const submenu = this.setSubmenu(core)

      // You must bind "core" object when registering an event.
      /** add event listeners */
      submenu.querySelector('.se-form-group').addEventListener('click', this.onClick.bind(core))

      // @Required
      // You must add the "submenu" element using the "core.initMenuTarget" method.
      /** append target button menu */
      core.initMenuTarget(this.name, targetElement, submenu)
    },

    setSubmenu: function (core) {
      const submenu = core.util.createElement('DIV')
      submenu.className = 'se-menu-container se-submenu se-list-layer'
      submenu.innerHTML = `
        <div class="se-list-inner">
          <div class="se-form-group">
            <div>
              <button type="button" class="se-btn se-tooltip" data-table-class="se-table-clean" style="margin: 2px 5px !important">
                <i class="fas fa-border-none"></i>
                <span class="se-tooltip-inner"><span class="se-tooltip-text">${window.I18n.t('editor.table_clean')}</span></span>
              </button>
            </div>
            <div>
              <button type="button" class="se-btn se-tooltip" data-table-class="se-table-bordered" style="margin: 2px 5px !important">
                <i class="fas fa-border-all"></i>
                <span class="se-tooltip-inner"><span class="se-tooltip-text">${window.I18n.t('editor.table_bordered')}</span></span>
              </button>
            </div>
            <div>
              <button type="button" class="se-btn se-tooltip" data-table-class="se-table-striped" style="margin: 2px 5px !important">
                <i class="fas fa-bars"></i>
                <span class="se-tooltip-inner"><span class="se-tooltip-text">${window.I18n.t('editor.table_striped')}</span></span>
              </button>
            </div>
          </div>
        </div>
      `
      return submenu
    },

    onClick: function (e) {
      e.preventDefault()
      e.stopPropagation()

      const element = this.util.getFormatElement(this.getSelectionNode())
      const $table = $(element).closest('table')

      if (!$table.length) return

      const tableClass = e.target.dataset.tableClass

      if ($table.hasClass(tableClass)) {
        $table.removeClass(tableClass)
      } else {
        // 'se-' prefix required by suneditor
        $table.removeClass(['se-table-clean', 'se-table-striped', 'se-table-bordered'])
        $table.addClass(tableClass)
      }
    }
  }
}

import BaseWidget from './base_widget'
import { min, sumBy, round } from 'lodash'
import { subtract } from 'services/numbers'

class BindsWidget extends BaseWidget {
  initialize() {
    this.$modal = this.el.closest('.modal')
  }

  bindEvents() {
    this.el.find('.bind-checkbox').change(this.onSelectBind.bind(this))
  }

  onSelectBind(e) {
    const $bind = $(e.target).closest('.bind-item')
    const $amount = $bind.find('.bind-amount')
    const $destroy = $bind.find('.destroy-input')
    const checked = $(e.target).prop('checked')

    if (checked) {
      $destroy.val(0)
      $amount.val(this.autoBindAmount($bind)).prop('disabled', false).focus()
    } else {
      $amount.val(null).prop('disabled', true)
      $destroy.val(1)
    }
  }

  autoBindAmount($bind) {
    const free = Number($bind.find('.free-amount').text() || 0)
    const paid = this.sumModalPaidAmounts()
    let amount = free

    if (this.$modal.hasClass('document-pay-modal')) {
      const total = Number(this.$modal.data('payable-amount') || 0)
      amount = subtract(total, paid)
    } else if ($('.payment#payment').length) {
      const total = Number($('#payment .payment-total-amount').val() || 0)
      amount = subtract(total, paid)
    } else {
      amount = 0
    }

    if (amount <= 0) return null

    return round(min([free, amount]), 4)
  }

  sumModalPaidAmounts() {
    return sumBy(Array.from(this.$modal.find('input[type="number"]')), (input) => Number($(input).val() || 0))
  }
}

export default BindsWidget

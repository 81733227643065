import { connectWidgets } from 'main/widgets/helpers'
import { showAlert } from 'services/errors'

// This function should be called last.
// If not you should provide { widgets: false } and connect widgets manually
export function ajaxGlobalResponse(data, $el, { widgets = true } = {}) {
  if (data.modal) {
    if (widgets) connectWidgets($el)
    return
  }

  $('.modal').modal('hide')
  $('body').removeClass('modal-open')
  $('.modal-backdrop').remove()
  $('.toastify-ajax-error').remove()
  $('#notice').html(null)

  let alert = null
  const styles = data.popup ? "style='margin: 0; display: block !important'" : ''

  if (data.notice) alert = `<div class='alert alert-success' ${styles}>${data.notice}</div>`
  if (data.alert) alert = `<div class='alert alert-danger' ${styles}>${data.alert}</div>`

  if (alert && data.popup) {
    showAlert(alert, { title: data.popup })
  } else if (alert) {
    $('#notice').html(alert)
    $('#notice')[0].scrollIntoView()
  }

  if (data.js_scroll_top) $('body')[0].scrollIntoView()
  if (widgets) connectWidgets($el)
}

import { round } from 'lodash'
import { connectWidgets } from 'main/widgets/helpers'
import { subtract } from 'services/numbers'

class DocumentPay {
  constructor(el, { payableAmount }) {
    this.el = el
    this.roundingDecimals = Number(this.el.find('.document-pay-form').data('rounding-decimals')),
    this.payableAmount = payableAmount
    this.bindEvents()
    this.refreshPayableAmount()
  }

  bindEvents() {
    this.el.find('.auto-fill-paid-buttons button').click(this.autoFillPaidField)
    this.el.find('.typepay-select').change(this.onTypePayChange)
  }

  onTypePayChange = (e) => {
    const typepay = $(e.target).val()

    this.el.find('.payment-due-date').toggle(typepay === 'credit')
  }

  autoFillPaidField = (e) => {
    e.preventDefault()

    const $button = $(e.currentTarget)
    const percent = Number($button.data('percent') || 100)
    const paid = round((this.payableAmount() * percent / 100.0), this.roundingDecimals)

    $button.closest('.input-group').find('input').val(paid)
  }

  isEnabled() {
    return !!this.el.length
  }

  togglePaymentStatus = () => {
    if (!this.isEnabled()) return

    const paidAmount = Number(this.el.find('.total-paid-amount').text() || 0)
    const total = this.payableAmount()
    let status = ''

    switch (true) {
      case paidAmount > total:
        status = `${window.I18n.t('main.overpayment')} <strong id='paid-status-amount'>${round(subtract(paidAmount, total), this.roundingDecimals)}</strong>`
        break
      case paidAmount < total:
        status = `${window.I18n.t('main.underpayment')} <strong id='paid-status-amount'>${round(subtract(total, paidAmount), this.roundingDecimals)}</strong>`
        break
    }
    this.el.find('.payment-status').html(status)
    this.refreshPayableAmount()
  }

  // BindsWidget need this data
  refreshPayableAmount() {
    this.el.data('payable-amount', this.payableAmount())
  }
}

export default DocumentPay
